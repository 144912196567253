import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { PY_URL } from '../config';

const YouTubeLinkModal = ({ onClose }) => {
  const { currentUser } = useAuth();
  const [authUrl, setAuthUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAuthUrl = async () => {
      try {
        const response = await fetch(`${PY_URL}/get_youtube_auth_url`);
        const data = await response.json();
        if (data.auth_url) {
          setAuthUrl(data.auth_url);
        } else {
          throw new Error('Failed to retrieve auth URL');
        }
      } catch (err) {
        setError('Failed to initialize YouTube authentication');
      } finally {
        setLoading(false);
      }
    };

    fetchAuthUrl();
  }, []);

  const handleConnect = () => {
    if (authUrl) {
      window.location.replace(authUrl);
    } else {
      setError('No auth URL available');
    }
  };

  return (
    <div className="modal-overlay show">
      <div className="modal-content">
        <h3>Connect YouTube Account</h3>
        <p>Would you like to link your YouTube account for importing sermons?</p>
        {error && <div className="error-message">{error}</div>}
        <div className="button-group">
          <button 
            className="button button-primary"
            onClick={handleConnect}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Connect YouTube'}
          </button>
          <button 
            className="button button-secondary"
            onClick={onClose}
          >
            Skip for now
          </button>
        </div>
      </div>
    </div>
  );
};

export default YouTubeLinkModal; 