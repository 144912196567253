import React, { useState } from 'react';
import NewsFeed from '../components/community/NewsFeed';
import Groups from '../components/community/Groups';
import PrayerRequests from '../components/community/PrayerRequests';
import Events from '../components/community/Events';
import Churches from '../components/community/Churches';

const Community = () => {
  const [activeSection, setActiveSection] = useState('feed');

  const styles = {
    communityContainer: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '-10px',
    },
    navigationTabs: {
      width: '100%',
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '12px',
      padding: '0.5rem',
      marginBottom: '1rem',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.07)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
    },
    tabsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '0.5rem',
      width: '100%',
      padding: '0',
    },
    tab: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.25rem',
      padding: '0.5rem',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      background: 'transparent',
      position: 'relative',
      fontFamily: "'Cardo', serif",
    },
    tabIcon: {
      fontSize: '1.3rem',
      color: '#333333',
      transition: 'color 0.2s ease',
    },
    tabIconActive: {
      color: 'var(--primary-color)',
    },
    tabLabel: {
      fontSize: '0.9rem',
      fontWeight: '500',
      color: '#333333',
      fontFamily: "'Cardo', serif",
      whiteSpace: 'nowrap',
    },
    tabLabelActive: {
      color: 'var(--primary-color)',
      fontWeight: '600',
      fontFamily: "'Cardo', serif",
    },
    tabInactive: {
      color: '#333333',
      '&:hover': {
        background: '#F7FAFC',
      },
    },
    contentContainer: {
      width: '100%',
      padding: '0',
      background: 'transparent',
      boxShadow: 'none',
      borderRadius: '0',
    },
  };

  const tabs = [
    {
      id: 'feed',
      title: 'News Feed',
      icon: 'mdi-newspaper',
      component: NewsFeed,
    },
    {
      id: 'events',
      title: 'Events',
      icon: 'mdi-calendar',
      component: Events,
    },
    {
      id: 'groups',
      title: 'Groups',
      icon: 'mdi-account-group',
      component: Groups,
    },
    {
      id: 'prayer',
      title: 'Prayer',
      icon: 'mdi-hands-pray',
      component: PrayerRequests,
    }
  ];

  const CurrentComponent = tabs.find(tab => tab.id === activeSection)?.component;

  return (
    <div style={styles.communityContainer}>
      <div style={styles.navigationTabs}>
        <div style={styles.tabsContainer}>
          {tabs.map((tab) => (
            <button
              key={tab.id}
              style={styles.tab}
              onClick={() => setActiveSection(tab.id)}
            >
              <i 
                className={`mdi ${tab.icon}`} 
                style={{
                  ...styles.tabIcon,
                  ...(activeSection === tab.id && styles.tabIconActive)
                }}
              ></i>
              <span style={{
                ...styles.tabLabel,
                ...(activeSection === tab.id && styles.tabLabelActive)
              }}>
                {tab.title}
              </span>
            </button>
          ))}
        </div>
      </div>

      <div style={styles.contentContainer}>
        {CurrentComponent && <CurrentComponent />}
      </div>
    </div>
  );
};

export default Community;
