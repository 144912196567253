export const theme = {
    colors: {
        primary: '#3498db',
        secondary: '#2ecc71',
        background: '#f8f9fa',
        surface: '#ffffff',
        text: '#2c3e50',
        textLight: '#666666',
        border: '#eeeeee',
        error: '#e74c3c',
        success: '#2ecc71',
        warning: '#f1c40f'
    },
    spacing: {
        xs: '0.25rem',
        sm: '0.5rem',
        md: '1rem',
        lg: '1.5rem',
        xl: '2rem'
    },
    borderRadius: {
        sm: '4px',
        md: '8px',
        lg: '12px',
        circle: '50%'
    },
    shadows: {
        sm: '0 2px 4px rgba(0, 0, 0, 0.1)',
        md: '0 4px 6px rgba(0, 0, 0, 0.1)',
        lg: '0 8px 16px rgba(0, 0, 0, 0.1)'
    },
    typography: {
        fontFamily: "'Inter', sans-serif",
        sizes: {
            xs: '0.75rem',
            sm: '0.875rem',
            md: '1rem',
            lg: '1.25rem',
            xl: '1.5rem',
            xxl: '2rem'
        },
        weights: {
            normal: 400,
            medium: 500,
            semibold: 600,
            bold: 700
        }
    },
    breakpoints: {
        mobile: '480px',
        tablet: '768px',
        desktop: '1024px',
        wide: '1200px'
    }
}; 