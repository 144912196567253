import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import YouTubeAuth from '../components/YouTubeAuth';
import { deleteUserAccount } from '../control/firebase';
import { PY_URL, YT_REDIRECT_URI } from '../config'; // Ensure PY_URL is correctly set in your config

const Profile = () => {
  const [activeSection, setActiveSection] = useState('account');
  const { currentUser, userProfile, logout } = useAuth();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState('');
  const [isYouTubeLinked, setIsYouTubeLinked] = useState(false); // New state for YouTube link status

  useEffect(() => {
    // Check if YouTube account is linked on component mount
    const checkYouTubeAuth = async () => {
      try {
        const response = await fetch(`${PY_URL}/check-youtube-auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.uid }),
        });
        const data = await response.json();
        setIsYouTubeLinked(data.isAuthenticated);
      } catch (error) {
        console.error('Error checking YouTube auth:', error);
      }
    };

    checkYouTubeAuth();
  }, [currentUser.uid]);

  const handleYouTubeLinkToggle = async () => {
    if (isYouTubeLinked) {
      // Unlink YouTube account logic
      try {
        const response = await fetch(`${PY_URL}/delete-user-yt-auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.uid }),
        });
        if (response.ok) {
          setIsYouTubeLinked(false);
        } else {
          console.error('Failed to unlink YouTube account');
        }
      } catch (error) {
        console.error('Error unlinking YouTube account:', error);
      }
    } else {
      // Fetch the YouTube auth URL and open it
      try {
        const response = await fetch(`${PY_URL}/get_youtube_auth_url?redirect_uri=${encodeURIComponent(YT_REDIRECT_URI)}`, {
          method: 'GET',
        });
        const data = await response.json();
        if (data.auth_url) {
          window.open(data.auth_url, '_blank');
        } else {
          console.error('Failed to get YouTube auth URL');
        }
      } catch (error) {
        console.error('Error fetching YouTube auth URL:', error);
      }
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const handleDeleteAccount = async () => {
    setDeleteLoading(true);
    setDeleteError('');
    
    try {
      await deleteUserAccount(currentUser.uid);
    } catch (error) {
      setDeleteError('Failed to delete account: ' + error.message);
      setDeleteLoading(false);
    }
  };

  const DeleteAccountModal = () => (
    <div style={{
      ...modalStyles.modalOverlay,
      ...(showDeleteModal ? modalStyles.modalOverlayShow : {})
    }}>
      <div style={modalStyles.modalContent}>
        <h3 style={modalStyles.modalTitle}>Delete Account</h3>
        <p style={modalStyles.modalText}>
          Are you sure you want to delete your account? This action cannot be undone and all your data will be permanently deleted.
        </p>
        
        {deleteError && (
          <div style={modalStyles.deleteError}>
            <i className="mdi mdi-alert-circle"></i>
            {deleteError}
          </div>
        )}
        
        <div style={styles.formGroup}>
          <label style={styles.label}>Type "DELETE" to confirm:</label>
          <input 
            type="text" 
            style={modalStyles.deleteConfirmInput}
            placeholder="DELETE"
            onChange={(e) => setDeleteConfirm(e.target.value)}
          />
        </div>
        
        <div style={modalStyles.modalButtons}>
          <button 
            style={{...styles.button, ...styles.buttonSecondary, flex: 1}}
            onClick={() => setShowDeleteModal(false)}
            disabled={deleteLoading}
          >
            Cancel
          </button>
          <button 
            style={{...styles.button, ...styles.buttonDanger, flex: 1}}
            onClick={handleDeleteAccount}
            disabled={deleteLoading || deleteConfirm !== 'DELETE'}
          >
            {deleteLoading ? (
              <>
                <i className="mdi mdi-loading mdi-spin"></i>
                Deleting...
              </>
            ) : (
              <>
                <i className="mdi mdi-delete-forever"></i>
                Delete Account
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );

  const profileData = {
    name: userProfile?.displayName || currentUser?.displayName || '',
    email: currentUser?.email || '',
    avatar: userProfile?.imageUrl || currentUser?.photoURL || 'https://via.placeholder.com/150',
    role: userProfile?.role || '',
    church: userProfile?.church || '',
    ministryAreas: userProfile?.ministryAreas || ''
  };

  const renderPreferencesSection = () => (
    <div>
      <div style={styles.preferenceSection}>
        <div style={styles.preferenceItem}>
          <div style={styles.preferenceContent}>
            <i className="mdi mdi-bell" style={styles.preferenceIcon}></i>
            <div style={styles.preferenceTextGroup}>
              <span style={styles.preferenceName}>Email Notifications</span>
              <span style={styles.preferenceDescription}>Receive updates about your account</span>
            </div>
          </div>
          <div className="toggle-switch">
            <input type="checkbox" id="notifications" className="toggle-input" />
            <label htmlFor="notifications" className="toggle-label"></label>
          </div>
        </div>

        <div style={styles.preferenceItem}>
          <div style={styles.preferenceContent}>
            <i className="mdi mdi-theme-light-dark" style={styles.preferenceIcon}></i>
            <div style={styles.preferenceTextGroup}>
              <span style={styles.preferenceName}>Dark Mode</span>
              <span style={styles.preferenceDescription}>Switch between light and dark themes</span>
            </div>
          </div>
          <div className="toggle-switch">
            <input type="checkbox" id="darkMode" className="toggle-input" />
            <label htmlFor="darkMode" className="toggle-label"></label>
          </div>
        </div>
      </div>
    </div>
  );

  const renderAccountSection = () => (
    <div>
      {/* Basic Information */}
      <div style={styles.formGroup}>
        <label style={styles.label}>Full Name</label>
        <input 
          type="text" 
          style={styles.input}
          value={profileData.name} 
          placeholder="Enter your full name"
        />
      </div>
      <div style={styles.formGroup}>
        <label style={styles.label}>Email</label>
        <input 
          type="email" 
          style={styles.input}
          value={profileData.email} 
          placeholder="Enter your email"
        />
      </div>

      {/* Church Information */}
      <div style={styles.formGroup}>
        <label style={styles.label}>Home Church</label>
        <input 
          type="text" 
          style={styles.input}
          value={profileData.church || ''} 
          placeholder="Enter your home church"
        />
      </div>
      <div style={styles.formGroup}>
        <label style={styles.label}>Ministry Role</label>
        <input 
          type="text" 
          style={styles.input}
          value={profileData.role || ''} 
          placeholder="e.g., Pastor, Staff, Member"
        />
      </div>
      <div style={styles.formGroup}>
        <label style={styles.label}>Ministry Areas</label>
        <input 
          type="text" 
          style={styles.input}
          value={profileData.ministryAreas || ''} 
          placeholder="#worship #youth #outreach #prayer"
        />
      </div>

      <div style={styles.cardActions}>
        <button style={{...styles.button, ...styles.buttonPrimary}}>
          <i className="mdi mdi-content-save"></i>
          Save Changes
        </button>
      </div>
    </div>
  );

  const renderManageAccountSection = () => (
    <div>
      {/* Connected Accounts Section */}
      <div style={styles.manageSection}>
        <div style={styles.manageSectionHeader}>
          <i className="mdi mdi-youtube" style={{...styles.manageSectionIcon, color: '#FF0000'}}></i>
          <h4 style={styles.manageSectionTitle}>YouTube Account</h4>
        </div>
        <div style={styles.connectedAccountContent}>
          <div style={styles.accountStatus}>
            <span style={styles.statusText}>
              {isYouTubeLinked ? 'Your YouTube account is linked' : 'Link your YouTube account to import sermons'}
            </span>
          </div>
          <button 
            style={{...styles.button, ...styles.buttonConnect}}
            onClick={handleYouTubeLinkToggle}
          >
            {isYouTubeLinked ? 'Unlink' : 'Connect'}
          </button>
        </div>
      </div>

      {/* Account Actions Section */}
      <div style={styles.actionSection}>
        <div style={styles.actionButtons}>
          <button 
            style={styles.actionButton}
            onClick={handleLogout}
          >
            <i className="mdi mdi-logout" style={styles.actionIcon}></i>
            <span style={styles.actionText}>Sign Out</span>
          </button>
          <button 
            style={{...styles.actionButton, ...styles.actionButtonDanger}}
            onClick={() => setShowDeleteModal(true)}
          >
            <i className="mdi mdi-delete-forever" style={styles.actionIcon}></i>
            <span style={styles.actionText}>Delete Account</span>
          </button>
        </div>
      </div>
    </div>
  );

  const renderSubscriptionSection = () => (
    <div>
      <div style={styles.subscriptionSection}>
        <div style={styles.subscriptionHeader}>
          <i className="mdi mdi-crown" style={{...styles.subscriptionIcon, color: '#FFD700'}}></i>
          <h4 style={styles.subscriptionTitle}>Premium Plan</h4>
        </div>
        <div style={styles.subscriptionDetails}>
          <div style={styles.subscriptionInfo}>
            <i className="mdi mdi-calendar" style={styles.infoIcon}></i>
            <span style={styles.infoText}>Next billing: April 1, 2024</span>
          </div>
          <div style={styles.subscriptionInfo}>
            <i className="mdi mdi-check-circle" style={{...styles.infoIcon, color: 'var(--success-color)'}}></i>
            <span style={styles.infoText}>Active Subscription</span>
          </div>
        </div>
      </div>

      <div style={styles.subscriptionActions}>
        <button style={{...styles.button, ...styles.buttonPrimary, width: '100%'}}>
          <i className="mdi mdi-credit-card"></i>
          Manage Plan
        </button>
        <button style={{...styles.button, ...styles.buttonSecondary, width: '100%'}}>
          <i className="mdi mdi-history"></i>
          View History
        </button>
      </div>
    </div>
  );

  const styles = {
    profileContainer: {
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '1rem',
      background: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    communityGrid: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width: '100%',
      maxWidth: '600px',
      alignItems: 'center',
    },
    profileCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1.5rem',
      padding: '2rem',
      background: 'transparent',
      border: 'none',
      boxShadow: 'none',
    },
    avatarContainer: {
      position: 'relative',
      width: '120px',
      height: '120px',
      borderRadius: '50%',
      overflow: 'hidden',
      border: '3px solid rgba(255, 255, 255, 0.7)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07)',
    },
    profileAvatar: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '50%',
      border: '3px solid #FFFFFF',
    },
    buttonIcon: {
      position: 'absolute',
      bottom: '8px',
      right: '8px',
      background: '#333333',
      border: 'none',
      borderRadius: '50%',
      width: '36px',
      height: '36px',
      color: 'white',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
      zIndex: 2,
    },
    profileInfo: {
      textAlign: 'center',
      padding: '0.5rem',
      position: 'relative',
    },
    profileName: {
      margin: '0 0 0.25rem',
      fontSize: '1.5rem',
      fontWeight: '700',
      color: '#333333',
      fontFamily: "'Cardo', serif",
      letterSpacing: '-0.5px',
    },
    profileRole: {
      color: '#333333',
      margin: '0 0 0.5rem',
      fontSize: '1.1rem',
      fontWeight: '500',
      opacity: 0.9,
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    profileChurch: {
      color: '#333333',
      fontSize: '1rem',
      marginTop: '0.5rem',
      fontStyle: 'italic',
    },
    navCard: {
      background: 'white',
      borderRadius: '12px',
      padding: '1rem',
      boxShadow: 'var(--shadow-md)',
      marginBottom: '1.5rem',
    },
    navButton: {
      width: '100%',
      padding: '0.35rem 0.25rem',
      border: 'none',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '1rem',
      marginBottom: '0.5rem',
      background: 'transparent',
      color: '#333333',
    },
    navButtonActive: {
      background: '#333333',
      color: 'white',
    },
    navButtonInactive: {
      color: 'var(--text-color)',
      '&:hover': {
        background: 'rgba(74, 85, 104, 0.1)',
      },
    },
    contentCard: {
      background: '#FFFFFF',
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: 'var(--shadow-sm)',
      marginBottom: '1rem',
      border: '1px solid #E2E8F0',
      padding: '1rem',
      width: '100%',
      maxWidth: '600px',
    },
    sectionTitle: {
      fontSize: '1.5rem',
      fontWeight: '600',
      marginBottom: '1rem',
      color: '#333333',
      fontFamily: "'Cardo', serif",
      position: 'relative',
      zIndex: 1,
    },
    formGroup: {
      marginBottom: '0.5rem',
    },
    label: {
      display: 'block',
      marginBottom: '0.25rem',
      color: '#333333',
      fontWeight: '500',
      fontSize: '0.9rem',
    },
    input: {
      width: '100%',
      padding: '0.5rem 0.75rem',
      borderRadius: '8px',
      border: '1px solid #E2E8F0',
      transition: 'all 0.2s ease',
      fontSize: '0.95rem',
      color: '#333333',
      background: '#F8FAFC',
      '&:focus': {
        outline: 'none',
        borderColor: '#4A5568',
        background: '#FFFFFF',
        boxShadow: '0 0 0 3px rgba(74, 85, 104, 0.1)',
      },
    },
    dangerZone: {
      marginTop: '1rem',
      padding: '1rem',
      border: '2px solid var(--error-color)',
      borderRadius: '12px',
      background: 'rgba(220, 53, 69, 0.05)',
    },
    dangerTitle: {
      color: 'var(--error-color)',
      fontSize: '1.1rem',
      fontWeight: '600',
      marginBottom: '0.75rem',
    },
    dangerText: {
      color: '#333333',
      marginBottom: '1rem',
      fontSize: '0.95rem',
    },
    button: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      padding: '0.75rem',
      border: 'none',
      borderRadius: '12px',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.95rem',
      fontWeight: '500',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    buttonPrimary: {
      background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
      color: 'white',
      backdropFilter: 'blur(4px)',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
      },
    },
    buttonDanger: {
      background: 'linear-gradient(135deg, rgba(220, 53, 69, 0.85) 0%, rgba(220, 53, 69, 0.75) 100%)',
      color: 'white',
      backdropFilter: 'blur(4px)',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(220, 53, 69, 0.3)',
      },
    },
    preferencesSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    preferenceItem: {
      background: '#FFFFFF',
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: 'var(--shadow-sm)',
      marginBottom: '1rem',
      border: '1px solid #E2E8F0',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    preferenceContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    preferenceIcon: {
      fontSize: '1.4rem',
      color: '#333333',
    },
    preferenceTextGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
    },
    preferenceName: {
      fontSize: '0.95rem',
      fontWeight: '500',
      color: '#333333',
    },
    preferenceDescription: {
      fontSize: '0.85rem',
      color: '#666666',
    },
    switch: {
      position: 'relative',
      display: 'inline-block',
      width: '44px',
      height: '24px',
      margin: '0',
    },
    switchInput: {
      opacity: 0,
      width: 0,
      height: 0,
    },
    slider: {
      position: 'absolute',
      cursor: 'pointer',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#CBD5E0',
      transition: '0.3s',
      borderRadius: '34px',
    },
    connectedAccounts: {
      marginBottom: '2rem',
    },
    accountActions: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    buttonSecondary: {
      background: 'rgba(241, 245, 249, 0.8)',
      color: '#666666',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      '&:hover': {
        background: 'rgba(226, 232, 240, 0.9)',
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      },
    },
    buttonBlock: {
      width: '100%',
      justifyContent: 'center',
    },
    navSection: {
      width: '100%',
      maxWidth: '600px',
      marginBottom: '0.25rem',
      background: 'transparent',
    },
    navBar: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '0.5rem',
      width: '100%',
      borderBottom: '2px solid #EDF2F7',
      padding: '0',
      marginBottom: '0.25rem',
    },
    navButton: {
      flex: 1,
      padding: '0.35rem 0.25rem',
      border: 'none',
      background: 'transparent',
      color: '#333333',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.8rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.15rem',
      position: 'relative',
    },
    navButtonActive: {
      color: 'var(--primary-color)',
    },
    navIcon: {
      fontSize: '1.2rem',
      color: '#333333',
      transition: 'color 0.2s ease',
    },
    navIconActive: {
      color: 'var(--primary-color)',
    },
    navLabel: {
      fontSize: '0.8rem',
      fontWeight: '500',
      color: '#333333',
    },
    formSection: {
      marginBottom: '1rem',
      padding: '1rem',
      background: '#FFFFFF',
      borderRadius: '12px',
      border: '1px solid #EDF2F7',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
    },
    subSectionTitle: {
      fontSize: '1.1rem',
      fontWeight: '600',
      marginBottom: '0.75rem',
      color: '#333333',
      fontFamily: "'Cardo', serif",
      borderBottom: '1px solid #EDF2F7',
      paddingBottom: '0.25rem',
    },
    select: {
      width: '100%',
      padding: '0.75rem',
      borderRadius: '8px',
      border: '1px solid var(--border-color)',
      background: 'white',
      fontSize: '1rem',
      color: 'var(--text-color)',
      cursor: 'pointer',
      transition: 'border-color 0.2s ease',
      '&:focus': {
        outline: 'none',
        borderColor: 'var(--primary-color)',
      },
    },
    checkboxGroup: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
      gap: '0.75rem',
      marginTop: '0.5rem',
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      padding: '0.5rem',
      borderRadius: '6px',
      transition: 'background 0.2s ease',
      '&:hover': {
        background: '#F7FAFC',
      },
    },
    checkboxInput: {
      width: '18px',
      height: '18px',
      cursor: 'pointer',
    },
    checkboxLabel: {
      fontSize: '0.95rem',
      color: 'var(--text-color)',
    },
    cardActions: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'center',
    },
    profileIcon: {
      marginRight: '8px',
      color: '#333333',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    buttonSmall: {
      padding: '0.5rem 1rem',
      fontSize: '0.85rem',
      minWidth: 'auto',
    },
    manageSection: {
      background: '#FFFFFF',
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: 'var(--shadow-sm)',
      marginBottom: '1rem',
      border: '1px solid #E2E8F0',
      padding: '1rem',
    },
    manageSectionHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      marginBottom: '0.75rem',
    },
    manageSectionIcon: {
      fontSize: '1.3rem',
    },
    manageSectionTitle: {
      fontSize: '1rem',
      fontWeight: '600',
      color: '#333333',
      margin: 0,
    },
    connectedAccountContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
    },
    accountStatus: {
      flex: 1,
    },
    statusText: {
      fontSize: '0.9rem',
      color: '#666666',
    },
    buttonConnect: {
      background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
      color: 'white',
      backdropFilter: 'blur(4px)',
      padding: '0.5rem 1rem',
      fontSize: '0.85rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      transition: 'all 0.2s ease',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
      },
    },
    actionSection: {
      marginTop: '1rem',
      padding: '0.75rem',
      background: 'rgba(255, 255, 255, 0.8)',
      backdropFilter: 'blur(10px)',
      borderRadius: '12px',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.07)',
    },
    actionButtons: {
      display: 'flex',
      gap: '0.75rem',
      width: '100%',
    },
    actionButton: {
      flex: 1,
      padding: '0.75rem',
      background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
      color: 'white',
      border: 'none',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      transition: 'all 0.2s ease',
      backdropFilter: 'blur(4px)',
      boxShadow: '0 2px 4px rgba(255, 0, 0, 0.2)',
      cursor: 'pointer',
      fontSize: '0.9rem',
      fontWeight: '500',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
      },
    },
    actionButtonDanger: {
      background: 'linear-gradient(135deg, rgba(220, 53, 69, 0.85) 0%, rgba(220, 53, 69, 0.75) 100%)',
      boxShadow: '0 2px 4px rgba(220, 53, 69, 0.2)',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(220, 53, 69, 0.3)',
      },
    },
    actionIcon: {
      fontSize: '1.2rem',
      filter: 'drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2))',
    },
    actionText: {
      fontSize: '0.85rem',
      fontWeight: '500',
    },
    subscriptionSection: {
      background: '#FFFFFF',
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: 'var(--shadow-sm)',
      marginBottom: '1rem',
      border: '1px solid #E2E8F0',
      padding: '1rem',
    },
    subscriptionHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      marginBottom: '1rem',
      paddingBottom: '0.75rem',
      borderBottom: '1px solid #EDF2F7',
    },
    subscriptionIcon: {
      fontSize: '1.5rem',
    },
    subscriptionTitle: {
      fontSize: '1.1rem',
      fontWeight: '600',
      color: '#333333',
      margin: 0,
    },
    subscriptionDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem',
    },
    subscriptionInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
    },
    infoIcon: {
      fontSize: '1.2rem',
      color: '#333333',
    },
    infoText: {
      fontSize: '0.9rem',
      color: '#333333',
    },
    subscriptionActions: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      marginTop: '1rem',
    },
  };

  const renderNavButtons = () => (
    <div style={styles.navSection}>
      <div style={styles.navBar}>
        {[
          { id: 'account', icon: 'mdi-account', label: 'Info' },
          { id: 'preferences', icon: 'mdi-tune', label: 'Preferences' },
          { id: 'subscription', icon: 'mdi-credit-card', label: 'Subscription' },
          { id: 'manage', icon: 'mdi-cog-outline', label: 'Manage' },
        ].map(nav => (
          <button
            key={nav.id}
            style={{
              ...styles.navButton,
              ...(activeSection === nav.id && styles.navButtonActive)
            }}
            onClick={() => setActiveSection(nav.id)}
          >
            <i 
              className={`mdi ${nav.icon}`} 
              style={{
                ...styles.navIcon,
                ...(activeSection === nav.id && styles.navIconActive)
              }}
            ></i>
            <span style={styles.navLabel}>{nav.label}</span>
          </button>
        ))}
      </div>
    </div>
  );

  const renderProfileCard = () => (
    <div className="card-common profile-card" style={styles.profileCard}>
      <div style={styles.avatarContainer}>
        <img 
          className="profile-avatar"
          src={profileData.avatar} 
          alt={profileData.name} 
          style={styles.profileAvatar}
        />
        <button 
          className="button-icon"
          style={styles.buttonIcon}
          title="Change profile picture"
          onClick={() => {/* Add your image upload logic here */}}
        >
          <i className="mdi mdi-camera"></i>
        </button>
      </div>
      <div style={styles.profileInfo}>
        <h2 style={styles.profileName}>{profileData.name}</h2>
        {profileData.role && (
          <p style={styles.profileRole}>
            <i className="mdi mdi-badge-account" style={styles.profileIcon}></i>
            {profileData.role}
          </p>
        )}
        {profileData.church && (
          <p style={styles.profileChurch}>
            <i className="mdi mdi-church" style={styles.profileIcon}></i>
            {profileData.church}
          </p>
        )}
      </div>
    </div>
  );

  const modalStyles = {
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
      opacity: 0,
      visibility: 'hidden',
      transition: 'all 0.3s ease',
    },
    modalOverlayShow: {
      opacity: 1,
      visibility: 'visible',
    },
    modalContent: {
      background: 'white',
      borderRadius: '12px',
      padding: '2rem',
      width: '90%',
      maxWidth: '500px',
      position: 'relative',
      boxShadow: 'var(--shadow-lg)',
      transform: 'translateY(20px)',
      transition: 'transform 0.3s ease forwards',
      animation: 'slideUp 0.3s ease forwards',
    },
    modalTitle: {
      fontSize: '1.5rem',
      fontWeight: '600',
      color: 'var(--text-color)',
      marginBottom: '1rem',
      fontFamily: "'Cardo', serif",
    },
    modalText: {
      color: 'var(--text-light-color)',
      marginBottom: '1.5rem',
      lineHeight: '1.5',
    },
    modalButtons: {
      display: 'flex',
      gap: '1rem',
      marginTop: '2rem',
    },
    deleteConfirmInput: {
      width: '100%',
      padding: '0.75rem',
      borderRadius: '8px',
      border: '1px solid var(--border-color)',
      marginTop: '0.5rem',
      fontSize: '1rem',
      transition: 'all 0.2s ease',
      '&:focus': {
        outline: 'none',
        borderColor: 'var(--error-color)',
        boxShadow: '0 0 0 2px rgba(220, 53, 69, 0.1)',
      },
    },
    deleteError: {
      background: 'rgba(220, 53, 69, 0.1)',
      color: 'var(--error-color)',
      padding: '1rem',
      borderRadius: '8px',
      marginBottom: '1rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
  };

  return (
    <div style={styles.profileContainer}>
      <div style={styles.communityGrid}>
        {renderProfileCard()}
        {renderNavButtons()}
        <div className="card-common content-card" style={styles.contentCard}>
          {activeSection === 'account' && renderAccountSection()}
          {activeSection === 'preferences' && renderPreferencesSection()}
          {activeSection === 'manage' && renderManageAccountSection()}
          {activeSection === 'subscription' && renderSubscriptionSection()}
        </div>
      </div>
      <DeleteAccountModal />
    </div>
  );
};

export default Profile;
