import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useAuth } from '../../../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../control/firebase';

const styles = {
  finalReview: {
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto'
  },
  reviewHeader: {
    marginBottom: '2rem'
  },
  errorMessage: {
    color: '#dc3545',
    padding: '1rem',
    marginBottom: '1rem',
    borderRadius: '8px',
    background: 'rgba(220, 53, 69, 0.1)',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem'
  },
  buttonGroup: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '2rem'
  },
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.75rem 1.5rem',
    borderRadius: '8px',
    fontWeight: '600',
    fontSize: '0.95rem',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
  },
  buttonSecondary: {
    background: 'transparent',
    color: '#FF416C',
    border: '2px solid #FF416C'
  },
  buttonPrimary: {
    background: 'linear-gradient(135deg, #FF416C 0%, #FF4B2B 100%)',
    color: 'white',
    border: 'none',
    boxShadow: '0 4px 15px rgba(255, 65, 108, 0.2)'
  },
  reviewSections: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
  },
  reviewSection: {
    background: 'white',
    borderRadius: '12px',
    padding: '1.5rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'
  },
  sectionTitle: {
    fontSize: '1.25rem',
    fontWeight: '600',
    marginBottom: '1rem',
    color: '#333'
  },
  contentBox: {
    background: '#f8f9fa',
    padding: '1.5rem',
    borderRadius: '8px',
    border: '1px solid #e0e0e0'
  },
  highlightsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem'
  },
  highlightItem: {
    background: 'white',
    borderRadius: '8px',
    padding: '1rem',
    border: '1px solid #e0e0e0'
  },
  highlightContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  },
  blockquote: {
    borderLeft: '4px solid #FF416C',
    paddingLeft: '1rem',
    margin: '1rem 0',
    fontStyle: 'italic',
    color: '#666'
  },
  highlightNote: {
    fontSize: '0.875rem',
    color: '#666'
  },
  highlightVideo: {
    marginTop: '1rem'
  },
  timestampLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: '#FF416C',
    textDecoration: 'none',
    fontSize: '0.875rem',
    marginTop: '0.5rem'
  }
};

const FinalReview = ({ processedContent }) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  const handleSaveToLibrary = async () => {
    setSaving(true);
    setError('');

    try {
      const sermonId = processedContent.sermonId;
      
      console.log('ProcessedContent:', processedContent);
      console.log('Saving sermon with ID:', sermonId);
      
      if (!sermonId) {
        throw new Error('No sermon ID found');
      }

      // Reference to the specific sermon document
      const sermonRef = doc(db, 'users', currentUser.uid, 'sermons', sermonId);

      // Update the sermon document with all processed content
      const updateData = {
        // Original sermon data from initial creation
        title: processedContent.title || 'Untitled Sermon',
        description: processedContent.description || '',
        scripture: processedContent.scripture || '',
        date: processedContent.date || new Date().toISOString(),
        notes: processedContent.notes || '',
        
        // Source information
        youtubeUrl: processedContent.youtubeUrl || '',
        sourceType: processedContent.youtubeUrl ? 'youtube' : 'upload',
        
        // Original and processed transcripts
        transcript: processedContent.transcript || '',
        refinedTranscript: processedContent.refinedTranscript || '',
        
        // AI-generated content
        summary: processedContent.summary || '',
        lessonPlan: processedContent.lessonPlan || '',
        highlights: processedContent.highlights || '',
        
        // Metadata
        updatedAt: new Date().toISOString(),
        status: 'completed',
        
        // Additional metadata
        timezone: processedContent.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        lastModifiedBy: currentUser.uid,
        completedAt: new Date().toISOString()
      };

      console.log('Updating sermon with data:', updateData);

      await updateDoc(sermonRef, updateData);
      console.log('Sermon saved successfully');

      // Navigate to library on success
      navigate('/library');
    } catch (err) {
      console.error('Error saving sermon:', err);
      console.error('ProcessedContent:', processedContent);
      setError('Failed to save sermon: ' + err.message);
    } finally {
      setSaving(false);
    }
  };

  const renderHighlights = () => {
    if (!processedContent.highlights) return null;

    try {
      // First parse the outer structure
      const highlightsData = JSON.parse(processedContent.highlights);
      
      // Then parse the nested highlights string
      const parsedHighlights = JSON.parse(highlightsData.highlights);
      const highlights = parsedHighlights.highlights;

      // Get YouTube video ID if URL exists
      const videoId = processedContent.youtubeUrl ? getYoutubeVideoId(processedContent.youtubeUrl) : null;

      return (
        <div style={styles.highlightsList}>
          {highlights.map((highlight, index) => (
            <div key={index} style={styles.highlightItem}>
              <div style={styles.highlightContent}>
                <blockquote style={styles.blockquote}>{highlight.quote}</blockquote>
                <p style={styles.highlightNote}>{highlight.note}</p>
                {videoId && (
                  <div style={styles.highlightVideo}>
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${videoId}?start=${Math.floor(parseFloat(highlight.start_time))}`}
                      title={`Highlight ${index + 1}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <div style={styles.timestampLink}>
                      <a 
                        href={`https://youtu.be/${videoId}?t=${Math.floor(parseFloat(highlight.start_time))}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="mdi mdi-youtube"></i>
                        Watch on YouTube at {formatTimestamp(highlight.start_time)}
                        {highlight.end_time && ` - ${formatTimestamp(highlight.end_time)}`}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    } catch (error) {
      console.error('Error parsing highlights:', error);
      return (
        <div style={styles.errorMessage}>
          Error displaying highlights
        </div>
      );
    }
  };

  const getYoutubeVideoId = (url) => {
    try {
      if (url.includes('youtu.be/')) {
        return url.split('youtu.be/')[1].split('?')[0];
      } else if (url.includes('youtube.com/watch')) {
        return url.split('v=')[1].split('&')[0];
      }
    } catch (err) {
      console.error('Error extracting video ID:', err);
    }
    return null;
  };

  const formatTimestamp = (seconds) => {
    const time = parseFloat(seconds);
    const minutes = Math.floor(time / 60);
    const remainingSeconds = Math.floor(time % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div style={styles.finalReview}>
      <div style={styles.reviewHeader}>
        {error && (
          <div style={styles.errorMessage}>
            <i className="mdi mdi-alert"></i>
            {error}
          </div>
        )}
        
        <div style={styles.buttonGroup}>
          <button
            style={{...styles.button, ...styles.buttonSecondary}}
            onClick={() => navigate(-1)}
            disabled={saving}
          >
            <i className="mdi mdi-arrow-left"></i>
            Back to Processing
          </button>
          <button
            style={{...styles.button, ...styles.buttonPrimary}}
            onClick={handleSaveToLibrary}
            disabled={saving}
          >
            {saving ? (
              <>
                <i className="mdi mdi-loading mdi-spin"></i>
                Saving...
              </>
            ) : (
              <>
                <i className="mdi mdi-content-save"></i>
                Save to Library
              </>
            )}
          </button>
        </div>
      </div>
      
      <div style={styles.reviewSections}>
        <section style={styles.reviewSection}>
          <h3 style={styles.sectionTitle}>Refined Transcript</h3>
          <div style={styles.contentBox}>
            <ReactMarkdown>{processedContent.refinedTranscript}</ReactMarkdown>
          </div>
        </section>

        <section style={styles.reviewSection}>
          <h3 style={styles.sectionTitle}>Summary</h3>
          <div style={styles.contentBox}>
            <ReactMarkdown>{processedContent.summary}</ReactMarkdown>
          </div>
        </section>

        <section style={styles.reviewSection}>
          <h3 style={styles.sectionTitle}>Lesson Plan</h3>
          <div style={styles.contentBox}>
            <div dangerouslySetInnerHTML={{ __html: processedContent.lessonPlan }} />
          </div>
        </section>

        <section style={styles.reviewSection}>
          <h3 style={styles.sectionTitle}>Highlights</h3>
          <div style={styles.contentBox}>
            {renderHighlights()}
          </div>
        </section>
      </div>
    </div>
  );
};

export default FinalReview; 