import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { PY_URL, YT_REDIRECT_URI } from '../../../config';
import ReactMarkdown from 'react-markdown';

const styles = {
  container: {
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '-10px',
  },
  card: {
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
    marginBottom: '1rem',
    border: '1px solid rgba(255, 255, 255, 0.7)',
    width: '100%',
  },
  iconBar: {
    width: '100%',
    height: '48px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '0',
    lineHeight: '1',
  },
  feedIcon: {
    color: 'white',
    fontSize: '1.5rem',
    filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: '1px',
  },
  header: {
    padding: '1.5rem',
    borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
    background: 'rgba(255, 255, 255, 0.3)',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: '#2D3748',
    marginBottom: '0.5rem',
    fontFamily: "'Cardo', serif",
  },
  description: {
    color: '#4A5568',
    fontSize: '1rem',
    lineHeight: '1.6',
  },
  content: {
    padding: '1.5rem',
    background: 'rgba(255, 255, 255, 0.2)',
  },
  formGroup: {
    marginBottom: '1.5rem',
  },
  label: {
    display: 'block',
    marginBottom: '0.5rem',
    fontWeight: '500',
    color: '#2D3748',
  },
  urlInputContainer: {
    position: 'relative',
    background: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '12px',
    padding: '0.25rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  youtubeIcon: {
    position: 'absolute',
    left: '1rem',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#FF0000',
    fontSize: '1.2rem',
  },
  input: {
    width: '100%',
    padding: '0.75rem 1rem 0.75rem 2.5rem',
    borderRadius: '8px',
    border: '1px solid rgba(226, 232, 240, 0.8)',
    fontSize: '1rem',
    background: 'transparent',
    color: '#2D3748',
    transition: 'all 0.2s ease',
    '&:focus': {
      outline: 'none',
      borderColor: 'var(--primary-color)',
      boxShadow: '0 0 0 3px rgba(255, 0, 0, 0.1)',
    },
  },
  buttonGroup: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1.5rem',
  },
  button: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    padding: '0.75rem',
    border: 'none',
    borderRadius: '12px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    fontSize: '0.95rem',
    fontWeight: '500',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  buttonPrimary: {
    background: 'rgba(255, 0, 0, 0.8)',
    color: 'white',
    boxShadow: '0 2px 4px rgba(255, 0, 0, 0.2)',
    '&:hover': {
      background: 'rgba(255, 0, 0, 0.9)',
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
    },
  },
  buttonSecondary: {
    background: 'rgba(241, 245, 249, 0.8)',
    color: '#666666',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      background: 'rgba(226, 232, 240, 0.9)',
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
  },
  transcriptPreview: {
    marginTop: '1.5rem',
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.7)',
  },
  transcriptContent: {
    padding: '1.5rem',
    maxHeight: '400px',
    overflowY: 'auto',
    background: 'rgba(255, 255, 255, 0.2)',
    color: '#2D3748',
    fontSize: '1rem',
    lineHeight: '1.6',
  },
  errorMessage: {
    background: 'rgba(220, 53, 69, 0.1)',
    color: '#dc3545',
    padding: '1rem',
    borderRadius: '12px',
    marginBottom: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    backdropFilter: 'blur(4px)',
  },
};

const YouTubeInput = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sermonId } = useParams();
  const { currentUser } = useAuth();
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [transcript, setTranscript] = useState('');

  // Check if user has YouTube auth on component mount
  useEffect(() => {
    checkYouTubeAuth();
  }, []);

  const checkYouTubeAuth = async () => {
    try {
      const response = await fetch(`${PY_URL}/check-youtube-auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: currentUser.uid
        }),
      });
      
      if (response.ok) {
        const data = await response.json();
        setIsAuthenticated(data.isAuthenticated);
      }
    } catch (err) {
      console.error('Error checking YouTube auth:', err);
      setError('Failed to check YouTube authentication status');
    }
  };

  const handleAuth = async () => {
    try {
      const response = await fetch(`${PY_URL}/get_youtube_auth_url`);
      const data = await response.json();
      
      if (data && data.auth_url) {
        window.location.href = data.auth_url;
      } else {
        throw new Error('Invalid auth URL received from server');
      }
    } catch (err) {
      console.error('Error getting auth URL:', err);
      setError('Failed to initialize YouTube authentication');
    }
  };

  const validateYouTubeUrl = (url) => {
    const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    return pattern.test(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateYouTubeUrl(url)) {
      setError('Please enter a valid YouTube URL');
      return;
    }

    if (!isAuthenticated) {
      handleAuth();
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${PY_URL}/download-transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url,
          user_id: currentUser.uid,
          redirect_uri: YT_REDIRECT_URI
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to process YouTube video');
      }

      const data = await response.json();
      setTranscript(data.transcript);
    } catch (err) {
      setError('Failed to process YouTube video: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = () => {
    navigate(`/create/sermon/process/${sermonId}`, { 
      state: { 
        content: { 
          transcript,
          sermonId,
          youtubeUrl: url
        } 
      }
    });
  };

  // Handle OAuth callback
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    
    if (code) {
      handleOAuthCallback(code);
    }
  }, [location]);

  const handleOAuthCallback = async (code) => {
    try {
      const response = await fetch(`${PY_URL}/store-youtube-auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code,
          user_id: currentUser.uid
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to store YouTube credentials');
      }

      setIsAuthenticated(true);
      // Clean up URL
      window.history.replaceState({}, document.title, window.location.pathname);
    } catch (err) {
      console.error('Error storing YouTube auth:', err);
      setError('Failed to complete YouTube authentication');
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.iconBar}>
          <i className="mdi mdi-youtube" style={styles.feedIcon}></i>
        </div>

        <div style={styles.header}>
          <h2 style={styles.title}>Import from YouTube</h2>
          <p style={styles.description}>
            {isAuthenticated 
              ? 'Enter the URL of your YouTube sermon video to import and transcribe it'
              : 'First, you need to authorize access to your YouTube account'}
          </p>
        </div>

        <div style={styles.content}>
          {error && (
            <div style={styles.errorMessage}>
              <i className="mdi mdi-alert"></i>
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="youtube-url">YouTube URL</label>
              <div style={styles.urlInputContainer}>
                <i style={styles.youtubeIcon} className="mdi mdi-youtube"></i>
                <input
                  type="url"
                  id="youtube-url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="https://www.youtube.com/watch?v=..."
                  style={styles.input}
                  required
                />
              </div>
            </div>

            <div style={styles.buttonGroup}>
              <button
                type="button"
                style={{...styles.button, ...styles.buttonSecondary}}
                onClick={() => navigate(-1)}
                disabled={loading}
              >
                <i className="mdi mdi-arrow-left"></i>
                Back
              </button>
              <button
                type="submit"
                style={{...styles.button, ...styles.buttonPrimary}}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <i className="mdi mdi-loading mdi-spin"></i>
                    Processing...
                  </>
                ) : isAuthenticated ? (
                  <>
                    <i className="mdi mdi-download"></i>
                    Import Transcript
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-youtube"></i>
                    Connect YouTube
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      {transcript && (
        <div style={styles.transcriptPreview}>
          <div style={styles.iconBar}>
            <i className="mdi mdi-text" style={styles.feedIcon}></i>
          </div>
          <div style={styles.header}>
            <h3 style={styles.title}>Imported Transcript</h3>
          </div>
          <div style={styles.transcriptContent}>
            <ReactMarkdown>{transcript}</ReactMarkdown>
          </div>
          <div style={styles.buttonGroup}>
            <button
              style={{...styles.button, ...styles.buttonPrimary}}
              onClick={handleContinue}
            >
              <i className="mdi mdi-arrow-right"></i>
              Continue to Processing
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default YouTubeInput;