export const MODE = process.env.NODE_ENV || 'development';

export const DEV_API_URL = 'http://localhost:8080';
export const PROD_API_URL = 'https://sts-ai-lpniihjc2a-uc.a.run.app';

export const DEV_PY_URL = 'http://localhost:5000';
export const PROD_PY_URL = 'https://sts-yt-downlaoder-lpniihjc2a-uc.a.run.app';
export const API_URL = MODE === 'development' ? DEV_API_URL : PROD_API_URL;
export const PY_URL = MODE === 'development' ? DEV_PY_URL : PROD_PY_URL;

export const DEV_YT_REDIRECT_URI = 'http://localhost:3000/';
export const PROD_YT_REDIRECT_URI = 'https://sermonize.ai/';
export const YT_REDIRECT_URI = MODE === 'development' ? DEV_YT_REDIRECT_URI : PROD_YT_REDIRECT_URI;

export const PAYPAL_CLIENT_ID = 'AT02FgP0HCAbymunovRdQ0nfkg6js37CBNigSyixjtaUqIzo_2oUZIlKaR2yo1CpLlRWaVPrXYRBsQJl';