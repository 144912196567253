import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { saveStudyToFirestore, saveBibleStudyDraft, getBibleStudyById } from '../../../control/firebase';
import { API_URL } from '../../../config';
import { collection, query, where, orderBy, limit, doc, setDoc, updateDoc, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../../../control/firebase';
import debounce from 'lodash/debounce';

const StudyCreator = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [currentStep, setCurrentStep] = useState(1);
  
  // Get today's date in YYYY-MM-DD format
  const today = new Date().toLocaleDateString('en-CA');
  
  const [studyData, setStudyData] = useState({
    title: '',
    description: '',
    startDate: today,
    endDate: '',
    scripture: '',
    frequency: 'weekly',
    ministryTags: '',
    materials: '',
    objectives: '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    sessions: [],
    studyFormat: 'in-person',
    difficultyLevel: 'beginner',
    targetAgeGroup: 'adults',
    languageOptions: ['English'],
    prerequisites: '',
    expectedOutcomes: []
  });
  
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('details');

  const tabs = [
    {
      id: 'details',
      title: 'Basic Details',
      icon: 'mdi-information'
    },
    {
      id: 'sessions',
      title: 'Study Sessions',
      icon: 'mdi-book-open-variant'
    },
    {
      id: 'settings',
      title: 'Study Settings',
      icon: 'mdi-cog'
    }
  ];

  const tabStyles = {
    navigationTabs: {
      width: '100%',
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '12px',
      padding: '0.5rem',
      marginBottom: '1rem',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.07)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
    },
    tabsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '0.5rem',
      width: '100%',
      padding: '0',
    },
    tab: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.25rem',
      padding: '0.5rem',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      background: 'transparent',
      position: 'relative',
      fontFamily: "'Cardo', serif",
      minWidth: 'auto',
    },
    tabIcon: {
      fontSize: '1.3rem',
      color: '#333333',
      transition: 'color 0.2s ease',
    },
    tabIconActive: {
      color: 'var(--primary-color)',
    },
    tabLabel: {
      fontSize: '0.75rem',
      fontWeight: '500',
      color: '#333333',
      fontFamily: "'Cardo', serif",
      whiteSpace: 'nowrap',
    },
    tabLabelActive: {
      color: 'var(--primary-color)',
      fontWeight: '600',
      fontFamily: "'Cardo', serif",
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudyData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const [docId, setDocId] = useState(null);

  const autosave = useCallback(async (newData) => {
    if (!currentUser) return;
    
    try {
      const studyDataForSave = {
        ...newData,
        status: 'draft',
        updatedAt: new Date().toISOString(),
        lastModifiedBy: currentUser.uid
      };

      // If no docId exists, create new document
      if (!docId) {
        studyDataForSave.createdAt = new Date().toISOString();
        studyDataForSave.createdBy = currentUser.uid;
        
        const studiesRef = collection(db, 'users', currentUser.uid, 'studies');
        const docRef = await addDoc(studiesRef, studyDataForSave);
        setDocId(docRef.id);
        return docRef.id;
      } 
      
      // Otherwise update existing document
      const studyRef = doc(db, 'users', currentUser.uid, 'studies', docId);
      await updateDoc(studyRef, studyDataForSave);
      return docId;
      
    } catch (error) {
      console.error('Error saving study:', error);
      setError('Failed to save study');
    }
  }, [currentUser, docId]);

  const handleNext = async (e) => {
    e.preventDefault();
    
    try {
      setError('');
      
      // Validate current tab
      if (activeTab === 'details') {
        if (!studyData.title.trim() || !studyData.scripture.trim()) {
          throw new Error('Title and Scripture reference are required');
        }
        setActiveTab('sessions');
        return;
      }
      
      if (activeTab === 'sessions') {
        if (studyData.sessions.length === 0) {
          throw new Error('At least one session is required');
        }
        setActiveTab('settings');
        return;
      }
      
      // On settings tab, update the study status
      setLoading(true);

      const finalStudyData = {
        ...studyData,
        status: 'published',
        updatedAt: new Date().toISOString(),
        lastModifiedBy: currentUser.uid
      };

      // Update the existing document
      const studyRef = doc(db, 'users', currentUser.uid, 'studies', docId);
      await updateDoc(studyRef, finalStudyData);
      
      // Navigate to the study viewer
      navigate(`/study/${docId}`);
      
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '-10px',
    },
    card: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      marginBottom: '1rem',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      width: '100%',
    },
    iconBar: {
      width: '100%',
      height: '48px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      padding: '0',
      lineHeight: '1',
    },
    feedIcon: {
      color: 'white',
      fontSize: '1.5rem',
      filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      marginTop: '1px',
    },
    header: {
      padding: '1.5rem',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: '600',
      color: '#2D3748',
      marginBottom: '0.5rem',
      fontFamily: "'Cardo', serif",
    },
    content: {
      padding: '1.5rem',
      background: 'rgba(255, 255, 255, 0.2)',
    },
    formGroup: {
      marginBottom: '1.5rem',
    },
    label: {
      display: 'block',
      marginBottom: '0.5rem',
      fontWeight: '500',
      color: '#2D3748',
    },
    input: {
      width: '100%',
      padding: '0.75rem',
      borderRadius: '12px',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      background: 'rgba(255, 255, 255, 0.9)',
      fontSize: '1rem',
      color: '#2D3748',
      transition: 'all 0.2s ease',
      '&:focus': {
        outline: 'none',
        borderColor: 'var(--primary-color)',
        boxShadow: '0 0 0 3px rgba(255, 0, 0, 0.1)',
      },
      wordWrap: 'break-word',
    },
    select: {
      appearance: 'none',
      width: '100%',
      padding: '0.75rem 2.5rem 0.75rem 1rem',
      borderRadius: '12px',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      background: `
        rgba(255, 255, 255, 0.9)
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23666666' d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3E%3C/svg%3E")
        no-repeat right 0.75rem center/1.5rem
      `,
      fontSize: '1rem',
      color: '#2D3748',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontFamily: "'Cardo', serif",
      '&:hover': {
        borderColor: 'var(--primary-color)',
        background: `
          rgba(255, 255, 255, 1)
          url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23FF0000' d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3E%3C/svg%3E")
          no-repeat right 0.75rem center/1.5rem
        `,
      },
      '&:focus': {
        outline: 'none',
        borderColor: 'var(--primary-color)',
        boxShadow: '0 0 0 3px rgba(255, 0, 0, 0.1)',
        background: `
          rgba(255, 255, 255, 1)
          url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23FF0000' d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3E%3C/svg%3E")
          no-repeat right 0.75rem center/1.5rem
        `,
      },
    },
    selectGroup: {
      marginBottom: '1.5rem',
    },
    selectLabel: {
      display: 'block',
      marginBottom: '0.5rem',
      fontWeight: '500',
      color: '#2D3748',
      fontSize: '0.95rem',
    },
    selectWrapper: {
      position: 'relative',
      width: '100%',
    },
    selectIcon: {
      position: 'absolute',
      right: '1rem',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#666666',
      pointerEvents: 'none',
      transition: 'color 0.2s ease',
    },
    textarea: {
      width: '100%',
      padding: '0.75rem',
      borderRadius: '12px',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      background: 'rgba(255, 255, 255, 0.9)',
      fontSize: '1rem',
      color: '#2D3748',
      minHeight: '120px',
      resize: 'vertical',
      transition: 'all 0.2s ease',
      '&:focus': {
        outline: 'none',
        borderColor: 'var(--primary-color)',
        boxShadow: '0 0 0 3px rgba(255, 0, 0, 0.1)',
      },
    },
    buttonGroup: {
      display: 'flex',
      gap: '1rem',
      marginTop: '1.5rem',
    },
    button: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      padding: '0.75rem',
      border: 'none',
      borderRadius: '12px',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.95rem',
      fontWeight: '500',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      whiteSpace: 'normal',
    },
    buttonPrimary: {
      background: 'rgba(255, 0, 0, 0.8)',
      color: 'white',
      boxShadow: '0 2px 4px rgba(255, 0, 0, 0.2)',
      '&:hover': {
        background: 'rgba(255, 0, 0, 0.9)',
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
      },
    },
    buttonSecondary: {
      background: 'rgba(241, 245, 249, 0.8)',
      color: '#666666',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      '&:hover': {
        background: 'rgba(226, 232, 240, 0.9)',
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      },
    },
    errorMessage: {
      background: 'rgba(220, 53, 69, 0.1)',
      color: '#dc3545',
      padding: '1rem',
      borderRadius: '12px',
      marginBottom: '1.5rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      backdropFilter: 'blur(4px)',
    },
    fileInputContainer: {
      position: 'relative',
      width: '100%',
    },
    fileInput: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      cursor: 'pointer',
      zIndex: 2,
    },
    fileInputButton: {
      width: '100%',
      padding: '0.75rem',
      borderRadius: '12px',
      border: '2px dashed rgba(226, 232, 240, 0.8)',
      background: 'rgba(255, 255, 255, 0.9)',
      color: '#666666',
      fontSize: '0.95rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      transition: 'all 0.2s ease',
      '&:hover': {
        borderColor: 'var(--primary-color)',
        color: 'var(--primary-color)',
        background: 'rgba(255, 0, 0, 0.05)',
      },
    },
    fileInputIcon: {
      fontSize: '1.2rem',
    },
    selectedFileName: {
      marginTop: '0.5rem',
      fontSize: '0.9rem',
      color: '#666666',
      textAlign: 'center',
    },
    savingIndicator: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontSize: '0.9rem',
      color: '#666666',
      marginTop: '0.5rem',
    },
    savedIndicator: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontSize: '0.9rem',
      color: '#28a745',
      marginTop: '0.5rem',
    },
  };

  const handleSessionUpdate = (sessionIndex, field, value) => {
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, index) => 
        index === sessionIndex 
          ? { ...session, [field]: value }
          : session
      )
    }));
  };

  const removeSession = (sessionIndex) => {
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.filter((_, index) => index !== sessionIndex)
    }));
  };

  const addSession = () => {
    setStudyData(prev => ({
      ...prev,
      sessions: [
        ...prev.sessions,
        {
          title: `Session ${prev.sessions.length + 1}`,
          description: '',
          scripture: '',
          discussionQuestions: [],
          activities: [],
          resources: [],
          media: []
        }
      ]
    }));
  };

  const handleQuestionUpdate = (sessionIndex, questionIndex, value) => {
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) => 
        sIndex === sessionIndex 
          ? {
              ...session,
              discussionQuestions: session.discussionQuestions.map((q, qIndex) =>
                qIndex === questionIndex ? value : q
              )
            }
          : session
      )
    }));
  };

  const removeQuestion = (sessionIndex, questionIndex) => {
    if (studyData.sessions[sessionIndex].discussionQuestions.length <= 1) return;
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) =>
        sIndex === sessionIndex
          ? {
              ...session,
              discussionQuestions: session.discussionQuestions.filter((_, qIndex) => qIndex !== questionIndex)
            }
          : session
      )
    }));
  };

  const addQuestion = (sessionIndex) => {
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) =>
        sIndex === sessionIndex
          ? {
              ...session,
              discussionQuestions: [...(session.discussionQuestions || []), '']
            }
          : session
      )
    }));
  };

  const handleActivityUpdate = (sessionIndex, activityIndex, value) => {
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) => 
        sIndex === sessionIndex 
          ? {
              ...session,
              activities: session.activities.map((a, aIndex) =>
                aIndex === activityIndex ? value : a
              )
            }
          : session
      )
    }));
  };

  const removeActivity = (sessionIndex, activityIndex) => {
    if (studyData.sessions[sessionIndex].activities.length <= 1) return;
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) =>
        sIndex === sessionIndex
          ? {
              ...session,
              activities: session.activities.filter((_, aIndex) => aIndex !== activityIndex)
            }
          : session
      )
    }));
  };

  const addActivity = (sessionIndex) => {
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) =>
        sIndex === sessionIndex
          ? {
              ...session,
              activities: [...(session.activities || []), '']
            }
          : session
      )
    }));
  };

  const handleMediaUpdate = (sessionIndex, mediaIndex, field, value) => {
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) => 
        sIndex === sessionIndex 
          ? {
              ...session,
              media: session.media.map((media, mIndex) =>
                mIndex === mediaIndex 
                  ? { ...media, [field]: value }
                  : media
              )
            }
          : session
      )
    }));
  };

  const removeMedia = (sessionIndex, mediaIndex) => {
    if (studyData.sessions[sessionIndex].media.length <= 1) return;
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) =>
        sIndex === sessionIndex
          ? {
              ...session,
              media: session.media.filter((_, mIndex) => mIndex !== mediaIndex)
            }
          : session
      )
    }));
  };

  const addMedia = (sessionIndex) => {
    setStudyData(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) =>
        sIndex === sessionIndex
          ? {
              ...session,
              media: [...(session.media || []), {
                type: 'video',
                url: '',
                title: '',
                description: ''
              }]
            }
          : session
      )
    }));
  };

  // Add this helper function
  const getEmbedUrl = (url, type) => {
    try {
      if (type === 'youtube') {
        const videoId = url.includes('youtu.be/') 
          ? url.split('youtu.be/')[1].split('?')[0]
          : url.split('v=')[1].split('&')[0];
        return `https://www.youtube.com/embed/${videoId}`;
      } else if (type === 'vimeo') {
        const videoId = url.split('vimeo.com/')[1].split('?')[0];
        return `https://player.vimeo.com/video/${videoId}`;
      }
    } catch (err) {
      console.error('Error getting embed URL:', err);
    }
    return '';
  };

  // Add file upload handler
  const handleFileUpload = async (sessionIndex, mediaIndex, file) => {
    if (!file) return;

    try {
      // Here you would typically:
      // 1. Upload the file to your storage (e.g., Firebase Storage)
      // 2. Get the download URL
      // 3. Update the media item with the URL
      
      // For now, we'll just create a local URL
      const localUrl = URL.createObjectURL(file);
      handleMediaUpdate(sessionIndex, mediaIndex, 'url', localUrl);
      handleMediaUpdate(sessionIndex, mediaIndex, 'title', file.name);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // Add a loading state for generating questions and activities
  const [generating, setGenerating] = useState({ questions: false, activities: false });

  // Update the generateMoreQuestions function
  const generateMoreQuestions = async (sessionIndex) => {
    setGenerating(prev => ({ ...prev, questions: true }));
    try {
      const session = studyData.sessions[sessionIndex];
      const response = await fetch(`${API_URL}/generate-discussion-questions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          scripture: session.scripture || studyData.scripture,
          title: studyData.title,
          description: studyData.description,
          objectives: studyData.objectives,
          existingQuestions: session.discussionQuestions || []
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate questions');
      }

      const { questions } = await response.json();
      
      setStudyData(prev => ({
        ...prev,
        sessions: prev.sessions.map((s, idx) => 
          idx === sessionIndex 
            ? {
                ...s,
                discussionQuestions: [...(s.discussionQuestions || []), ...questions]
              }
            : s
        )
      }));
    } catch (error) {
      console.error('Error generating questions:', error);
      setError('Failed to generate questions');
    } finally {
      setGenerating(prev => ({ ...prev, questions: false }));
    }
  };

  // Update the generateMoreActivities function
  const generateMoreActivities = async (sessionIndex) => {
    setGenerating(prev => ({ ...prev, activities: true }));
    try {
      const session = studyData.sessions[sessionIndex];
      const response = await fetch(`${API_URL}/generate-activities`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          scripture: session.scripture || studyData.scripture,
          title: studyData.title,
          description: studyData.description,
          objectives: studyData.objectives,
          existingActivities: session.activities || []
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate activities');
      }

      const { activities } = await response.json();
      
      setStudyData(prev => ({
        ...prev,
        sessions: prev.sessions.map((s, idx) => 
          idx === sessionIndex 
            ? {
                ...s,
                activities: [...(s.activities || []), ...activities]
              }
            : s
        )
      }));
    } catch (error) {
      console.error('Error generating activities:', error);
      setError('Failed to generate activities');
    } finally {
      setGenerating(prev => ({ ...prev, activities: false }));
    }
  };

  // Debounced auto-save function
  const debouncedSave = useCallback(
    debounce(async (data) => {
      if (!currentUser || !data.title) return;

      try {
        setSaving(true);
        const savedId = await saveBibleStudyDraft(currentUser.uid, {
          ...data,
          id: docId // Use docId instead of studyId
        });
        
        // Update the URL if this is a new study
        if (!docId) {
          window.history.replaceState(null, '', `/create/biblestudy/${savedId}`);
          setDocId(savedId); // Update docId with the new ID
        }
        
        setLastSaved(new Date());
      } catch (error) {
        console.error('Error auto-saving:', error);
        setError('Failed to save changes');
      } finally {
        setSaving(false);
      }
    }, 1000),
    [currentUser, docId] // Update dependency array to use docId
  );

  // Auto-save on any change to studyData
  useEffect(() => {
    if (studyData.title) {
      debouncedSave(studyData);
    }
    
    return () => {
      debouncedSave.cancel();
    };
  }, [studyData, debouncedSave]);

  const { studyId } = useParams(); // For editing existing studies
  const [saving, setSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState(null);

  // Load existing study if editing
  useEffect(() => {
    const loadStudy = async () => {
      if (!currentUser || !studyId) return;
      
      try {
        setLoading(true);
        const studyData = await getBibleStudyById(currentUser.uid, studyId);
        
        // Set all the study data
        setStudyData({
          title: studyData.title || '',
          description: studyData.description || '',
          startDate: studyData.startDate || today,
          endDate: studyData.endDate || '',
          scripture: studyData.scripture || '',
          frequency: studyData.frequency || 'weekly',
          ministryTags: studyData.ministryTags || '',
          materials: studyData.materials || '',
          objectives: studyData.objectives || '',
          timezone: studyData.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
          sessions: studyData.sessions || [],
          studyFormat: studyData.studyFormat || 'in-person',
          difficultyLevel: studyData.difficultyLevel || 'beginner',
          targetAgeGroup: studyData.targetAgeGroup || 'adults',
          languageOptions: studyData.languageOptions || ['English'],
          prerequisites: studyData.prerequisites || '',
          expectedOutcomes: studyData.expectedOutcomes || []
        });
        
        // Set the docId for autosaving
        setDocId(studyId);
        
      } catch (error) {
        console.error('Error loading study:', error);
        setError('Failed to load study');
      } finally {
        setLoading(false);
      }
    };

    loadStudy();
  }, [currentUser, studyId, today]);

  return (
    <>
      <div style={styles.card}>
        <div style={styles.iconBar}>
          <i className="mdi mdi-book-open-variant" style={styles.feedIcon}></i>
        </div>

        <div style={styles.header}>
          <h2 style={styles.title}>
            {studyId ? 'Edit Bible Study' : 'Create Bible Study'}
          </h2>
          {saving && (
            <div style={styles.savingIndicator}>
              <i className="mdi mdi-loading mdi-spin"></i>
              Saving...
            </div>
          )}
          {lastSaved && !saving && (
            <div style={styles.savedIndicator}>
              <i className="mdi mdi-check"></i>
              Saved {new Date(lastSaved).toLocaleTimeString()}
            </div>
          )}
        </div>

        <div style={styles.content}>
          {error && (
            <div style={styles.errorMessage}>
              <i className="mdi mdi-alert"></i>
              {error}
            </div>
          )}

          <div style={tabStyles.navigationTabs}>
            <div style={tabStyles.tabsContainer}>
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  style={tabStyles.tab}
                  onClick={() => setActiveTab(tab.id)}
                >
                  <i 
                    className={`mdi ${tab.icon}`} 
                    style={{
                      ...tabStyles.tabIcon,
                      ...(activeTab === tab.id && tabStyles.tabIconActive)
                    }}
                  ></i>
                  <span style={{
                    ...tabStyles.tabLabel,
                    ...(activeTab === tab.id && tabStyles.tabLabelActive)
                  }}>
                    {tab.title}
                  </span>
                </button>
              ))}
            </div>
          </div>

          {activeTab === 'details' && (
            <div style={styles.formSection}>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="title">Study Title</label>
                <input
                  style={styles.input}
                  type="text"
                  id="title"
                  name="title"
                  value={studyData.title}
                  onChange={handleInputChange}
                  placeholder="Enter study title"
                  required
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="description">Description</label>
                <textarea
                  style={styles.textarea}
                  id="description"
                  name="description"
                  value={studyData.description}
                  onChange={handleInputChange}
                  placeholder="Enter study description"
                  rows="4"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="scripture">Scripture Reference</label>
                <input
                  style={styles.input}
                  type="text"
                  id="scripture"
                  name="scripture"
                  value={studyData.scripture}
                  onChange={handleInputChange}
                  placeholder="e.g., John 3:16, Romans 8:1-11"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="objectives">Study Objectives</label>
                <textarea
                  style={styles.textarea}
                  id="objectives"
                  name="objectives"
                  value={studyData.objectives}
                  onChange={handleInputChange}
                  placeholder="What are the main learning objectives of this study?"
                  rows="4"
                />
              </div>
            </div>
          )}

          {activeTab === 'sessions' && (
            <div style={styles.formSection}>
              {studyData.sessions.map((session, index) => (
                <div key={index} style={{
                  background: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '12px',
                  padding: '1.5rem',
                  marginBottom: '1rem',
                  border: '1px solid rgba(255, 255, 255, 0.3)',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'
                }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1rem'
                  }}>
                    <input
                      style={{
                        ...styles.input,
                        flex: 1,
                        marginRight: '1rem'
                      }}
                      type="text"
                      value={session.title}
                      onChange={(e) => handleSessionUpdate(index, 'title', e.target.value)}
                      placeholder="Session Title"
                    />
                    <button
                      style={{
                        background: 'rgba(220, 53, 69, 0.1)',
                        color: '#dc3545',
                        border: 'none',
                        borderRadius: '8px',
                        padding: '0.5rem',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      onClick={() => removeSession(index)}
                    >
                      <i className="mdi mdi-close"></i>
                    </button>
                  </div>

                  <div style={styles.formGroup}>
                    <label style={styles.label}>Scripture Reference</label>
                    <input
                      style={styles.input}
                      type="text"
                      value={session.scripture}
                      onChange={(e) => handleSessionUpdate(index, 'scripture', e.target.value)}
                      placeholder="e.g., John 3:16-21"
                    />
                  </div>

                  <div style={styles.formGroup}>
                    <label style={styles.label}>Discussion Questions</label>
                    {session.discussionQuestions?.map((question, qIndex) => (
                      <div key={qIndex} style={{ 
                        marginBottom: '0.5rem',
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center'
                      }}>
                        <input
                          style={{...styles.input, flex: 1}}
                          type="text"
                          value={question}
                          onChange={(e) => handleQuestionUpdate(index, qIndex, e.target.value)}
                          placeholder="Enter a discussion question"
                        />
                        <button
                          style={{
                            background: 'rgba(220, 53, 69, 0.1)',
                            color: '#dc3545',
                            border: 'none',
                            borderRadius: '8px',
                            padding: '0.5rem',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => removeQuestion(index, qIndex)}
                        >
                          <i className="mdi mdi-delete"></i>
                        </button>
                      </div>
                    ))}
                    <div style={{
                      display: 'flex',
                      gap: '0.5rem',
                      marginTop: '0.5rem'
                    }}>
                      <button
                        style={{...styles.button, ...styles.buttonSecondary, flex: 1}}
                        onClick={() => addQuestion(index)}
                        type="button"
                      >
                        <i className="mdi mdi-plus"></i>
                        Add Question
                      </button>
                      <button
                        style={{...styles.button, ...styles.buttonSecondary, flex: 1}}
                        onClick={() => generateMoreQuestions(index)}
                        type="button"
                        disabled={generating.questions}
                      >
                        {generating.questions ? (
                          <>
                            <i className="mdi mdi-loading mdi-spin"></i>
                            Generating...
                          </>
                        ) : (
                          <>
                            <i className="mdi mdi-auto-fix"></i>
                            Generate More
                          </>
                        )}
                      </button>
                    </div>
                  </div>

                  <div style={styles.formGroup}>
                    <label style={styles.label}>Activities</label>
                    {session.activities?.map((activity, aIndex) => (
                      <div key={aIndex} style={{ 
                        marginBottom: '0.5rem',
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center'
                      }}>
                        <input
                          style={{...styles.input, flex: 1}}
                          type="text"
                          value={activity}
                          onChange={(e) => handleActivityUpdate(index, aIndex, e.target.value)}
                          placeholder="Enter an activity"
                        />
                        <button
                          style={{
                            background: 'rgba(220, 53, 69, 0.1)',
                            color: '#dc3545',
                            border: 'none',
                            borderRadius: '8px',
                            padding: '0.5rem',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => removeActivity(index, aIndex)}
                        >
                          <i className="mdi mdi-delete"></i>
                        </button>
                      </div>
                    ))}
                    <div style={{
                      display: 'flex',
                      gap: '0.5rem',
                      marginTop: '0.5rem'
                    }}>
                      <button
                        style={{...styles.button, ...styles.buttonSecondary, flex: 1}}
                        onClick={() => addActivity(index)}
                        type="button"
                      >
                        <i className="mdi mdi-plus"></i>
                        Add Activity
                      </button>
                      <button
                        style={{...styles.button, ...styles.buttonSecondary, flex: 1}}
                        onClick={() => generateMoreActivities(index)}
                        type="button"
                        disabled={generating.activities}
                      >
                        {generating.activities ? (
                          <>
                            <i className="mdi mdi-loading mdi-spin"></i>
                            Generating...
                          </>
                        ) : (
                          <>
                            <i className="mdi mdi-auto-fix"></i>
                            Generate More
                          </>
                        )}
                      </button>
                    </div>
                  </div>

                  <div style={styles.formGroup}>
                    <label style={styles.label}>Session Media</label>
                    {session.media?.map((mediaItem, mIndex) => (
                      <div key={mIndex} style={{
                        background: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: '12px',
                        padding: '1.5rem',
                        marginBottom: '1rem',
                        border: '1px solid rgba(226, 232, 240, 0.8)',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '1rem'
                        }}>
                          <div style={styles.selectGroup}>
                            <label style={styles.selectLabel} htmlFor={`mediaType-${index}-${mIndex}`}>Media Type</label>
                            <div style={styles.selectWrapper}>
                              <select
                                style={styles.select}
                                id={`mediaType-${index}-${mIndex}`}
                                value={mediaItem.type}
                                onChange={(e) => handleMediaUpdate(index, mIndex, 'type', e.target.value)}
                              >
                                <option value="youtube">YouTube Video</option>
                                <option value="vimeo">Vimeo Video</option>
                                <option value="audio">Audio File</option>
                                <option value="document">Document</option>
                                <option value="image">Image</option>
                              </select>
                            </div>
                          </div>
                          <button
                            style={{
                              background: 'rgba(220, 53, 69, 0.1)',
                              color: '#dc3545',
                              border: 'none',
                              borderRadius: '8px',
                              padding: '0.5rem',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              minWidth: '40px'
                            }}
                            onClick={() => removeMedia(index, mIndex)}
                            disabled={session.media.length === 1}
                          >
                            <i className="mdi mdi-close"></i>
                          </button>
                        </div>

                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '1rem'
                        }}>
                          {/* URL/File Input */}
                          <div style={styles.fileInputContainer}>
                            <input
                              type="file"
                              accept={
                                mediaItem.type === 'audio' ? 'audio/*' :
                                mediaItem.type === 'image' ? 'image/*' :
                                mediaItem.type === 'pdf' ? 'application/pdf' : '*/*'
                              }
                              onChange={(e) => handleFileUpload(index, mIndex, e.target.files[0])}
                              style={styles.fileInput}
                            />
                            <div style={styles.fileInputButton}>
                              <i className="mdi mdi-upload" style={styles.fileInputIcon}></i>
                              <span>Choose {mediaItem.type} file</span>
                            </div>
                            {mediaItem.title && (
                              <div style={styles.selectedFileName}>
                                Selected: {mediaItem.title}
                              </div>
                            )}
                          </div>

                          {/* Title Input */}
                          <input
                            style={styles.input}
                            type="text"
                            value={mediaItem.title}
                            onChange={(e) => handleMediaUpdate(index, mIndex, 'title', e.target.value)}
                            placeholder="Media Title"
                          />

                          {/* Description Input */}
                          <textarea
                            style={styles.textarea}
                            value={mediaItem.description}
                            onChange={(e) => handleMediaUpdate(index, mIndex, 'description', e.target.value)}
                            placeholder="Media Description (optional)"
                            rows="2"
                          />

                          {/* Preview Area */}
                          {mediaItem.url && (mediaItem.type === 'youtube' || mediaItem.type === 'vimeo') && (
                            <div style={{
                              position: 'relative',
                              width: '100%',
                              paddingBottom: '56.25%', // 16:9 aspect ratio
                              marginTop: '0.5rem'
                            }}>
                              <iframe
                                src={getEmbedUrl(mediaItem.url, mediaItem.type)}
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: '8px',
                                  border: 'none'
                                }}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          )}
                          
                          {mediaItem.url && mediaItem.type === 'image' && (
                            <img
                              src={mediaItem.url}
                              alt={mediaItem.title}
                              style={{
                                width: '100%',
                                maxHeight: '200px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                                marginTop: '0.5rem'
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                    
                    <button
                      style={{...styles.button, ...styles.buttonSecondary, width: '100%'}}
                      onClick={() => addMedia(index)}
                      type="button"
                    >
                      <i className="mdi mdi-plus"></i>
                      Add Media
                    </button>
                  </div>
                </div>
              ))}
              
              {/* Add Session Button */}
              {studyData.sessions.length === 0 ? (
                <div style={{
                  textAlign: 'center',
                  padding: '2rem',
                  background: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '12px',
                  border: '1px solid rgba(255, 255, 255, 0.3)',
                }}>
                  <p style={{ marginBottom: '1rem', color: '#666666' }}>No sessions added yet</p>
                  <button
                    style={{...styles.button, ...styles.buttonSecondary}}
                    onClick={addSession}
                    type="button"
                  >
                    <i className="mdi mdi-plus"></i>
                    Add First Session
                  </button>
                </div>
              ) : (
                <button
                  style={{...styles.button, ...styles.buttonSecondary, width: '100%'}}
                  onClick={addSession}
                  type="button"
                >
                  <i className="mdi mdi-plus"></i>
                  Add Another Session
                </button>
              )}
            </div>
          )}

          {activeTab === 'settings' && (
            <div style={styles.formSection}>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="startDate">Start Date</label>
                <input
                  style={styles.input}
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={studyData.startDate}
                  onChange={handleInputChange}
                  min={today}
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="endDate">End Date</label>
                <input
                  style={styles.input}
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={studyData.endDate}
                  onChange={handleInputChange}
                  min={studyData.startDate}
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="frequency">Frequency</label>
                <select
                  style={styles.select}
                  id="frequency"
                  name="frequency"
                  value={studyData.frequency}
                  onChange={handleInputChange}
                >
                  <option value="weekly">Weekly</option>
                  <option value="biweekly">Bi-weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="daily">Daily</option>
                </select>
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="ministryTags">Ministry Tags</label>
                <input
                  style={styles.input}
                  type="text"
                  id="ministryTags"
                  name="ministryTags"
                  value={studyData.ministryTags}
                  onChange={handleInputChange}
                  placeholder="#youth #adults #womens #mens"
                />
              </div>
            </div>
          )}

          <div style={styles.buttonGroup}>
            <button 
              type="button" 
              style={{...styles.button, ...styles.buttonSecondary}}
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              style={{...styles.button, ...styles.buttonPrimary}}
              disabled={loading}
              onClick={handleNext}
            >
              {loading ? (
                <>
                  <i className="mdi mdi-loading mdi-spin"></i>
                  {studyId ? 'Saving...' : 'Creating...'}
                </>
              ) : (
                <>
                  <i className={activeTab === 'settings' ? "mdi mdi-check" : "mdi mdi-arrow-right"}></i>
                  {activeTab === 'settings' ? (studyId ? 'Save Study' : 'Create Study') : 'Next'}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyCreator;
