// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCcUvHqOE2Fb86T3zgDWToD7IRHhwfFDhg",
  authDomain: "sermonize-ai-app-dev.firebaseapp.com",
  projectId: "sermonize-ai-app-dev",
  storageBucket: "sermonize-ai-app-dev.appspot.com",
  messagingSenderId: "749574385861",
  appId: "1:749574385861:web:4e11eef688b560db1ac0ac",
  measurementId: "G-RM5Y7MNRXB",
};

