import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { API_URL } from '../../../config';
import ReactMarkdown from 'react-markdown';

const styles = {
  container: {
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '-10px',
  },
  card: {
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
    marginBottom: '1rem',
    border: '1px solid rgba(255, 255, 255, 0.7)',
    width: '100%',
  },
  iconBar: {
    width: '100%',
    height: '48px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '0',
    lineHeight: '1',
  },
  feedIcon: {
    color: 'white',
    fontSize: '1.5rem',
    filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: '1px',
  },
  header: {
    padding: '1.5rem',
    borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
    background: 'rgba(255, 255, 255, 0.3)',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: '#333333',
    marginBottom: '0.5rem',
    fontFamily: "'Cardo', serif",
  },
  content: {
    padding: '1.5rem',
    background: 'rgba(255, 255, 255, 0.2)',
  },
  progressSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    marginBottom: '2rem',
    padding: '0.5rem',
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '12px',
  },
  step: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.5rem',
  },
  stepIndicator: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.9)',
    color: '#666666',
    fontSize: '0.9rem',
    fontWeight: '600',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.2s ease',
  },
  stepIndicatorActive: {
    background: 'rgba(255, 0, 0, 0.8)',
    color: 'white',
    boxShadow: '0 2px 4px rgba(255, 0, 0, 0.2)',
  },
  stepIndicatorCompleted: {
    background: 'rgba(40, 167, 69, 0.8)',
    color: 'white',
    boxShadow: '0 2px 4px rgba(40, 167, 69, 0.2)',
  },
  stepName: {
    fontSize: '0.8rem',
    color: '#666666',
    fontWeight: '500',
    textAlign: 'center',
  },
  stepNameActive: {
    color: 'var(--primary-color)',
    fontWeight: '600',
  },
  stepNameCompleted: {
    color: '#28a745',
    fontWeight: '600',
  },
  buttonGroup: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1.5rem',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    padding: '0.75rem 1.25rem',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    fontSize: '0.95rem',
    fontWeight: '500',
    background: 'rgba(255, 255, 255, 0.9)',
    color: '#666666',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    minWidth: '140px',
    '&:hover:not(:disabled)': {
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
  buttonPrimary: {
    background: 'rgba(255, 0, 0, 0.8)',
    color: 'white',
    boxShadow: '0 2px 4px rgba(255, 0, 0, 0.2)',
    '&:hover:not(:disabled)': {
      background: 'rgba(255, 0, 0, 0.9)',
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
    },
  },
  buttonSecondary: {
    background: 'rgba(241, 245, 249, 0.8)',
    color: '#666666',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      background: 'rgba(226, 232, 240, 0.9)',
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    '&:disabled': {
      background: 'rgba(241, 245, 249, 0.5)',
      color: '#999999',
      cursor: 'not-allowed',
      transform: 'none',
    },
  },
  transcriptContent: {
    padding: '2rem',
    maxHeight: '400px',
    overflowY: 'auto',
    background: 'rgba(255, 255, 255, 0.2)',
    color: '#666666',
    fontSize: '1.1rem',
    lineHeight: '1.8',
    '& p': {
      marginBottom: '2.5rem',
      maxWidth: '65ch',
      margin: '0 auto',
    },
    '& p + p': {
      marginTop: '2.5rem',
    },
    '& p:last-child': {
      marginBottom: 0,
    },
  },
  stepResult: {
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '12px',
    padding: '1.5rem',
    marginTop: '1.5rem',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  resultContent: {
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '2rem',
    borderRadius: '12px',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    '& p': {
      marginBottom: '2.5rem',
      fontSize: '1.1rem',
      lineHeight: '1.8',
      maxWidth: '65ch',
      margin: '0 auto',
    },
    '& p + p': {
      marginTop: '2.5rem',
    },
    '& p:last-child': {
      marginBottom: 0,
    },
  },
  errorMessage: {
    background: 'rgba(220, 53, 69, 0.1)',
    color: '#dc3545',
    padding: '1rem',
    borderRadius: '12px',
    marginBottom: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    backdropFilter: 'blur(4px)',
  },
  processingStatus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.75rem',
    padding: '0.75rem 1.5rem',
    background: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '8px',
    color: '#666666',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    minWidth: '200px',
    fontSize: '0.95rem',
    fontWeight: '500',
  },
  processingIcon: {
    color: 'var(--primary-color)',
    animation: 'spin 1s linear infinite',
  },
  stepNavigation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem',
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '12px',
    marginBottom: '1.5rem',
    position: 'relative',
  },
  navigationButtons: {
    display: 'flex',
    gap: '0.5rem',
    background: 'rgba(255, 255, 255, 0.5)',
    padding: '0.5rem',
    borderRadius: '10px',
    backdropFilter: 'blur(4px)',
  },
  transcriptSection: {
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '12px',
    padding: '1.5rem',
    marginTop: '1.5rem',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  lessonPlanContent: {
    '& h1, h2, h3': {
      marginBottom: '1.5rem',
      color: '#333333',
      fontFamily: "'Cardo', serif",
    },
    '& ul, ol': {
      paddingLeft: '1.5rem',
      marginBottom: '2.5rem',
      color: '#666666',
    },
    '& p': {
      marginBottom: '2.5rem',
      lineHeight: '1.8',
      color: '#666666',
    },
    '& p + p': {
      marginTop: '2.5rem',
    },
    '& p:last-child': {
      marginBottom: 0,
    },
  },
  highlightsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  highlightItem: {
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '12px',
    padding: '1.5rem',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  highlightContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  blockquote: {
    borderLeft: '4px solid var(--primary-color)',
    paddingLeft: '1rem',
    margin: '1rem 0',
    fontStyle: 'italic',
    color: '#666666',
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '0 8px 8px 0',
  },
  highlightNote: {
    fontSize: '0.95rem',
    color: '#666666',
  },
  highlightVideo: {
    marginTop: '1rem',
    borderRadius: '8px',
    overflow: 'hidden',
    background: 'rgba(0, 0, 0, 0.05)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
  },
  timestampLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: '#666666',
    textDecoration: 'none',
    fontSize: '0.9rem',
    marginTop: '0.5rem',
    padding: '0.5rem',
    borderRadius: '8px',
    background: 'rgba(255, 255, 255, 0.5)',
    transition: 'all 0.2s ease',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.8)',
      color: 'var(--primary-color)',
    },
  },
  transcriptDropdown: {
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '12px',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    overflow: 'hidden',
    marginBottom: '1.5rem',
  },
  dropdownHeader: {
    padding: '1rem 1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    background: 'rgba(255, 255, 255, 0.5)',
    transition: 'background 0.2s ease',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.7)',
    },
  },
  dropdownTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: '#333333',
    fontSize: '1.1rem',
    fontWeight: '600',
    margin: 0,
  },
  dropdownIcon: {
    fontSize: '1.5rem',
    color: '#666666',
    transition: 'transform 0.2s ease',
  },
  dropdownIconOpen: {
    transform: 'rotate(180deg)',
  },
  dropdownContent: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'all 0.3s ease',
  },
  dropdownContentOpen: {
    maxHeight: '400px',
    overflow: 'auto',
    borderTop: '1px solid rgba(226, 232, 240, 0.8)',
  },
};

const SermonReview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sermonId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [processingStep, setProcessingStep] = useState(0);
  const [processedContent, setProcessedContent] = useState({
    refinedTranscript: '',
    summary: '',
    highlights: '',
    lessonPlan: ''
  });
  const [isTranscriptOpen, setIsTranscriptOpen] = useState(false);

  const content = location.state?.content;

  useEffect(() => {
    console.log('SermonReview - sermonId:', sermonId);
    console.log('SermonReview - content:', content);
  }, [sermonId, content]);

  const processSteps = [
    {
      name: 'Refining Transcript',
      buttonText: 'Refine Transcript',
      endpoint: '/refine-transcription',
      payload: { transcription: content?.transcript },
      resultKey: 'refinedTranscript',
      description: 'Cleaning and formatting the transcript...'
    },
    {
      name: 'Generating Summary',
      buttonText: 'Generate Summary',
      endpoint: '/summarize',
      payload: { text: content?.transcript },
      resultKey: 'summary',
      description: 'Creating a concise summary of the sermon...'
    },
    {
      name: 'Creating Lesson Plan',
      buttonText: 'Generate Lesson Plan',
      endpoint: '/generate-lesson-plan',
      payload: { transcription: content?.transcript },
      resultKey: 'lessonPlan',
      description: 'Developing a comprehensive lesson plan...'
    },
    {
      name: 'Extracting Highlights',
      buttonText: 'Extract Highlights',
      endpoint: '/get-highlights',
      payload: { transcription: content?.transcript },
      resultKey: 'highlights',
      description: 'Identifying key moments and quotes...'
    }
  ];

  const processNextStep = async () => {
    if (processingStep >= processSteps.length) return;
    
    const currentStep = processSteps[processingStep];
    setLoading(true);
    setError('');

    try {
      const response = await fetch(`${API_URL}${currentStep.endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentStep.payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to ${currentStep.name.toLowerCase()}`);
      }

      const data = await response.json();
      
      // Handle different response structures based on the step
      if (currentStep.resultKey === 'highlights') {
        setProcessedContent(prev => ({
          ...prev,
          [currentStep.resultKey]: JSON.stringify(data, null, 2)
        }));
      } else if (currentStep.resultKey === 'refinedTranscript') {
        // Handle refined transcript specifically
        const refinedText = data.refinedTranscription || data.status === 'Success' && data.refined_transcription;
        setProcessedContent(prev => ({
          ...prev,
          [currentStep.resultKey]: refinedText
        }));
      } else {
        // Handle other responses normally
        let resultContent = data[currentStep.resultKey] || 
                           data.status === 'Success' && data[Object.keys(data)[1]];
        setProcessedContent(prev => ({
          ...prev,
          [currentStep.resultKey]: resultContent
        }));
      }
      
      setLoading(false);
    } catch (err) {
      setError(`Error during ${currentStep.name}: ${err.message}`);
      setLoading(false);
    }
  };

  const handleStepChange = (direction) => {
    if (direction === 'back' && processingStep > 0) {
      setProcessingStep(prev => prev - 1);
    } else if (direction === 'forward' && processingStep < processSteps.length && processedContent[processSteps[processingStep].resultKey]) {
      setProcessingStep(prev => prev + 1);
    }
  };

  const handleFinishProcessing = () => {
    if (!sermonId) {
      console.error('No sermon ID available');
      setError('Missing sermon ID');
      return;
    }

    navigate('/create/sermon', { 
      state: {
        step: 3,
        processedContent: {
          ...content,
          ...processedContent,
          sermonId: sermonId,
          title: content?.title || '',
          description: content?.description || '',
          scripture: content?.scripture || '',
          date: content?.date || new Date().toISOString(),
          youtubeUrl: content?.youtubeUrl || '',
          transcript: content?.transcript || ''
        }
      }
    });
  };

  const renderHighlights = (highlightsJson) => {
    try {
      const highlightsData = JSON.parse(highlightsJson);
      const parsedHighlights = JSON.parse(highlightsData.highlights);
      const highlights = parsedHighlights.highlights;
      const videoId = location.state?.content?.youtubeUrl ? getYoutubeVideoId(location.state.content.youtubeUrl) : null;

      return (
        <div style={styles.highlightsList}>
          {highlights.map((highlight, index) => (
            <div key={index} style={styles.highlightItem}>
              <div style={styles.highlightContent}>
                <blockquote style={styles.blockquote}>{highlight.quote}</blockquote>
                <p style={styles.highlightNote}>{highlight.note}</p>
                {videoId && (
                  <div style={styles.highlightVideo}>
                    <iframe
                      width="100%"
                      height="315"
                      src={`https://www.youtube.com/embed/${videoId}?start=${Math.floor(parseFloat(highlight.start_time))}`}
                      title={`Highlight ${index + 1}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <a 
                      href={`https://youtu.be/${videoId}?t=${Math.floor(parseFloat(highlight.start_time))}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={styles.timestampLink}
                    >
                      <i className="mdi mdi-youtube"></i>
                      Watch on YouTube at {formatTimestamp(highlight.start_time)}
                      {highlight.end_time && ` - ${formatTimestamp(highlight.end_time)}`}
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    } catch (err) {
      console.error('Error parsing highlights:', err);
      return <pre style={styles.resultContent}>{highlightsJson}</pre>;
    }
  };

  const getYoutubeVideoId = (url) => {
    try {
      if (url.includes('youtu.be/')) {
        return url.split('youtu.be/')[1].split('?')[0];
      } else if (url.includes('youtube.com/watch')) {
        return url.split('v=')[1].split('&')[0];
      }
    } catch (err) {
      console.error('Error extracting video ID:', err);
    }
    return null;
  };

  const formatTimestamp = (seconds) => {
    const time = parseFloat(seconds);
    const minutes = Math.floor(time / 60);
    const remainingSeconds = Math.floor(time % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const renderCurrentStepContent = () => {
    if (processingStep >= processSteps.length) return null;
    
    const currentStep = processSteps[processingStep];
    const content = processedContent[currentStep.resultKey];
    
    if (!content) return null;

    return (
      <div style={styles.stepResult}>
        <h4 style={styles.title}>
          {currentStep.name === 'Creating Lesson Plan' 
            ? 'Generated Lesson Plan'
            : `${currentStep.name} Result`}
        </h4>
        {currentStep.resultKey === 'highlights' ? (
          renderHighlights(content)
        ) : currentStep.resultKey === 'lessonPlan' ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : currentStep.resultKey === 'refinedTranscript' ? (
          <ReactMarkdown>{content}</ReactMarkdown>
        ) : (
          <ReactMarkdown>{content}</ReactMarkdown>
        )}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.iconBar}>
          <i className="mdi mdi-cross" style={styles.feedIcon}></i>
        </div>

        <div style={styles.header}>
          <h2 style={styles.title}>Process Sermon Content</h2>
          <div style={styles.progressSteps}>
            {processSteps.map((step, index) => (
              <div key={index} style={styles.step}>
                <div style={{
                  ...styles.stepIndicator,
                  ...(index === processingStep && styles.stepIndicatorActive),
                  ...(index < processingStep && styles.stepIndicatorCompleted),
                }}>
                  {index < processingStep ? (
                    <i className="mdi mdi-check"></i>
                  ) : (
                    index + 1
                  )}
                </div>
                <span style={{
                  ...styles.stepName,
                  ...(index === processingStep && styles.stepNameActive),
                  ...(index < processingStep && styles.stepNameCompleted),
                }}>
                  {step.name}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div style={styles.content}>
          {error && <div style={styles.errorMessage}>{error}</div>}
          
          <div style={styles.stepNavigation}>
            <div style={styles.navigationButtons}>
              <button
                style={{
                  ...styles.button,
                  visibility: processingStep === 0 ? 'hidden' : 'visible',
                }}
                onClick={() => handleStepChange('back')}
                disabled={processingStep === 0}
              >
                <i className="mdi mdi-arrow-left"></i>
                Previous
              </button>

              {loading ? (
                <div style={styles.processingStatus}>
                  <i className="mdi mdi-loading mdi-spin" style={styles.processingIcon}></i>
                  <span>{processSteps[processingStep].description}</span>
                </div>
              ) : processingStep < processSteps.length ? (
                <button
                  style={{...styles.button, ...styles.buttonPrimary}}
                  onClick={processNextStep}
                  disabled={loading}
                >
                  <i className="mdi mdi-play"></i>
                  {processSteps[processingStep].buttonText}
                </button>
              ) : (
                <button
                  style={{...styles.button, ...styles.buttonPrimary}}
                  onClick={handleFinishProcessing}
                >
                  <i className="mdi mdi-check-circle"></i>
                  Review Final Content
                </button>
              )}

              <button
                style={{
                  ...styles.button,
                  visibility: (
                    processingStep === processSteps.length || 
                    !processedContent[processSteps[processingStep]?.resultKey]
                  ) ? 'hidden' : 'visible',
                }}
                onClick={() => handleStepChange('forward')}
                disabled={
                  processingStep === processSteps.length || 
                  !processedContent[processSteps[processingStep]?.resultKey]
                }
              >
                Next
                <i className="mdi mdi-arrow-right"></i>
              </button>
            </div>
          </div>

          <div style={styles.currentStepInfo}>
            {processingStep === 0 && (
              <div style={styles.transcriptDropdown}>
                <div 
                  style={styles.dropdownHeader}
                  onClick={() => setIsTranscriptOpen(!isTranscriptOpen)}
                >
                  <h3 style={styles.dropdownTitle}>
                    <i className="mdi mdi-text" style={{ color: 'var(--primary-color)' }}></i>
                    Original Transcript
                  </h3>
                  <i 
                    className="mdi mdi-chevron-down"
                    style={{
                      ...styles.dropdownIcon,
                      ...(isTranscriptOpen && styles.dropdownIconOpen)
                    }}
                  ></i>
                </div>
                <div 
                  style={{
                    ...styles.dropdownContent,
                    ...(isTranscriptOpen && styles.dropdownContentOpen)
                  }}
                >
                  <div style={styles.transcriptContent}>
                    <ReactMarkdown>{content?.transcript || ''}</ReactMarkdown>
                  </div>
                </div>
              </div>
            )}
            {renderCurrentStepContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SermonReview; 