import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';

const Dashboard = () => {
  const navigate = useNavigate();
  
  const [isMinistryOpen, setIsMinistryOpen] = useState(false);
  const [isActionsOpen, setIsActionsOpen] = useState(false);
  const [isStatsOpen, setIsStatsOpen] = useState(false);
  const [isPublicMinistryOpen, setIsPublicMinistryOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  const [ministryProfile, setMinistryProfile] = useState({
    name: 'Living Word Church',
    role: 'Associate Pastor',
    denomination: 'Non-denominational',
    location: '567 Worship Way, Austin, TX',
    serviceTime: 'Sun 8AM, 10AM & 12PM',
    description: 'A welcoming community dedicated to sharing God\'s word through contemporary worship and meaningful fellowship.',
    image: "https://picsum.photos/400/200?random=1",
    color: '#FF416C'
  });

  const tabs = [
    {
      id: 'overview',
      title: 'Overview',
      icon: 'mdi-view-dashboard'
    },
    {
      id: 'discover',
      title: 'Discover',
      icon: 'mdi-compass'
    },
    {
      id: 'connected',
      title: 'Connected',
      icon: 'mdi-link-variant'
    },
    {
      id: 'ministry',
      title: 'My Ministry',
      icon: 'mdi-church'
    }
  ];

  const tabStyles = {
    navigationTabs: {
      width: '100%',
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '12px',
      padding: '0.5rem',
      marginBottom: '1rem',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.07)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
    },
    tabsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '0.5rem',
      width: '100%',
      padding: '0',
    },
    tab: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.25rem',
      padding: '0.5rem',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      background: 'transparent',
      position: 'relative',
      fontFamily: "'Cardo', serif",
      minWidth: 'auto',
    },
    tabIcon: {
      fontSize: '1.3rem',
      color: '#333333',
      transition: 'color 0.2s ease',
    },
    tabIconActive: {
      color: 'var(--primary-color)',
    },
    tabLabel: {
      fontSize: '0.75rem',
      fontWeight: '500',
      color: '#333333',
      fontFamily: "'Cardo', serif",
      whiteSpace: 'nowrap',
    },
    tabLabelActive: {
      color: 'var(--primary-color)',
      fontWeight: '600',
      fontFamily: "'Cardo', serif",
    }
  };

  const styles = {
    dashboardContainer: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      marginTop: '-10px',
    },
    dashboardContent: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    card: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      marginBottom: '1rem',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'fit-content',
    },
    content: {
      padding: '1.5rem',
      background: 'rgba(255, 255, 255, 0.2)',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    formSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    buttonGroup: {
      display: 'flex',
      gap: '1rem',
      marginTop: 'auto',
      padding: '1rem 0 0 0',
    },
    iconBar: {
      width: '100%',
      height: '48px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      padding: '0',
      lineHeight: '1',
    },
    feedIcon: {
      color: 'white',
      fontSize: '1.5rem',
      filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      marginTop: '1px',
    },
    notificationCard: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      marginBottom: '1rem',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    notificationIcon: {
      width: '40px',
      height: '40px',
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1.5rem',
      backdropFilter: 'blur(4px)',
    },
    notificationContent: {
      flex: 1,
    },
    notificationMessage: {
      margin: '0 0 0.25rem',
      color: '#333333',
      fontWeight: '500',
    },
    notificationTime: {
      color: '#666666',
      fontSize: '0.9rem',
    },
    notificationClose: {
      background: 'rgba(255, 255, 255, 0.9)',
      border: 'none',
      color: '#666666',
      cursor: 'pointer',
      padding: '0.5rem',
      borderRadius: '8px',
      transition: 'all 0.2s ease',
      backdropFilter: 'blur(4px)',
      '&:hover': {
        background: 'rgba(255, 0, 0, 0.1)',
        color: 'var(--error-color)',
      },
    },
    statsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: '1rem',
      marginBottom: '1.5rem',
    },
    statCard: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      transition: 'transform 0.2s ease',
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
      },
    },
    statContent: {
      padding: '1.25rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.75rem',
      flex: 1,
      justifyContent: 'space-between',
    },
    statHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.5rem',
      textAlign: 'center',
    },
    statValue: {
      fontSize: '2.5rem',
      fontWeight: '700',
      color: '#333333',
      fontFamily: "'Cardo', serif",
      lineHeight: '1',
      background: 'linear-gradient(135deg, #333333 0%, #666666 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    statTitle: {
      fontSize: '0.9rem',
      fontWeight: '500',
      color: '#666666',
      textAlign: 'center',
      lineHeight: '1.2',
      maxWidth: '120px',
    },
    statFooter: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      padding: '0.5rem 0.75rem',
      background: 'rgba(255, 255, 255, 0.5)',
      borderRadius: '8px',
      width: '100%',
      justifyContent: 'center',
    },
    statSubtitle: {
      fontSize: '0.8rem',
      color: '#666666',
      fontWeight: '500',
    },
    trendIndicator: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.25rem',
      fontSize: '0.8rem',
      fontWeight: '600',
      padding: '0.25rem 0.5rem',
      borderRadius: '6px',
      background: 'rgba(255, 255, 255, 0.8)',
      backdropFilter: 'blur(4px)',
    },
    trendPositive: {
      color: '#4CAF50',
    },
    trendNegative: {
      color: '#FF5252',
    },
    quickActionsGrid: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: '1rem',
      marginTop: '1rem',
    },
    quickActionButton: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
      },
    },
    actionIconBar: {
      width: '100%',
      height: '48px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    },
    actionIcon: {
      color: 'white',
      fontSize: '1.5rem',
      filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
    },
    actionContent: {
      padding: '1.25rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.5rem',
      background: 'rgba(255, 255, 255, 0.2)',
      width: '100%',
    },
    actionTitle: {
      fontSize: '1.1rem',
      fontWeight: '600',
      color: '#333333',
      textAlign: 'center',
      fontFamily: "'Cardo', serif",
    },
    actionDescription: {
      fontSize: '0.9rem',
      color: '#666666',
      textAlign: 'center',
      lineHeight: '1.4',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1.5rem',
    },
    sectionTitleGroup: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      '& h2': {
        fontSize: '1.5rem',
        fontWeight: '600',
        color: '#333333',
        fontFamily: "'Cardo', serif",
        margin: 0,
      },
      '& i': {
        fontSize: '1.5rem',
        color: 'var(--primary-color)',
      },
    },
    churchesSection: {
      marginTop: '1.5rem',
      width: '100%',
    },
    churchesGrid: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    churchCard: {
      background: '#FFFFFF',
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: 'var(--shadow-sm)',
      border: '1px solid #E2E8F0',
      width: '100%',
    },
    imageContainer: {
      width: '100%',
      height: '160px',
      overflow: 'hidden',
    },
    churchImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    churchInfo: {
      padding: '1rem',
    },
    churchName: {
      fontSize: '1.1rem',
      fontWeight: '600',
      color: '#333333',
      marginBottom: '0.5rem',
    },
    detailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    detail: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontSize: '0.9rem',
      color: '#666666',
    },
    detailIcon: {
      fontSize: '1.1rem',
      color: '#333333',
    },
    actionButtons: {
      display: 'flex',
      gap: '0.5rem',
      marginTop: '1rem',
      padding: '0.5rem',
      borderTop: '1px solid #E2E8F0',
      background: 'rgba(247, 250, 252, 0.8)',
      backdropFilter: 'blur(4px)',
    },
    button: {
      flex: 1,
      padding: '0.75rem',
      borderRadius: '12px',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      fontSize: '0.95rem',
      fontWeight: '500',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    primaryButton: {
      background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
      color: 'white',
      backdropFilter: 'blur(4px)',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
      },
    },
    secondaryButton: {
      background: 'rgba(241, 245, 249, 0.8)',
      color: '#666666',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      '&:hover': {
        background: 'rgba(226, 232, 240, 0.9)',
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      },
    },
    ministryDropdown: {
      background: 'transparent',
      backdropFilter: 'none',
      borderRadius: '16px',
      overflow: 'hidden',
      marginTop: '1.5rem',
      marginBottom: '2rem',
      border: 'none',
      boxShadow: 'none',
    },
    dropdownHeader: {
      padding: '1rem 1.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      background: 'rgba(255, 255, 255, 0.5)',
      transition: 'background 0.2s ease',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.7)',
      },
    },
    dropdownTitle: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      margin: 0,
    },
    dropdownIcon: {
      fontSize: '1.5rem',
      color: '#666666',
      transition: 'transform 0.2s ease',
    },
    dropdownIconOpen: {
      transform: 'rotate(180deg)',
    },
    dropdownContent: {
      maxHeight: '0',
      overflow: 'hidden',
      transition: 'all 0.3s ease',
    },
    dropdownContentOpen: {
      maxHeight: '70vh', // Set a maximum height
      overflowY: 'auto', // Make it scrollable
      paddingBottom: '1rem',
      // Add smooth scrolling
      scrollBehavior: 'smooth',
      // Add custom scrollbar styling
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.3)',
        },
      },
    },
    contentContainer: {
      padding: '0 1rem',
    },
    searchContainer: {
      padding: '1rem',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
    },
    searchInputWrapper: {
      position: 'relative',
      width: '100%',
    },
    searchIcon: {
      position: 'absolute',
      left: '1rem',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#666666',
      fontSize: '1.2rem',
    },
    searchInput: {
      width: '100%',
      padding: '0.75rem 1rem 0.75rem 2.5rem',
      borderRadius: '12px',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      background: 'rgba(255, 255, 255, 0.9)',
      fontSize: '1rem',
      color: '#2D3748',
      transition: 'all 0.2s ease',
      '&:focus': {
        outline: 'none',
        borderColor: 'var(--primary-color)',
        boxShadow: '0 0 0 3px rgba(255, 0, 0, 0.1)',
      },
    },
    ministryDescription: {
      fontSize: '0.9rem',
      color: '#666666',
      marginTop: '0.5rem',
      marginBottom: '1rem',
      lineHeight: '1.4',
    },
    styledInput: {
      width: '100%',
      padding: '0.75rem 1rem 0.75rem 2.5rem',
      borderRadius: '12px',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      background: 'rgba(255, 255, 255, 0.9)',
      fontSize: '0.95rem',
      color: '#2D3748',
      transition: 'all 0.2s ease',
      '&:focus': {
        outline: 'none',
        borderColor: 'var(--primary-color)',
        boxShadow: '0 0 0 3px rgba(255, 0, 0, 0.1)',
      },
    },
    inputWrapper: {
      position: 'relative',
      width: '100%',
    },
    inputIcon: {
      position: 'absolute',
      left: '1rem',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#666666',
      fontSize: '1.2rem',
    },
    imageUploadButton: {
      position: 'absolute',
      bottom: '1rem',
      right: '1rem',
      background: 'rgba(255, 255, 255, 0.9)',
      border: 'none',
      padding: '0.5rem 1rem',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      cursor: 'pointer',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.2s ease',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
      },
    },
    label: {
      display: 'block',
      marginBottom: '0.5rem',
      fontWeight: '500',
      color: '#2D3748',
      fontSize: '0.95rem',
    },
    detail: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      marginBottom: '1rem',
    },
    detailIcon: {
      fontSize: '1.2rem',
      color: '#666666',
      minWidth: '24px',
    },
    staffCard: {
      background: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '12px',
      padding: '1.5rem',
      marginBottom: '1rem',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    staffHeader: {
      display: 'flex',
      gap: '1rem',
      marginBottom: '1.5rem',
    },
    staffAvatar: {
      width: '64px',
      height: '64px',
      borderRadius: '12px',
      objectFit: 'cover',
      border: '2px solid rgba(255, 255, 255, 0.8)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    staffInfo: {
      flex: 1,
    },
    staffName: {
      fontSize: '1.1rem',
      fontWeight: '600',
      color: '#333333',
      marginBottom: '0.25rem',
      fontFamily: "'Cardo', serif",
    },
    staffRole: {
      fontSize: '0.9rem',
      color: '#666666',
      marginBottom: '0.25rem',
    },
    staffEmail: {
      fontSize: '0.9rem',
      color: '#666666',
    },
    permissionsSection: {
      background: 'rgba(255, 255, 255, 0.8)',
      borderRadius: '8px',
      padding: '1rem',
      marginBottom: '1rem',
    },
    permissionsTitle: {
      fontSize: '1rem',
      fontWeight: '600',
      color: '#333333',
      marginBottom: '1rem',
      fontFamily: "'Cardo', serif",
    },
    permissionsList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: '0.75rem',
    },
    permissionItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      padding: '0.5rem',
      background: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '8px',
      border: '1px solid rgba(226, 232, 240, 0.8)',
    },
    permissionLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontSize: '0.9rem',
      color: '#333333',
      cursor: 'pointer',
    },
    permissionCheckbox: {
      width: '18px',
      height: '18px',
      cursor: 'pointer',
    },
    staffActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '0.75rem',
      marginTop: '1rem',
    },
    buttonDanger: {
      background: 'rgba(220, 53, 69, 0.1)',
      color: '#dc3545',
      border: 'none',
      padding: '0.75rem 1rem',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.9rem',
      fontWeight: '500',
      '&:hover': {
        background: 'rgba(220, 53, 69, 0.2)',
        transform: 'translateY(-1px)',
      },
    },
    addButton: {
      width: '100%',
      padding: '1rem',
      background: 'rgba(255, 255, 255, 0.9)',
      border: '2px dashed rgba(226, 232, 240, 0.8)',
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.75rem',
      color: '#666666',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.95rem',
      fontWeight: '500',
      '&:hover': {
        borderColor: 'var(--primary-color)',
        color: 'var(--primary-color)',
        background: 'rgba(255, 0, 0, 0.05)',
      },
    },
    memberCard: {
      background: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '12px',
      padding: '1.5rem',
      marginBottom: '1rem',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    memberHeader: {
      display: 'flex',
      gap: '1rem',
      marginBottom: '1.5rem',
    },
    memberAvatar: {
      width: '64px',
      height: '64px',
      borderRadius: '12px',
      objectFit: 'cover',
      border: '2px solid rgba(255, 255, 255, 0.8)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    memberInfo: {
      flex: 1,
    },
    memberName: {
      fontSize: '1.1rem',
      fontWeight: '600',
      color: '#333333',
      marginBottom: '0.25rem',
      fontFamily: "'Cardo', serif",
    },
    memberEmail: {
      fontSize: '0.9rem',
      color: '#666666',
    },
    memberActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '0.75rem',
      marginTop: '1rem',
    },
    // Calendar styles
    calendarContainer: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      marginTop: '2rem',
      padding: '1.5rem',
    },
    calendarTop: {
      marginBottom: '1.5rem',
    },
    btnGroup: {
      display: 'flex',
      gap: '0.5rem',
      marginBottom: '1rem',
    },
    tag: {
      padding: '0.5rem 1rem',
      borderRadius: '8px',
      border: '1px solid rgba(255, 0, 0, 0.2)',
      background: 'rgba(255, 0, 0, 0.05)',
      color: 'var(--primary-color)',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.9rem',
    },
    monthSelector: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.5rem',
      background: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '12px',
      border: '1px solid rgba(226, 232, 240, 0.8)',
    },
    arrow: {
      background: 'transparent',
      border: 'none',
      color: '#666666',
      cursor: 'pointer',
      padding: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      transition: 'all 0.2s ease',
    },
    monthName: {
      fontSize: '1.1rem',
      fontWeight: '600',
      color: '#333333',
      fontFamily: "'Cardo', serif",
    },
    calendar: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: '0.5rem',
      textAlign: 'center',
    },
    day: {
      padding: '0.5rem',
      color: '#666666',
      fontSize: '0.9rem',
      fontWeight: '500',
    },
    date: {
      padding: '0.75rem',
      border: 'none',
      background: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '8px',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      color: '#333333',
    },
    currentDay: {
      background: '#82B1FF', // Update from var(--primary-color)
      color: 'white',
      fontWeight: '600',
    },
    faded: {
      color: '#999999',
      background: 'rgba(0, 0, 0, 0.02)',
    },
    eventIndicator: {
      width: '4px',
      height: '4px',
      borderRadius: '50%',
      background: '#82B1FF', // Update from var(--primary-color)
      margin: '2px auto 0',
    },
    calendarWithEvents: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '1.5rem',
      '@media (min-width: 768px)': {
        gridTemplateColumns: '1fr 300px',
      },
    },
    eventsContainer: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      padding: '1.5rem',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07)',
    },
    eventsList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    eventCard: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      padding: '1rem',
      background: 'rgba(255, 255, 255, 0.8)',
      borderRadius: '12px',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      transition: 'transform 0.2s ease',
      cursor: 'pointer',
    },
    eventIcon: {
      width: '48px',
      height: '48px',
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1.5rem',
      flexShrink: 0,
    },
    eventInfo: {
      flex: 1,
    },
    eventTitle: {
      margin: '0 0 0.25rem',
      fontSize: '1.1rem',
      fontWeight: '600',
      color: '#333333',
      fontFamily: "'Cardo', serif",
    },
    eventDetails: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '0.75rem',
      color: '#666666',
      fontSize: '0.9rem',
    },
    eventDetail: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.25rem',
    },
    noEvents: {
      textAlign: 'center',
      color: '#666666',
      padding: '2rem',
      fontSize: '0.95rem',
    },
    selectedDateHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '1rem',
      padding: '0.5rem',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
    },
    selectedDate: {
      fontSize: '1.2rem',
      fontWeight: '600',
      color: '#333333',
      fontFamily: "'Cardo', serif",
    },
    calendarHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', // Add this line
      gap: '0.75rem',
      marginBottom: '1rem',
      padding: '0.5rem',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)', // Optional: adds a subtle separator
      width: '100%', // Ensure full width
    },
    calendarTitle: {
      fontSize: '1.5rem',
      fontWeight: '600',
      color: '#333333',
      fontFamily: "'Cardo', serif",
      margin: 0,
    },
    calendarIcon: {
      color: 'var(--primary-color)',
      fontSize: '1.5rem',
    },
    ministryDropdownContainer: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      marginBottom: '2rem',
    },
    ministryHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1.5rem',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
      background: 'rgba(255, 255, 255, 0.9)',
    },
    ministryHeaderLeft: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
    },
    ministryIcon: {
      color: 'var(--primary-color)',
      fontSize: '1.5rem',
    },
    ministryTitle: {
      fontSize: '1.5rem',
      fontWeight: '600',
      color: '#333333',
      fontFamily: "'Cardo', serif",
      margin: 0,
    },
    ministryToggle: {
      background: 'transparent',
      border: 'none',
      color: '#666666',
      cursor: 'pointer',
      padding: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      transition: 'all 0.2s ease',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.05)',
        color: 'var(--primary-color)',
      },
    },
    ministryContent: {
      padding: '1.5rem',
      display: 'grid',
      gap: '1rem',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    },
    ministryCard: {
      background: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '12px',
      overflow: 'hidden',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      transition: 'all 0.2s ease',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
      },
    },
    ministryImageContainer: {
      position: 'relative',
      height: '160px',
      overflow: 'hidden',
    },
    ministryImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    ministryOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      background: 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%)',
      display: 'flex',
      alignItems: 'flex-end',
      padding: '1rem',
    },
    ministryName: {
      color: 'white',
      fontSize: '1.25rem',
      fontWeight: '600',
      fontFamily: "'Cardo', serif",
      margin: 0,
      textShadow: '0 2px 4px rgba(0,0,0,0.3)',
    },
    ministryDetails: {
      padding: '1rem',
    },
    ministryInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      marginBottom: '1rem',
    },
    ministryInfoItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      color: '#666666',
      fontSize: '0.9rem',
    },
    ministryActions: {
      display: 'flex',
      gap: '0.5rem',
      marginTop: '1rem',
      padding: '0.5rem',
      borderTop: '1px solid rgba(226, 232, 240, 0.8)',
    },
    ministryButton: {
      flex: 1,
      padding: '0.75rem',
      borderRadius: '8px',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      fontSize: '0.9rem',
      fontWeight: '500',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
    },
    ministryPrimaryButton: {
      background: 'var(--primary-color)',
      color: 'white',
      '&:hover': {
        background: 'var(--primary-hover)',
        transform: 'translateY(-1px)',
      },
    },
    ministrySecondaryButton: {
      background: 'rgba(226, 232, 240, 0.8)',
      color: '#666666',
      '&:hover': {
        background: 'rgba(226, 232, 240, 1)',
        transform: 'translateY(-1px)',
      },
    },
  };

  const [notifications] = useState([
    {
      id: 1,
      type: 'info',
      message: 'New sermon transcription complete',
      time: '2 hours ago',
      icon: 'mdi-text-to-speech',
      color: '#82B1FF'
    },
    {
      id: 2,
      type: 'success',
      message: 'Your latest sermon was published successfully',
      time: '5 hours ago',
      icon: 'mdi-check-circle',
      color: '#4CAF50'
    }
  ]);

  const stats = [
    {
      title: 'Congregation members',
      value: '245',
      subtitle: 'Active members',
      icon: 'mdi-account-group',
      color: '#FF416C',
      trend: '+12'
    },
    {
      title: 'Active study series',
      value: '12',
      subtitle: 'Currently running',
      icon: 'mdi-book-open-variant',
      color: '#FF4B2B',
      trend: '+2'
    },
    {
      title: 'Active participants',
      value: '150',
      subtitle: 'Across all series',
      icon: 'mdi-account-group',
      color: '#82B1FF',
      trend: '+12'
    },
    {
      title: 'Prayer Requests',
      value: '8',
      subtitle: 'New this week',
      icon: 'mdi-hands-pray',
      color: '#4CAF50',
      trend: '+3'
    }
  ];

  const communityActions = [
    {
      title: 'New Post',
      icon: 'mdi-post',
      color: '#FF416C',
      description: 'Share an update with your community',
      action: () => navigate('/community')
    },
    {
      title: 'New Group',
      icon: 'mdi-account-group-outline',
      color: '#FF4B2B',
      description: 'Create a new ministry group',
      action: () => navigate('/community/groups')
    },
    {
      title: 'New Event',
      icon: 'mdi-calendar-plus',
      color: '#82B1FF',
      description: 'Schedule a new community event',
      action: () => navigate('/community/events')
    },
    {
      title: 'New Prayer Request',
      icon: 'mdi-hands-pray',
      color: '#4CAF50',
      description: 'Share a prayer request',
      action: () => navigate('/community/prayer')
    }
  ];

  const [myMinistries, setMyMinistries] = useState([
    {
      id: 1,
      name: "Living Word Church",
      role: "Associate Pastor",
      denomination: "Non-denominational",
      location: "567 Worship Way, Austin, TX",
      serviceTime: "Sun 8AM, 10AM & 12PM",
      description: "A welcoming community dedicated to sharing God's word through contemporary worship and meaningful fellowship.",
      image: "https://picsum.photos/400/200?random=1",
      color: '#FF416C'
    },
    {
      id: 2,
      name: "Grace Community Church",
      role: "Bible Study Leader",
      denomination: "Evangelical",
      location: "321 Faith Lane, Austin, TX",
      serviceTime: "Sun 9:30AM",
      description: "Dedicated to in-depth Bible study and discipleship. Join us for our weekly studies and fellowship.",
      image: "https://picsum.photos/400/200?random=2",
      color: '#4CAF50'
    }
  ]);

  const [staffMembers, setStaffMembers] = useState([
    {
      id: 1,
      name: 'John Smith',
      role: 'Associate Pastor',
      email: 'john@example.com',
      permissions: {
        canEdit: true,
        canPublish: true,
        canManageUsers: true,
        canManageContent: true
      },
      image: "https://picsum.photos/40/40?random=1"
    },
    {
      id: 2,
      name: 'Sarah Wilson',
      role: 'Youth Director',
      email: 'sarah@example.com',
      permissions: {
        canEdit: true,
        canPublish: true,
        canManageUsers: false,
        canManageContent: true
      },
      image: "https://picsum.photos/40/40?random=2"
    }
  ]);

  const [members, setMembers] = useState([
    {
      id: 1,
      name: 'Michael Brown',
      role: 'Member',
      email: 'michael@example.com',
      permissions: {
        canView: true,
        canComment: true,
        canParticipate: true
      },
      image: "https://picsum.photos/40/40?random=3"
    },
    {
      id: 2,
      name: 'Emily Davis',
      role: 'Member',
      email: 'emily@example.com',
      permissions: {
        canView: true,
        canComment: true,
        canParticipate: true
      },
      image: "https://picsum.photos/40/40?random=4"
    }
  ]);

  const [isStaffOpen, setIsStaffOpen] = useState(false);
  const [isMembersOpen, setIsMembersOpen] = useState(false);

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteType, setInviteType] = useState(null); // 'staff' or 'member'
  const [inviteLink, setInviteLink] = useState('');

  const generateInviteLink = (type) => {
    const baseUrl = window.location.origin;
    const uniqueCode = Math.random().toString(36).substring(2, 15);
    return `${baseUrl}/join/${type}/${uniqueCode}`;
  };

  const handleStaffPermissionChange = (staffId, permission, value) => {
    setStaffMembers(prev => prev.map(staff => 
      staff.id === staffId 
        ? {
            ...staff,
            permissions: {
              ...staff.permissions,
              [permission]: value
            }
          }
        : staff
    ));
  };

  const handleMemberPermissionChange = (memberId, permission, value) => {
    setMembers(prev => prev.map(member => 
      member.id === memberId 
        ? {
            ...member,
            permissions: {
              ...member.permissions,
              [permission]: value
            }
          }
        : member
    ));
  };

  const handleAddStaff = () => {
    const link = generateInviteLink('staff');
    setInviteLink(link);
    setInviteType('staff');
    setShowInviteModal(true);
  };

  const handleAddMember = () => {
    const link = generateInviteLink('member');
    setInviteLink(link);
    setInviteType('member');
    setShowInviteModal(true);
  };

  const InviteModal = ({ type, link, onClose }) => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000,
    }}>
      <div style={{
        background: 'white',
        borderRadius: '16px',
        padding: '2rem',
        width: '90%',
        maxWidth: '500px',
        position: 'relative',
      }}>
        <button 
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            background: 'none',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
            color: '#666666',
          }}
        >
          <i className="mdi mdi-close"></i>
        </button>
        
        <h2 style={{
          fontSize: '1.5rem',
          marginBottom: '1rem',
          fontFamily: "'Cardo', serif",
        }}>
          Invite {type === 'staff' ? 'Staff Member' : 'Member'}
        </h2>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.5rem',
        }}>
          <QRCodeSVG 
            value={link}
            size={200}
            level="H"
            includeMargin={true}
            style={{
              padding: '1rem',
              background: 'white',
              borderRadius: '12px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
            }}
          />

          <div style={{
            width: '100%',
            position: 'relative',
          }}>
            <input
              type="text"
              value={link}
              readOnly
              style={{
                width: '100%',
                padding: '0.75rem 1rem',
                paddingRight: '4.5rem',
                borderRadius: '8px',
                border: '1px solid rgba(226, 232, 240, 0.8)',
                fontSize: '0.9rem',
              }}
            />
            <button
              onClick={() => {
                navigator.clipboard.writeText(link);
                // You could add a toast notification here
              }}
              style={{
                position: 'absolute',
                right: '0.5rem',
                top: '50%',
                transform: 'translateY(-50%)',
                background: 'none',
                border: 'none',
                color: '#666666',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '0.25rem',
                padding: '0.5rem',
              }}
            >
              <i className="mdi mdi-content-copy"></i>
              Copy
            </button>
          </div>

          <div style={{
            textAlign: 'center',
            color: '#666666',
            fontSize: '0.9rem',
          }}>
            Share this QR code or link to invite a new {type === 'staff' ? 'staff member' : 'member'}
          </div>
        </div>
      </div>
    </div>
  );

  const [isGroupsOpen, setIsGroupsOpen] = useState(false);

  const myGroups = [
    {
      id: 1,
      name: 'Sermon Preparation',
      members: 45,
      icon: 'mdi-cross',
      color: '#FF416C',
      description: 'Collaborate on sermon preparation and share resources',
      image: "https://picsum.photos/400/200?random=10",
      role: 'Admin'
    },
    {
      id: 2,
      name: 'Bible Study Leaders',
      members: 32,
      icon: 'mdi-book-open-variant',
      color: '#4CAF50',
      description: 'Connect with other Bible study leaders',
      image: "https://picsum.photos/400/200?random=11",
      role: 'Member'
    },
    {
      id: 3,
      name: 'Prayer Warriors',
      members: 78,
      icon: 'mdi-hands-pray',
      color: '#FF4B2B',
      description: 'Join together in prayer and intercession',
      image: "https://picsum.photos/400/200?random=12",
      role: 'Moderator'
    }
  ];

  const [selectedMinistry, setSelectedMinistry] = useState(myMinistries[0]);
  const [isMinistryProfilesOpen, setIsMinistryProfilesOpen] = useState(false);

  const handleMinistrySelect = (ministry) => {
    setSelectedMinistry(ministry);
    // Update stats or other content based on selected ministry
    // You can add more logic here
  };

  const handleMinistryRemove = (ministryId) => {
    const updatedMinistries = myMinistries.filter(m => m.id !== ministryId);
    setMyMinistries(updatedMinistries);
    
    // If the removed ministry was selected, select the first available ministry
    if (selectedMinistry.id === ministryId) {
      setSelectedMinistry(updatedMinistries[0] || null);
    }
  };

  const handleAddMinistry = () => {
    navigate('/community');
  };

  const publicMinistries = [
    {
      id: 1,
      name: "First Baptist Church",
      role: "Associate Pastor",
      denomination: "Baptist",
      location: "123 Main Street, Anytown",
      serviceTime: "Sun 9AM & 11AM",
      description: "A welcoming community focused on Biblical teaching and family ministry.",
      image: "https://picsum.photos/400/200?random=1",
      color: '#FF416C'
    },
    {
      id: 2,
      name: "Grace Community Church",
      role: "Bible Study Leader",
      denomination: "Non-denominational",
      location: "456 Oak Avenue, Anytown",
      serviceTime: "Sun 10AM",
      description: "Contemporary worship with a strong emphasis on community outreach.",
      image: "https://picsum.photos/400/200?random=2",
      color: '#4CAF50'
    },
    {
      id: 3,
      name: "St. Mary's Cathedral",
      role: "Youth Pastor",
      denomination: "Catholic",
      location: "789 Church Road, Anytown",
      serviceTime: "Sat 5PM, Sun 8AM & 10AM",
      description: "Historic cathedral with vibrant youth and music ministries.",
      image: "https://picsum.photos/400/200?random=3",
      color: '#FF4B2B'
    }
  ];

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [upcomingEvents] = useState([
    {
      title: "Leadership Conference",
      date: "November 11, 2024",
      time: "9:00 AM",
      location: "Main Auditorium",
      icon: "mdi-account-group",
      color: "#FF416C",
      description: "Annual leadership conference for church leaders and ministry heads."
    },
    {
      title: "Youth Ministry Workshop",
      date: "November 11, 2024",
      time: "2:00 PM",
      location: "Youth Center",
      icon: "mdi-account-child",
      color: "#4CAF50",
      description: "Workshop focusing on engaging youth in ministry activities."
    },
    {
      title: "Worship Team Practice",
      date: "November 12, 2024",
      time: "6:30 PM",
      location: "Sanctuary",
      icon: "mdi-music",
      color: "#82B1FF",
      description: "Weekly worship team rehearsal and prayer meeting."
    },
    {
      title: "Bible Study",
      date: "November 13, 2024",
      time: "7:00 PM",
      location: "Fellowship Hall",
      icon: "mdi-book-open-variant",
      color: "#FF4B2B",
      description: "Mid-week Bible study focusing on the Book of Romans."
    },
    {
      title: "Prayer Meeting",
      date: "November 13, 2024",
      time: "5:30 PM",
      location: "Prayer Room",
      icon: "mdi-hands-pray",
      color: "#9C27B0",
      description: "Community prayer gathering for church needs and missions."
    }
  ]);

  const Calendar = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    
    const daysInMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    ).getDate();
    
    const firstDayOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    ).getDay();

    const prevMonthDays = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      0
    ).getDate();

    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const today = new Date();

    const [selectedDateEvents, setSelectedDateEvents] = useState([]);

    const handlePrevMonth = () => {
      setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)));
    };

    const handleNextMonth = () => {
      setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)));
    };

    const handleDateClick = (date) => {
      setSelectedDate(date);
      // Filter events for the selected date
      const dateEvents = upcomingEvents.filter(event => {
        const eventDate = new Date(event.date);
        return (
          date === eventDate.getDate() &&
          currentMonth.getMonth() === eventDate.getMonth() &&
          currentMonth.getFullYear() === eventDate.getFullYear()
        );
      });
      setSelectedDateEvents(dateEvents);
    };

    const formatSelectedDate = () => {
      if (!selectedDate) return '';
      const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), selectedDate);
      return date.toLocaleDateString('en-US', { 
        weekday: 'long', 
        month: 'long', 
        day: 'numeric',
        year: 'numeric'
      });
    };

    const renderCalendarDays = () => {
      const calendarDays = [];
      
      // Previous month days
      for (let i = firstDayOfMonth - 1; i >= 0; i--) {
        calendarDays.push(
          <button 
            key={`prev-${i}`} 
            style={{...styles.date, ...styles.faded}}
            disabled
          >
            {prevMonthDays - i}
          </button>
        );
      }

      // Current month days
      for (let i = 1; i <= daysInMonth; i++) {
        const isToday = 
          i === today.getDate() && 
          currentMonth.getMonth() === today.getMonth() &&
          currentMonth.getFullYear() === today.getFullYear();

        const hasEvent = upcomingEvents.some(event => {
          const eventDate = new Date(event.date);
          return (
            i === eventDate.getDate() &&
            currentMonth.getMonth() === eventDate.getMonth() &&
            currentMonth.getFullYear() === eventDate.getFullYear()
          );
        });

        const isSelected = selectedDate === i;

        calendarDays.push(
          <button 
            key={`current-${i}`} 
            onClick={() => handleDateClick(i)}
            style={{
              ...styles.date,
              ...(isToday ? styles.currentDay : {}),
              ...(isSelected ? {
                border: '2px solid #82B1FF', // Update from var(--primary-color)
                transform: 'scale(1.05)'
              } : {}),
              ...(hasEvent && !isToday ? {
                fontWeight: '600',
                color: '#82B1FF' // Update from var(--primary-color)
              } : {})
            }}
          >
            {i}
            {hasEvent && <div style={styles.eventIndicator} />}
          </button>
        );
      }

      // Next month days
      const remainingDays = 42 - calendarDays.length;
      for (let i = 1; i <= remainingDays; i++) {
        calendarDays.push(
          <button 
            key={`next-${i}`} 
            style={{...styles.date, ...styles.faded}}
            disabled
          >
            {i}
          </button>
        );
      }

      return calendarDays;
    };

    return (
      <div style={{
        background: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(10px)',
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.7)',
        padding: '1.5rem',
      }}>
        {/* Header */}
        <div style={styles.calendarHeader}>
          <i className="mdi mdi-calendar" style={{
            ...styles.calendarIcon,
            color: '#82B1FF' // Update to match the light blue color
          }}></i>
          <h2 style={styles.calendarTitle}>Events Calendar</h2>
        </div>

        {/* Calendar and Events Container */}
        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: '1.5rem',
          '@media (min-width: 768px)': {
            gridTemplateColumns: '1fr 300px',
          },
        }}>
          {/* Calendar Section */}
          <div>
            <div style={styles.monthSelector}>
              <button style={styles.arrow} onClick={handlePrevMonth}>
                <i className="mdi mdi-chevron-left"></i>
              </button>
              <span style={styles.monthName}>
                {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
              </span>
              <button style={styles.arrow} onClick={handleNextMonth}>
                <i className="mdi mdi-chevron-right"></i>
              </button>
            </div>
            <div style={{...styles.calendar, marginTop: '1rem'}}>
              {days.map(day => (
                <span key={day} style={styles.day}>{day}</span>
              ))}
              {renderCalendarDays()}
            </div>
          </div>

          {/* Events Section */}
          <div style={{
            borderLeft: '1px solid rgba(226, 232, 240, 0.8)',
            '@media (max-width: 767px)': {
              borderLeft: 'none',
              borderTop: '1px solid rgba(226, 232, 240, 0.8)',
              marginTop: '1.5rem',
            },
          }}>
            {selectedDate ? (
              <>
                <div style={styles.selectedDateHeader}>
                  <h3 style={styles.selectedDate}>{formatSelectedDate()}</h3>
                </div>
                <div style={styles.eventsList}>
                  {selectedDateEvents.length > 0 ? (
                    selectedDateEvents.map((event, index) => (
                      <div key={index} style={{
                        ...styles.eventCard,
                        background: 'transparent',
                        border: 'none',
                        padding: '0.75rem 1rem',
                        borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
                        '&:last-child': {
                          borderBottom: 'none',
                        },
                      }}>
                        <div style={{
                          ...styles.eventIcon,
                          background: `${event.color}15`,
                          color: event.color,
                        }}>
                          <i className={`mdi ${event.icon}`}></i>
                        </div>
                        <div style={styles.eventInfo}>
                          <h4 style={styles.eventTitle}>{event.title}</h4>
                          <div style={styles.eventDetails}>
                            <span style={styles.eventDetail}>
                              <i className="mdi mdi-clock-outline"></i>
                              {event.time}
                            </span>
                            <span style={styles.eventDetail}>
                              <i className="mdi mdi-map-marker"></i>
                              {event.location}
                            </span>
                          </div>
                          <p style={{
                            margin: '0.5rem 0 0',
                            fontSize: '0.9rem',
                            color: '#666666',
                            lineHeight: '1.4'
                          }}>
                            {event.description}
                          </p>
                        </div>
                        <button style={{
                          ...styles.arrow,
                          color: event.color,
                          padding: '0.5rem',
                          borderRadius: '50%',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            background: `${event.color}15`,
                          },
                        }}>
                          <i className="mdi mdi-chevron-right"></i>
                        </button>
                      </div>
                    ))
                  ) : (
                    <div style={{
                      textAlign: 'center',
                      color: '#666666',
                      padding: '1rem',
                    }}>
                      <i className="mdi mdi-calendar-blank" style={{ fontSize: '2rem', marginBottom: '0.5rem' }}></i>
                      <p>No events scheduled for this date</p>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div style={{
                textAlign: 'center',
                color: '#666666',
                padding: '1rem',
              }}>
                <i className="mdi mdi-calendar-blank" style={{ fontSize: '2rem', marginBottom: '0.5rem' }}></i>
                <p>Select a date to view events</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={styles.dashboardContainer}>
      <div style={styles.dashboardContent}>
        <div style={tabStyles.navigationTabs}>
          <div style={tabStyles.tabsContainer}>
            {tabs.map(tab => (
              <button
                key={tab.id}
                style={tabStyles.tab}
                onClick={() => setActiveTab(tab.id)}
              >
                <i 
                  className={`mdi ${tab.icon}`} 
                  style={{
                    ...tabStyles.tabIcon,
                    ...(activeTab === tab.id && tabStyles.tabIconActive)
                  }}
                ></i>
                <span style={{
                  ...tabStyles.tabLabel,
                  ...(activeTab === tab.id && tabStyles.tabLabelActive)
                }}>
                  {tab.title}
                </span>
              </button>
            ))}
          </div>
        </div>

        {activeTab === 'overview' && (
          <div style={{ width: '100%' }}>
            {notifications.length > 0 && (
              <div style={{ width: '100%', marginBottom: '2rem' }}>
                {notifications.map((notification, index) => (
                  <div key={index} style={{
                    ...styles.notificationCard,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    padding: '1.25rem',
                    background: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '16px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
                    border: '1px solid rgba(255, 255, 255, 0.7)',
                    marginBottom: '1rem',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 6px 8px rgba(0, 0, 0, 0.1)',
                    }
                  }}>
                    <div style={{
                      width: '48px',
                      height: '48px',
                      borderRadius: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: `${notification.color}15`,
                      color: notification.color,
                      fontSize: '1.5rem',
                      flexShrink: 0
                    }}>
                      <i className={`mdi ${notification.icon}`}></i>
                    </div>
                    <div style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.25rem'
                    }}>
                      <p style={{
                        margin: 0,
                        fontSize: '1rem',
                        color: '#333333',
                        fontWeight: '500',
                        lineHeight: '1.4'
                      }}>{notification.message}</p>
                      <span style={{
                        fontSize: '0.875rem',
                        color: '#666666',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                      }}>
                        <i className="mdi mdi-clock-outline" style={{ fontSize: '1rem' }}></i>
                        {notification.time}
                      </span>
                    </div>
                    <button style={{
                      background: 'transparent',
                      border: 'none',
                      color: '#666666',
                      padding: '0.5rem',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        background: 'rgba(0, 0, 0, 0.05)',
                        color: '#FF416C'
                      }
                    }}>
                      <i className="mdi mdi-close" style={{ fontSize: '1.25rem' }}></i>
                    </button>
                  </div>
                ))}
              </div>
            )}

            {/* My Groups Section */}
            <div style={{
              marginBottom: '2rem',
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '1rem',
                marginBottom: isGroupsOpen ? '1.5rem' : '0',
              }}>
                <div style={styles.ministryHeaderLeft}>
                  <i className="mdi mdi-account-group" style={{...styles.ministryIcon, color: '#FF4B2B'}}></i>
                  <h2 style={styles.ministryTitle}>My Groups</h2>
                </div>
                <button 
                  style={styles.ministryToggle}
                  onClick={() => setIsGroupsOpen(!isGroupsOpen)}
                >
                  <i className={`mdi mdi-chevron-${isGroupsOpen ? 'up' : 'down'}`} style={{ fontSize: '1.5rem' }}></i>
                </button>
              </div>
              
              {isGroupsOpen && (
                <div style={styles.ministryContent}>
                  {myGroups.map((group) => (
                    <div key={group.id} style={styles.ministryCard}>
                      <div style={{
                        ...styles.iconBar,
                        background: `linear-gradient(135deg, ${group.color}dd 0%, ${group.color}99 100%)`
                      }}>
                        <i className={`mdi ${group.icon}`} style={styles.feedIcon}></i>
                      </div>
                      <div style={styles.ministryImageContainer}>
                        <img src={group.image} alt={group.name} style={styles.ministryImage} />
                        <div style={styles.ministryOverlay}>
                          <h3 style={styles.ministryName}>{group.name}</h3>
                        </div>
                      </div>
                      <div style={styles.ministryDetails}>
                        <div style={styles.ministryInfo}>
                          <div style={styles.ministryInfoItem}>
                            <i className="mdi mdi-account-multiple" style={{
                              color: group.color,
                              fontSize: '1.2rem'
                            }}></i>
                            <span>{group.members} members</span>
                          </div>
                          <div style={styles.ministryInfoItem}>
                            <i className="mdi mdi-shield-account" style={{
                              color: group.color,
                              fontSize: '1.2rem'
                            }}></i>
                            <span>{group.role}</span>
                          </div>
                          <p style={{
                            margin: '0.5rem 0 0',
                            fontSize: '0.9rem',
                            color: '#666666',
                            lineHeight: '1.4'
                          }}>
                            {group.description}
                          </p>
                        </div>
                        <div style={styles.ministryActions}>
                          <button style={{
                            ...styles.ministryButton,
                            ...styles.ministryPrimaryButton,
                            background: group.color,
                          }}>
                            <i className="mdi mdi-forum"></i>
                            View Group
                          </button>
                          <button style={{...styles.ministryButton, ...styles.ministrySecondaryButton}}>
                            <i className="mdi mdi-cog"></i>
                            Settings
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Community Actions Section */}
            <div style={{
              marginBottom: '2rem',
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '1rem',
                marginBottom: isActionsOpen ? '1.5rem' : '0',
              }}>
                <div style={styles.ministryHeaderLeft}>
                  <i className="mdi mdi-account-group" style={{...styles.ministryIcon, color: '#FF416C'}}></i>
                  <h2 style={styles.ministryTitle}>Community Actions</h2>
                </div>
                <button 
                  style={styles.ministryToggle}
                  onClick={() => setIsActionsOpen(!isActionsOpen)}
                >
                  <i className={`mdi mdi-chevron-${isActionsOpen ? 'up' : 'down'}`} style={{ fontSize: '1.5rem' }}></i>
                </button>
              </div>
              
              {isActionsOpen && (
                <div style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: '1rem',
                  padding: '0 1rem',
                }}>
                  {communityActions.map((action, index) => (
                    <div key={index} style={{
                      background: 'rgba(255, 255, 255, 0.9)',
                      borderRadius: '12px',
                      overflow: 'hidden',
                      border: '1px solid rgba(226, 232, 240, 0.8)',
                      transition: 'all 0.2s ease',
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%', // Ensure all cards are same height
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                      },
                    }}>
                      <div style={{
                        ...styles.iconBar,
                        background: `linear-gradient(135deg, ${action.color}dd 0%, ${action.color}99 100%)`,
                        height: '40px',
                      }}>
                        <i className={`mdi ${action.icon}`} style={{
                          ...styles.feedIcon,
                          fontSize: '1.2rem',
                        }}></i>
                      </div>
                      <div style={{
                        padding: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%', // Take up remaining space
                      }}>
                        <div style={{ flex: '1' }}> {/* Content container that can grow */}
                          <h3 style={{
                            fontSize: '1.1rem',
                            fontWeight: '600',
                            color: '#333333',
                            marginBottom: '0.5rem',
                            fontFamily: "'Cardo', serif",
                          }}>{action.title}</h3>
                          <p style={{
                            margin: '0',
                            fontSize: '0.85rem',
                            color: '#666666',
                            lineHeight: '1.4',
                          }}>
                            {action.description}
                          </p>
                        </div>
                        <button 
                          onClick={action.action}
                          style={{
                            width: '100%',
                            padding: '0.75rem',
                            borderRadius: '8px',
                            border: 'none',
                            background: action.color,
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '0.5rem',
                            fontSize: '0.9rem',
                            fontWeight: '500',
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                            marginTop: '1rem', // Consistent spacing above button
                            '&:hover': {
                              opacity: 0.9,
                              transform: 'translateY(-2px)',
                            },
                          }}
                        >
                          <i className={`mdi ${action.icon}`}></i>
                          Get Started
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Calendar component remains the same */}
            <Calendar />
          </div>
        )}

        {activeTab === 'discover' && (
          <div style={styles.ministryContent}>
            {publicMinistries.map((ministry) => (
              <div key={ministry.id} style={styles.ministryCard}>
                <div style={{
                  ...styles.iconBar,
                  background: `linear-gradient(135deg, ${ministry.color}dd 0%, ${ministry.color}99 100%)`
                }}>
                  <i className="mdi mdi-church" style={styles.feedIcon}></i>
                </div>
                <div style={styles.ministryImageContainer}>
                  <img src={ministry.image} alt={ministry.name} style={styles.ministryImage} />
                  <div style={styles.ministryOverlay}>
                    <h3 style={styles.ministryName}>{ministry.name}</h3>
                  </div>
                </div>
                <div style={styles.ministryDetails}>
                  <div style={styles.ministryInfo}>
                    <div style={styles.ministryInfoItem}>
                      <i className="mdi mdi-account-tie" style={{
                        color: ministry.color,
                        fontSize: '1.2rem'
                      }}></i>
                      <span>{ministry.role}</span>
                    </div>
                    <div style={styles.ministryInfoItem}>
                      <i className="mdi mdi-church" style={{
                        color: ministry.color,
                        fontSize: '1.2rem'
                      }}></i>
                      <span>{ministry.denomination}</span>
                    </div>
                    <div style={styles.ministryInfoItem}>
                      <i className="mdi mdi-map-marker" style={{
                        color: ministry.color,
                        fontSize: '1.2rem'
                      }}></i>
                      <span>{ministry.location}</span>
                    </div>
                    <div style={styles.ministryInfoItem}>
                      <i className="mdi mdi-clock-outline" style={{
                        color: ministry.color,
                        fontSize: '1.2rem'
                      }}></i>
                      <span>{ministry.serviceTime}</span>
                    </div>
                  </div>
                  <div style={styles.ministryActions}>
                    <button style={{
                      ...styles.ministryButton,
                      ...styles.ministryPrimaryButton,
                      background: ministry.color,
                    }}>
                      <i className="mdi mdi-account-plus"></i>
                      Join Ministry
                    </button>
                    <button style={{...styles.ministryButton, ...styles.ministrySecondaryButton}}>
                      <i className="mdi mdi-information"></i>
                      Details
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {activeTab === 'connected' && (
          <div style={styles.ministryContent}>
            {myMinistries.map((ministry) => (
              <div key={ministry.id} style={styles.ministryCard}>
                <div style={{
                  ...styles.iconBar,
                  background: `linear-gradient(135deg, ${ministry.color}dd 0%, ${ministry.color}99 100%)`
                }}>
                  <i className="mdi mdi-church" style={styles.feedIcon}></i>
                </div>
                <div style={styles.ministryImageContainer}>
                  <img src={ministry.image} alt={ministry.name} style={styles.ministryImage} />
                  <div style={styles.ministryOverlay}>
                    <h3 style={styles.ministryName}>{ministry.name}</h3>
                  </div>
                </div>
                <div style={styles.ministryDetails}>
                  <div style={styles.ministryInfo}>
                    <div style={styles.ministryInfoItem}>
                      <i className="mdi mdi-account-tie" style={{
                        color: ministry.color,
                        fontSize: '1.2rem'
                      }}></i>
                      <span>{ministry.role}</span>
                    </div>
                    <div style={styles.ministryInfoItem}>
                      <i className="mdi mdi-church" style={{
                        color: ministry.color,
                        fontSize: '1.2rem'
                      }}></i>
                      <span>{ministry.denomination}</span>
                    </div>
                    <div style={styles.ministryInfoItem}>
                      <i className="mdi mdi-map-marker" style={{
                        color: ministry.color,
                        fontSize: '1.2rem'
                      }}></i>
                      <span>{ministry.location}</span>
                    </div>
                    <div style={styles.ministryInfoItem}>
                      <i className="mdi mdi-clock-outline" style={{
                        color: ministry.color,
                        fontSize: '1.2rem'
                      }}></i>
                      <span>{ministry.serviceTime}</span>
                    </div>
                  </div>
                  <div style={styles.ministryActions}>
                    <button style={{
                      ...styles.ministryButton,
                      ...styles.ministryPrimaryButton,
                      background: ministry.color,
                    }}>
                      <i className="mdi mdi-view-dashboard"></i>
                      Control Panel
                    </button>
                    <button style={{...styles.ministryButton, ...styles.ministrySecondaryButton}}>
                      <i className="mdi mdi-cog"></i>
                      Settings
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {activeTab === 'ministry' && (
          <>
            <div style={{
              marginBottom: '2rem',
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '1rem',
                marginBottom: isMinistryOpen ? '1.5rem' : '0',
              }}>
                <div style={styles.ministryHeaderLeft}>
                  <i className="mdi mdi-church" style={styles.ministryIcon}></i>
                  <h2 style={styles.ministryTitle}>My Ministries</h2>
                </div>
                <button 
                  style={styles.ministryToggle}
                  onClick={() => setIsMinistryOpen(!isMinistryOpen)}
                >
                  <i className={`mdi mdi-chevron-${isMinistryOpen ? 'up' : 'down'}`} style={{ fontSize: '1.5rem' }}></i>
                </button>
              </div>
              
              {isMinistryOpen && (
                <div style={styles.ministryContent}>
                  {myMinistries.map((ministry) => (
                    <div key={ministry.id} style={styles.ministryCard}>
                      <div style={{
                        ...styles.iconBar,
                        background: `linear-gradient(135deg, ${ministry.color}dd 0%, ${ministry.color}99 100%)`
                      }}>
                        <i className="mdi mdi-church" style={styles.feedIcon}></i>
                      </div>
                      <div style={styles.ministryImageContainer}>
                        <img src={ministry.image} alt={ministry.name} style={styles.ministryImage} />
                        <div style={styles.ministryOverlay}>
                          <h3 style={styles.ministryName}>{ministry.name}</h3>
                        </div>
                      </div>
                      <div style={styles.ministryDetails}>
                        <div style={styles.ministryInfo}>
                          <div style={styles.ministryInfoItem}>
                            <i className="mdi mdi-account-tie" style={{
                              color: ministry.color,
                              fontSize: '1.2rem'
                            }}></i>
                            <span>{ministry.role}</span>
                          </div>
                          <div style={styles.ministryInfoItem}>
                            <i className="mdi mdi-church" style={{
                              color: ministry.color,
                              fontSize: '1.2rem'
                            }}></i>
                            <span>{ministry.denomination}</span>
                          </div>
                          <div style={styles.ministryInfoItem}>
                            <i className="mdi mdi-map-marker" style={{
                              color: ministry.color,
                              fontSize: '1.2rem'
                            }}></i>
                            <span>{ministry.location}</span>
                          </div>
                          <div style={styles.ministryInfoItem}>
                            <i className="mdi mdi-clock-outline" style={{
                              color: ministry.color,
                              fontSize: '1.2rem'
                            }}></i>
                            <span>{ministry.serviceTime}</span>
                          </div>
                        </div>
                        <div style={styles.ministryActions}>
                          <button style={{
                            ...styles.ministryButton,
                            ...styles.ministryPrimaryButton,
                            background: ministry.color,
                          }}>
                            <i className="mdi mdi-view-dashboard"></i>
                            Control Panel
                          </button>
                          <button style={{...styles.ministryButton, ...styles.ministrySecondaryButton}}>
                            <i className="mdi mdi-cog"></i>
                            Settings
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div style={styles.statsGrid}>
              {stats.map((stat, index) => (
                <div key={index} style={styles.statCard}>
                  <div style={{
                    ...styles.iconBar,
                    background: `linear-gradient(135deg, ${stat.color}dd 0%, ${stat.color}99 100%)`
                  }}>
                    <i className={`mdi ${stat.icon}`} style={styles.feedIcon}></i>
                  </div>
                  <div style={styles.statContent}>
                    <div style={styles.statHeader}>
                      <div style={styles.statValue}>{stat.value}</div>
                      <div style={styles.statTitle}>{stat.title}</div>
                    </div>
                    <div style={styles.statFooter}>
                      <span style={styles.statSubtitle}>{stat.subtitle}</span>
                      <div style={{
                        ...styles.trendIndicator,
                        ...(stat.trend.startsWith('+') ? styles.trendPositive : styles.trendNegative)
                      }}>
                        <i className={`mdi ${stat.trend.startsWith('+') ? 'mdi-trending-up' : 'mdi-trending-down'}`}></i>
                        <span>{stat.trend}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {showInviteModal && (
        <InviteModal
          type={inviteType}
          link={inviteLink}
          onClose={() => {
            setShowInviteModal(false);
            setInviteType(null);
            setInviteLink('');
          }}
        />
      )}
    </div>
  );
};

export default Dashboard;