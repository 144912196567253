import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db, deleteSermonFromFirestore, deleteStudyFromFirestore, getUserBibleStudies } from '../control/firebase';

const getYouTubeThumbnail = (url) => {
  try {
    let videoId;
    if (url.includes('youtu.be/')) {
      videoId = url.split('youtu.be/')[1].split('?')[0];
    } else if (url.includes('youtube.com/watch')) {
      videoId = url.split('v=')[1].split('&')[0];
    } else if (url.includes('youtube.com/embed/')) {
      videoId = url.split('embed/')[1].split('?')[0];
    }
    
    if (videoId) {
      // Return the high quality thumbnail URL
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    }
  } catch (err) {
    console.error('Error extracting video ID:', err);
  }
  return null;
};

const Library = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [activeSection, setActiveSection] = useState('sermons');
  const [sermons, setSermons] = useState([]);
  const [studies, setStudies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteModal, setDeleteModal] = useState({ show: false, contentId: null, type: null });
  const [deleteLoading, setDeleteLoading] = useState(false);

  const styles = {
    libraryContainer: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '-10px',
    },
    navigationTabs: {
      width: '100%',
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '12px',
      padding: '0.5rem',
      marginBottom: '1rem',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.07)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
    },
    tabsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '0.5rem',
      width: '100%',
      padding: '0',
    },
    tab: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.25rem',
      padding: '0.5rem',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      background: 'transparent',
      position: 'relative',
      fontFamily: "'Cardo', serif",
    },
    tabIcon: {
      fontSize: '1.3rem',
      color: '#333333',
      transition: 'color 0.2s ease',
    },
    tabIconActive: {
      color: 'var(--primary-color)',
    },
    tabLabel: {
      fontSize: '0.9rem',
      fontWeight: '500',
      color: '#333333',
      fontFamily: "'Cardo', serif",
    },
    tabLabelActive: {
      color: 'var(--primary-color)',
      fontWeight: '600',
      fontFamily: "'Cardo', serif",
    },
    subNav: {
      background: '#FFFFFF',
      borderRadius: '12px',
      padding: '1rem',
      marginBottom: '2rem',
      boxShadow: 'var(--shadow-sm)',
      border: '1px solid #E2E8F0',
    },
    subNavContent: {
      display: 'flex',
      gap: '1rem',
      overflowX: 'auto',
      padding: '0.5rem',
    },
    subNavButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      padding: '0.75rem 1.25rem',
      borderRadius: '8px',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.95rem',
      fontWeight: '500',
      whiteSpace: 'nowrap',
    },
    subNavButtonActive: {
      background: 'var(--primary-color)',
      color: 'white',
    },
    subNavButtonInactive: {
      background: 'transparent',
      color: 'var(--text-color)',
      '&:hover': {
        background: 'var(--hover-bg)',
      },
    },
    errorMessage: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      padding: '1rem',
      borderRadius: '8px',
      background: 'rgba(220, 53, 69, 0.1)',
      color: 'var(--error-color)',
      marginBottom: '1.5rem',
    },
    contentGrid: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    loadingState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      padding: '3rem',
      color: 'var(--text-light-color)',
      fontSize: '1.1rem',
      '& i': {
        fontSize: '2rem',
        color: 'var(--primary-color)',
      },
    },
    card: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      marginBottom: '1rem',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      width: '100%',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
      },
    },
    iconBar: {
      width: '100%',
      height: '48px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)`,
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      padding: '0',
      lineHeight: '1',
    },
    feedIcon: {
      color: 'white',
      fontSize: '1.5rem',
      filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      marginTop: '1px',
    },
    cardHeader: {
      display: 'flex',
      gap: '1rem',
      padding: '1.5rem 1.5rem 1rem',
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    contentInfo: {
      flex: 1,
    },
    cardTitle: {
      fontSize: '1.1rem',
      fontWeight: '600',
      margin: 0,
      color: '#333333',
      marginBottom: '0.25rem',
    },
    metaInfo: {
      color: '#666666',
      fontSize: '0.875rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.25rem',
    },
    cardDescription: {
      padding: '1.5rem',
      color: '#2D3748',
      fontSize: '1rem',
      lineHeight: '1.6',
      background: 'rgba(255, 255, 255, 0.2)',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
    },
    cardActions: {
      display: 'flex',
      padding: '0.75rem',
      gap: '0.5rem',
      background: 'rgba(247, 250, 252, 0.8)',
      backdropFilter: 'blur(4px)',
    },
    button: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      padding: '0.75rem',
      border: 'none',
      borderRadius: '12px',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.95rem',
      fontWeight: '500',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    buttonPrimary: {
      background: 'rgba(255, 0, 0, 0.8)',
      color: 'white',
      boxShadow: '0 2px 4px rgba(255, 0, 0, 0.2)',
      '&:hover': {
        background: 'rgba(255, 0, 0, 0.9)',
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
      },
    },
    buttonSecondary: {
      background: 'rgba(241, 245, 249, 0.8)',
      color: '#666666',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      '&:hover': {
        background: 'rgba(226, 232, 240, 0.9)',
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      },
    },
    buttonIcon: {
      background: 'transparent',
      border: 'none',
      color: '#666666',
      cursor: 'pointer',
      padding: '0.5rem',
      borderRadius: '50%',
      transition: 'all 0.2s ease',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backdropFilter: 'blur(4px)',
      '&:hover': {
        background: 'rgba(241, 245, 249, 0.8)',
        color: 'var(--error-color)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      },
    },
    emptyState: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '2rem',
      marginTop: '1rem',
    },
    emptyStateIcon: {
      fontSize: '3rem',
      color: 'var(--primary-color)',
      marginBottom: '1rem',
    },
    emptyStateTitle: {
      fontSize: '1.5rem',
      fontWeight: '600',
      color: 'var(--text-color)',
      marginBottom: '0.5rem',
      fontFamily: "'Cardo', serif",
    },
    emptyStateText: {
      color: 'var(--text-light-color)',
      marginBottom: '1.5rem',
    },
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem',
      zIndex: 1000,
    },
    modalContent: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      padding: '2rem',
      maxWidth: '500px',
      width: '100%',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
    },
    modalTitle: {
      fontSize: '1.5rem',
      fontWeight: '600',
      color: 'var(--text-color)',
      marginBottom: '1rem',
      fontFamily: "'Cardo', serif",
    },
    modalText: {
      color: 'var(--text-light-color)',
      marginBottom: '1.5rem',
    },
    buttonGroup: {
      display: 'flex',
      gap: '1rem',
    },
    buttonDanger: {
      background: 'var(--error-color)',
      color: 'white',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: 'var(--shadow-sm)',
      },
    },
  };

  // Fetch sermons and studies from Firestore
  useEffect(() => {
    const fetchContent = async () => {
      if (!currentUser) return;
      
      try {
        setLoading(true);
        
        // Fetch sermons
        const sermonsRef = collection(db, 'users', currentUser.uid, 'sermons');
        const sermonsQuery = query(sermonsRef, orderBy('updatedAt', 'desc'));
        const sermonsSnapshot = await getDocs(sermonsQuery);
        const sermonsData = sermonsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Fetch studies using the Firebase function
        const studiesData = await getUserBibleStudies(currentUser.uid);
        
        setSermons(sermonsData);
        setStudies(studiesData);
      } catch (error) {
        console.error('Error fetching content:', error);
        setError('Failed to load content');
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [currentUser]);

  const handleDelete = (contentId, type = 'sermon') => {
    console.log(`Deleting ${type}:`, contentId);
    setDeleteModal({ show: true, contentId, type });
  };

  const confirmDelete = async () => {
    if (!deleteModal.contentId) return;
    
    setDeleteLoading(true);
    try {
      if (deleteModal.type === 'sermon') {
        await deleteSermonFromFirestore(currentUser.uid, deleteModal.contentId);
        setSermons(prevSermons => 
          prevSermons.filter(sermon => sermon.id !== deleteModal.contentId)
        );
      } else {
        await deleteStudyFromFirestore(currentUser.uid, deleteModal.contentId);
        setStudies(prevStudies => 
          prevStudies.filter(study => study.id !== deleteModal.contentId)
        );
      }
      
      setDeleteModal({ show: false, contentId: null, type: null });
    } catch (error) {
      console.error(`Error deleting ${deleteModal.type}:`, error);
      setError(`Failed to delete ${deleteModal.type}`);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleView = (sermonId) => {
    console.log('Viewing sermon:', sermonId); // Debug log
    navigate(`/sermon/${sermonId}`);
  };

  const handleEdit = (sermonId) => {
    console.log('Editing sermon:', sermonId); // Debug log
    navigate(`/create/sermon/edit/${sermonId}`);
  };

  const EmptyState = () => (
    <div style={styles.emptyState}>
      <i className="mdi mdi-book-plus-outline" style={styles.emptyStateIcon}></i>
      <h3 style={styles.emptyStateTitle}>No Content Yet</h3>
      <p style={styles.emptyStateText}>Start by creating your first sermon</p>
      <button 
        style={{...styles.button, ...styles.buttonPrimary}}
        onClick={() => navigate('/create/sermon')}
      >
        <i className="mdi mdi-plus"></i>
        Create Sermon
      </button>
    </div>
  );

  return (
    <div style={styles.libraryContainer}>
      <div style={styles.navigationTabs}>
        <div style={styles.tabsContainer}>
          {[
            { id: 'sermons', title: 'Sermons', icon: 'mdi-cross' },
            { id: 'studies', title: 'Studies', icon: 'mdi-book-open-variant' },
            { id: 'devotionals', title: 'Devotionals', icon: 'mdi-book-heart' },
            { id: 'testimonies', title: 'Testimonies', icon: 'mdi-account-voice' },
            { id: 'myContent', title: 'My Content', icon: 'mdi-folder-account', noWrap: true }
          ].map((tab) => (
            <button
              key={tab.id}
              style={{
                ...styles.tab,
                width: tab.noWrap ? 'auto' : undefined
              }}
              onClick={() => setActiveSection(tab.id)}
            >
              <i 
                className={`mdi ${tab.icon}`} 
                style={{
                  ...styles.tabIcon,
                  ...(activeSection === tab.id && styles.tabIconActive)
                }}
              ></i>
              <span style={{
                ...styles.tabLabel,
                ...(activeSection === tab.id && styles.tabLabelActive),
                whiteSpace: tab.noWrap ? 'nowrap' : undefined
              }}>
                {tab.title}
              </span>
            </button>
          ))}
        </div>
      </div>

      {error && (
        <div style={styles.errorMessage}>
          <i className="mdi mdi-alert"></i>
          {error}
        </div>
      )}

      <div style={styles.contentGrid}>
        {loading ? (
          <div style={styles.loadingState}>
            <i className="mdi mdi-loading mdi-spin"></i>
            <span>Loading your content...</span>
          </div>
        ) : activeSection === 'sermons' ? (
          sermons.length > 0 ? (
            sermons.map((sermon) => (
              <div key={sermon.id} style={styles.card}>
                <div style={{
                  ...styles.iconBar,
                  background: `linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)`
                }}>
                  <i className="mdi mdi-cross" style={styles.feedIcon}></i>
                </div>
                {sermon.youtubeUrl && (
                  <div style={{
                    width: '100%',
                    height: '200px',
                    overflow: 'hidden',
                    position: 'relative',
                    background: '#f0f0f0'
                  }}>
                    <img 
                      src={getYouTubeThumbnail(sermon.youtubeUrl)}
                      alt={sermon.title}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
                    }}></div>
                  </div>
                )}
                <div style={styles.cardHeader}>
                  <div style={styles.contentInfo}>
                    <h3 style={styles.cardTitle}>{sermon.title || 'Untitled Sermon'}</h3>
                    <p style={styles.metaInfo}>
                      <i className="mdi mdi-clock-outline"></i>
                      {sermon.date ? new Date(sermon.date).toLocaleDateString() : 'No date'}
                    </p>
                  </div>
                  <button 
                    style={styles.buttonIcon}
                    onClick={() => handleDelete(sermon.id)}
                    title="Delete sermon"
                  >
                    <i className="mdi mdi-delete"></i>
                  </button>
                </div>
                <div style={styles.cardDescription}>
                  {sermon.description || 'No description provided'}
                </div>
                <div style={styles.cardActions}>
                  <button 
                    style={{...styles.button, ...styles.buttonPrimary}}
                    onClick={() => handleView(sermon.id)}
                  >
                    <i className="mdi mdi-play"></i>
                    View
                  </button>
                  <button 
                    style={{...styles.button, ...styles.buttonSecondary}}
                    onClick={() => handleEdit(sermon.id)}
                  >
                    <i className="mdi mdi-pencil"></i>
                    Edit
                  </button>
                </div>
              </div>
            ))
          ) : (
            <EmptyState />
          )
        ) : activeSection === 'testimonies' ? (
          <div style={styles.emptyState}>
            <i className="mdi mdi-account-voice" style={styles.emptyStateIcon}></i>
            <h3 style={styles.emptyStateTitle}>Coming Soon</h3>
            <p style={styles.emptyStateText}>
              Testimony sharing feature is coming soon!
            </p>
          </div>
        ) : activeSection === 'studies' ? (
          studies.length > 0 ? (
            studies.map((study) => (
              <div key={study.id} style={styles.card}>
                <div style={{
                  ...styles.iconBar,
                  background: `linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)`
                }}>
                  <i className="mdi mdi-book-open-variant" style={styles.feedIcon}></i>
                </div>

                <div style={styles.cardHeader}>
                  <div style={styles.contentInfo}>
                    <h3 style={styles.cardTitle}>{study.title || 'Untitled Study'}</h3>
                    <p style={styles.metaInfo}>
                      <i className="mdi mdi-clock-outline"></i>
                      {study.startDate ? new Date(study.startDate).toLocaleDateString() : 'No date'}
                      {study.frequency && (
                        <span style={{ marginLeft: '0.5rem' }}>
                          <i className="mdi mdi-repeat"></i>
                          {study.frequency}
                        </span>
                      )}
                    </p>
                  </div>
                  <button 
                    style={styles.buttonIcon}
                    onClick={() => handleDelete(study.id, 'study')}
                    title="Delete study"
                  >
                    <i className="mdi mdi-delete"></i>
                  </button>
                </div>

                <div style={styles.cardDescription}>
                  {study.description || 'No description provided'}
                  <div style={{ marginTop: '0.5rem', color: '#666666' }}>
                    <i className="mdi mdi-book-open-page-variant"></i>
                    <span style={{ marginLeft: '0.5rem' }}>{study.scripture}</span>
                  </div>
                </div>

                <div style={styles.cardActions}>
                  <button 
                    style={{...styles.button, ...styles.buttonPrimary}}
                    onClick={() => navigate(`/study/${study.id}`)}
                  >
                    <i className="mdi mdi-eye"></i>
                    View
                  </button>
                  <button 
                    style={{...styles.button, ...styles.buttonSecondary}}
                    onClick={() => navigate(`/create/biblestudy/edit/${study.id}`)}
                  >
                    <i className="mdi mdi-pencil"></i>
                    Edit
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div style={styles.emptyState}>
              <i className="mdi mdi-book-open-variant" style={styles.emptyStateIcon}></i>
              <h3 style={styles.emptyStateTitle}>No Bible Studies Yet</h3>
              <p style={styles.emptyStateText}>Start by creating your first Bible study</p>
              <button 
                style={{...styles.button, ...styles.buttonPrimary}}
                onClick={() => navigate('/create/biblestudy')}
              >
                <i className="mdi mdi-plus"></i>
                Create Bible Study
              </button>
            </div>
          )
        ) : (
          <div style={styles.emptyState}>
            <i className="mdi mdi-book-open-variant" style={styles.emptyStateIcon}></i>
            <h3 style={styles.emptyStateTitle}>No Bible Studies Yet</h3>
            <p style={styles.emptyStateText}>Start by creating your first Bible study</p>
            <button 
              style={{...styles.button, ...styles.buttonPrimary}}
              onClick={() => navigate('/create/biblestudy')}
            >
              <i className="mdi mdi-plus"></i>
              Create Bible Study
            </button>
          </div>
        )}
      </div>

      {deleteModal.show && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h3 style={styles.modalTitle}>Delete Sermon</h3>
            <p style={styles.modalText}>
              Are you sure you want to delete this sermon? This action cannot be undone.
            </p>
            <div style={styles.buttonGroup}>
              <button 
                style={{...styles.button, ...styles.buttonSecondary}}
                onClick={() => setDeleteModal({ show: false, contentId: null, type: null })}
                disabled={deleteLoading}
              >
                Cancel
              </button>
              <button 
                style={{...styles.button, ...styles.buttonDanger}}
                onClick={confirmDelete}
                disabled={deleteLoading}
              >
                {deleteLoading ? (
                  <>
                    <i className="mdi mdi-loading mdi-spin"></i>
                    Deleting...
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-delete"></i>
                    Delete
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Library;
