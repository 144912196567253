import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { login, loginWithGoogle, currentUser } = useAuth();

    useEffect(() => {
        if (currentUser) {
            navigate(location.state?.from?.pathname || '/');
        }
    }, [currentUser, navigate, location]);

    const handleEmailLogin = async (e) => {
        e.preventDefault();
        try {
            setError('');
            setLoading(true);
            await login(email, password);
            navigate(location.state?.from?.pathname || '/');
        } catch (error) {
            setError('Failed to sign in: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            setError('');
            setLoading(true);
            const result = await loginWithGoogle();
            
            if (result.additionalUserInfo?.isNewUser) {
                const googleUser = result.user;
                navigate('/profile/setup', {
                    state: {
                        displayName: googleUser.displayName,
                        email: googleUser.email,
                        imageUrl: googleUser.photoURL
                    }
                });
            } else {
                navigate(location.state?.from?.pathname || '/');
            }
        } catch (error) {
            setError('Failed to sign in with Google: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const styles = {
        loginContainer: {
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem',
            fontFamily: "'Cardo', serif",
        },
        loginContent: {
            width: '100%',
            maxWidth: '400px',
            animation: 'slideUp 0.3s ease-in-out',
        },
        loginHeader: {
            textAlign: 'center',
            marginBottom: '2rem',
        },
        loginTitle: {
            fontSize: '2.5rem',
            fontWeight: '700',
            color: '#333333',
            marginBottom: '0.5rem',
            fontFamily: "'Cardo', serif",
        },
        loginSubtitle: {
            color: '#666666',
            fontSize: '1.1rem',
        },
        loginCard: {
            background: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(10px)',
            borderRadius: '16px',
            padding: '2rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.7)',
        },
        errorMessage: {
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            padding: '1rem',
            borderRadius: '12px',
            background: 'rgba(220, 53, 69, 0.1)',
            color: '#dc3545',
            marginBottom: '1.5rem',
        },
        formGroup: {
            marginBottom: '1.5rem',
        },
        formLabel: {
            display: 'block',
            marginBottom: '0.5rem',
            color: '#333333',
            fontWeight: '500',
            fontSize: '0.95rem',
        },
        inputWrapper: {
            position: 'relative',
        },
        formInput: {
            width: '100%',
            padding: '0.75rem',
            paddingLeft: '2.5rem',
            borderRadius: '12px',
            border: '1px solid rgba(226, 232, 240, 0.8)',
            background: 'rgba(255, 255, 255, 0.9)',
            fontSize: '1rem',
            color: '#333333',
            transition: 'all 0.2s ease',
        },
        inputIcon: {
            position: 'absolute',
            left: '0.75rem',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#666666',
            fontSize: '1.2rem',
        },
        passwordToggle: {
            position: 'absolute',
            right: '0.75rem',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            color: '#666666',
            cursor: 'pointer',
            display: 'flex',
            padding: '0.25rem',
            fontSize: '1.2rem',
        },
        button: {
            width: '100%',
            padding: '0.75rem',
            borderRadius: '12px',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
            fontSize: '1rem',
            fontWeight: '500',
            transition: 'all 0.2s ease',
        },
        buttonPrimary: {
            background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
            color: 'white',
            boxShadow: '0 2px 4px rgba(255, 0, 0, 0.2)',
        },
        buttonOutline: {
            background: 'rgba(255, 255, 255, 0.9)',
            border: '1px solid rgba(226, 232, 240, 0.8)',
            color: '#333333',
        },
        divider: {
            display: 'flex',
            alignItems: 'center',
            margin: '1.5rem 0',
            gap: '1rem',
        },
        dividerLine: {
            flex: 1,
            height: '1px',
            background: 'rgba(226, 232, 240, 0.8)',
        },
        dividerContent: {
            color: '#666666',
            fontSize: '0.9rem',
        },
        loginFooter: {
            marginTop: '1.5rem',
            textAlign: 'center',
            color: '#666666',
        },
        footerLink: {
            color: '#FF0000',
            textDecoration: 'none',
            fontWeight: '500',
        }
    };

    return (
        <div style={styles.loginContainer}>
            <div style={styles.loginContent}>
                <div style={styles.loginHeader}>
                    <h1 style={styles.loginTitle}>Welcome to Sermonize AI</h1>
                    <p style={styles.loginSubtitle}>Sign in to continue to your account</p>
                </div>

                <div style={styles.loginCard}>
                    {error && (
                        <div style={styles.errorMessage}>
                            <i className="mdi mdi-alert-circle"></i>
                            {error}
                        </div>
                    )}

                    <form onSubmit={handleEmailLogin}>
                        <div style={styles.formGroup}>
                            <label style={styles.formLabel}>Email Address</label>
                            <div style={styles.inputWrapper}>
                                <i className="mdi mdi-email" style={styles.inputIcon}></i>
                                <input
                                    type="email"
                                    style={styles.formInput}
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.formLabel}>Password</label>
                            <div style={styles.inputWrapper}>
                                <i className="mdi mdi-lock" style={styles.inputIcon}></i>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    style={styles.formInput}
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <button
                                    type="button"
                                    style={styles.passwordToggle}
                                    onClick={() => setShowPassword(!showPassword)}
                                    tabIndex="-1"
                                >
                                    {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                </button>
                            </div>
                        </div>

                        <button 
                            type="submit" 
                            style={{...styles.button, ...styles.buttonPrimary}}
                            disabled={loading}
                        >
                            {loading ? (
                                <>
                                    <i className="mdi mdi-loading mdi-spin"></i>
                                    Signing in...
                                </>
                            ) : (
                                <>
                                    <i className="mdi mdi-login"></i>
                                    Sign In
                                </>
                            )}
                        </button>
                    </form>

                    <div style={styles.divider}>
                        <div style={styles.dividerLine}></div>
                        <div style={styles.dividerContent}>or</div>
                        <div style={styles.dividerLine}></div>
                    </div>

                    <button 
                        onClick={handleGoogleLogin}
                        style={{...styles.button, ...styles.buttonOutline}}
                        disabled={loading}
                    >
                        <i className="mdi mdi-google"></i>
                        Sign in with Google
                    </button>

                    <div style={styles.loginFooter}>
                        <p style={{marginBottom: '0.5rem'}}>
                            Don't have an account? <a href="#signup" style={styles.footerLink}>Sign up</a>
                        </p>
                        <p>
                            <a href="#forgot-password" style={styles.footerLink}>Forgot password?</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;