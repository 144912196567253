import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const navItems = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    to: '/'
  },
  {
    title: 'Create',
    icon: 'mdi-plus-circle',
    to: '/create'
  },
  {
    title: 'Library',
    icon: 'mdi-library',
    to: '/library'
  },
  {
    title: 'Community',
    icon: 'mdi-account-group',
    to: '/community'
  }
];

const MainNav = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [showScanner, setShowScanner] = useState(false);

  if (!currentUser) {
    return null;
  }

  console.log("Current location:", location.pathname);

  const getCurrentPageTitle = () => {
    if (location.pathname === '/') return 'Dashboard';
    const currentRoute = navItems.find(item => item.to === location.pathname);
    return currentRoute ? currentRoute.title : 'Dashboard';
  };

  const handleNavigation = (to) => {
    console.log("Attempting navigation to:", to);
    navigate(to);
    console.log("Navigation completed");
    if (window.innerWidth < 1024) {
      setDrawerOpen(false);
    }
  };

  const QRScannerModal = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000,
    }}>
      <div style={{
        background: 'white',
        borderRadius: '16px',
        padding: '2rem',
        width: '90%',
        maxWidth: '500px',
        position: 'relative',
      }}>
        <button 
          onClick={() => setShowScanner(false)}
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            background: 'none',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
            color: '#666666',
          }}
        >
          <i className="mdi mdi-close"></i>
        </button>
        <h2 style={{
          fontSize: '1.5rem',
          marginBottom: '1rem',
          fontFamily: "'Cardo', serif",
        }}>
          Scan QR Code
        </h2>
        <div style={{
          width: '100%',
          aspectRatio: '1',
          background: '#f8f9fa',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '1rem',
        }}>
          {/* QR Scanner component would go here */}
          <i className="mdi mdi-qrcode-scan" style={{ fontSize: '3rem', color: '#666666' }}></i>
        </div>
        <p style={{
          color: '#666666',
          textAlign: 'center',
        }}>
          Position the QR code within the frame to scan
        </p>
      </div>
    </div>
  );

  return (
    <>
      {/* Navigation Drawer - Dark Theme */}
      <div className={`navigation-drawer ${drawerOpen ? 'open' : ''}`}>
        <div className="drawer-header">
          <h2 className="drawer-title">Ministry Dashboard</h2>
        </div>
        <nav className="nav-list">
          {navItems.map((item) => (
            <div
              key={item.title}
              className={`nav-item ${location.pathname === item.to ? 'active' : ''}`}
              onClick={() => {
                console.log("Nav item clicked:", item.title);
                handleNavigation(item.to);
              }}
              style={{ cursor: 'pointer' }}
            >
              <i className={`mdi ${item.icon} nav-icon`}></i>
              <span>{item.title}</span>
            </div>
          ))}
        </nav>
      </div>

      {/* Updated App Bar */}
      <div style={styles.appBar}>
        <button 
          style={styles.menuButton}
          onClick={() => setDrawerOpen(!drawerOpen)}
        >
          <i className="mdi mdi-menu"></i>
        </button>
        <h1 style={styles.appTitle}>{getCurrentPageTitle()}</h1>
        <div style={styles.appBarActions}>
          <button 
            style={styles.appBarButton}
            onClick={() => setShowScanner(true)}
          >
            <i className="mdi mdi-qrcode-scan"></i>
          </button>
          <button 
            style={styles.appBarButton}
            onClick={() => handleNavigation('/profile')}
          >
            <i className="mdi mdi-account-circle"></i>
          </button>
        </div>
      </div>

      {/* Overlay for mobile */}
      {drawerOpen && (
        <div 
          className="drawer-overlay" 
          onClick={() => setDrawerOpen(false)}
        />
      )}

      {showScanner && <QRScannerModal />}
    </>
  );
};

const styles = {
  appBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: 'var(--app-bar-height)',
    background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
    backdropFilter: 'blur(10px)',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    zIndex: 1000,
    transition: 'left 0.3s ease',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  appBarActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },
  appBarButton: {
    background: 'rgba(255, 255, 255, 0.1)',
    border: 'none',
    color: 'white',
    padding: '0.75rem',
    borderRadius: '12px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease',
    fontSize: '1.5rem',
    backdropFilter: 'blur(4px)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
    },
  },
  menuButton: {
    background: 'rgba(255, 255, 255, 0.1)',
    border: 'none',
    color: 'white',
    width: '40px',
    height: '40px',
    borderRadius: '12px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease',
    fontSize: '1.5rem',
    backdropFilter: 'blur(4px)',
    marginRight: '1rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
    },
  },
  appTitle: {
    fontFamily: "'Cardo', serif",
    fontSize: '1.5rem',
    fontWeight: '700',
    margin: 0,
    flex: 1,
    textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
};

export default MainNav;
