import React, { useState } from 'react';
import EventCreator from './EventCreator';

const styles = {
  eventCard: {
    background: '#FFFFFF',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: 'var(--shadow-sm)',
    marginBottom: '1rem',
    border: '1px solid #E2E8F0',
  },
  imageContainer: {
    width: '100%',
    height: '160px',
    overflow: 'hidden',
  },
  eventImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  iconBar: {
    width: '100%',
    height: '40px',
    overflow: 'hidden',
    background: `linear-gradient(135deg, var(--primary-color) 0%, var(--primary-color)dd 100%)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  eventIcon: {
    color: 'white',
    fontSize: '1.5rem',
  },
  eventContent: {
    padding: '1rem',
  },
  eventName: {
    fontSize: '1.1rem',
    fontWeight: '600',
    color: '#333333',
    marginBottom: '0.5rem',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '0.9rem',
    color: '#666666',
  },
  detailIcon: {
    fontSize: '1.1rem',
    color: '#333333',
  },
  actionButtons: {
    display: 'flex',
    gap: '0.5rem',
    marginTop: '1rem',
    padding: '0.5rem',
    borderTop: '1px solid #E2E8F0',
    background: 'rgba(247, 250, 252, 0.8)',
    backdropFilter: 'blur(4px)',
  },
  button: {
    flex: 1,
    padding: '0.75rem',
    borderRadius: '12px',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    fontSize: '0.95rem',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  primaryButton: {
    background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
    color: 'white',
    backdropFilter: 'blur(4px)',
    '&:hover': {
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
    },
  },
  secondaryButton: {
    background: 'rgba(241, 245, 249, 0.8)',
    color: '#666666',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      background: 'rgba(226, 232, 240, 0.9)',
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
  },
};

const Events = () => {
  const [events, setEvents] = useState([
    {
      id: 1,
      title: 'Sunday Service',
      date: '2024-03-24',
      time: '10:00 AM',
      location: 'Main Sanctuary',
      description: 'Weekly worship service with special guest speaker',
      attendees: 45,
      type: 'service',
      color: '#FF416C',
      image: "https://picsum.photos/400/200?random=7"
    },
    {
      id: 2,
      title: 'Bible Study Workshop',
      date: '2024-03-26',
      time: '7:00 PM',
      location: 'Fellowship Hall',
      description: 'Learn effective Bible study methods and techniques',
      attendees: 28,
      type: 'study',
      color: '#FF4B2B',
      image: "https://picsum.photos/400/200?random=8"
    },
    {
      id: 3,
      title: 'Prayer Meeting',
      date: '2024-03-28',
      time: '6:30 PM',
      location: 'Prayer Room',
      description: 'Community prayer gathering',
      attendees: 15,
      type: 'prayer',
      color: '#4CAF50',
      image: "https://picsum.photos/400/200?random=9"
    }
  ]);

  return (
    <>
      {events.map((event) => (
        <div key={event.id} style={styles.eventCard}>
          <div style={{
            ...styles.iconBar,
            background: `linear-gradient(135deg, ${event.color} 0%, ${event.color}dd 100%)`
          }}>
            <i 
              className={`mdi ${
                event.type === 'service' ? 'mdi-church' :
                event.type === 'study' ? 'mdi-book-open-variant' :
                'mdi-hands-pray'
              }`} 
              style={styles.eventIcon}
            ></i>
          </div>
          <div style={styles.imageContainer}>
            <img src={event.image} alt="" style={styles.eventImage} />
          </div>
          <div style={styles.eventContent}>
            <h3 style={styles.eventName}>{event.title}</h3>
            <div style={styles.detailsContainer}>
              <div style={styles.detail}>
                <i className="mdi mdi-calendar" style={styles.detailIcon}></i>
                <span>{new Date(event.date).toLocaleDateString()}</span>
              </div>
              <div style={styles.detail}>
                <i className="mdi mdi-clock-outline" style={styles.detailIcon}></i>
                <span>{event.time}</span>
              </div>
              <div style={styles.detail}>
                <i className="mdi mdi-map-marker" style={styles.detailIcon}></i>
                <span>{event.location}</span>
              </div>
              <div style={styles.detail}>
                <i className="mdi mdi-account-group" style={styles.detailIcon}></i>
                <span>{event.attendees} attending</span>
              </div>
            </div>
            <div style={styles.actionButtons}>
              <button style={{...styles.button, ...styles.primaryButton}}>
                <i className="mdi mdi-account-plus"></i>
                Attend
              </button>
              <button style={{...styles.button, ...styles.secondaryButton}}>
                <i className="mdi mdi-share-variant"></i>
                Share
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Events;