import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getStudyFromFirestore } from '../control/firebase';
import ReactMarkdown from 'react-markdown';

const StudyViewer = () => {
  const { studyId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [study, setStudy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchStudy = async () => {
      if (!currentUser || !studyId) return;
      
      try {
        setLoading(true);
        const studyData = await getStudyFromFirestore(currentUser.uid, studyId);
        setStudy(studyData);
      } catch (err) {
        console.error('Error fetching study:', err);
        setError('Failed to load study');
      } finally {
        setLoading(false);
      }
    };

    fetchStudy();
  }, [currentUser, studyId]);

  const styles = {
    container: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '-10px',
    },
    backButton: {
      background: 'rgba(255, 255, 255, 0.9)',
      border: 'none',
      padding: '0.5rem',
      borderRadius: '12px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1.2rem',
      color: '#4A5568',
      marginBottom: '1rem',
      transition: 'all 0.2s ease',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      },
    },
    card: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      marginBottom: '1rem',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      width: '100%',
    },
    iconBar: {
      width: '100%',
      height: '48px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      padding: '0',
      lineHeight: '1',
    },
    feedIcon: {
      color: 'white',
      fontSize: '1.5rem',
      filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      marginTop: '1px',
    },
    header: {
      padding: '1.5rem',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: '600',
      color: '#2D3748',
      marginBottom: '0.5rem',
      fontFamily: "'Cardo', serif",
    },
    meta: {
      display: 'flex',
      gap: '1rem',
      color: '#4A5568',
      fontSize: '0.9rem',
    },
    tabs: {
      display: 'flex',
      padding: '0.5rem',
      gap: '0.5rem',
      background: 'rgba(247, 250, 252, 0.8)',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
    },
    tab: {
      flex: 1,
      padding: '0.75rem',
      border: 'none',
      borderRadius: '12px',
      background: 'transparent',
      color: '#4A5568',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.95rem',
      fontWeight: '500',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.9)',
      },
    },
    activeTab: {
      background: 'rgba(255, 0, 0, 0.1)',
      color: 'var(--primary-color)',
    },
    content: {
      padding: '1.5rem',
      color: '#2D3748',
      fontSize: '1rem',
      lineHeight: '1.6',
      background: 'rgba(255, 255, 255, 0.2)',
    },
    sessionCard: {
      background: 'rgba(255, 255, 255, 0.8)',
      borderRadius: '12px',
      padding: '1.5rem',
      marginBottom: '1rem',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    sessionTitle: {
      fontSize: '1.2rem',
      fontWeight: '600',
      color: '#2D3748',
      marginBottom: '1rem',
      fontFamily: "'Cardo', serif",
    },
    questionList: {
      listStyle: 'none',
      padding: '0',
      margin: '0',
    },
    question: {
      marginBottom: '1rem',
      padding: '1rem',
      background: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '8px',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      wordWrap: 'break-word',
    },
    activityList: {
      listStyle: 'none',
      padding: '0',
      margin: '0',
    },
    activity: {
      marginBottom: '1rem',
      padding: '1rem',
      background: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '8px',
      border: '1px solid rgba(226, 232, 240, 0.8)',
      wordWrap: 'break-word',
    },
    mediaSection: {
      marginTop: '1rem',
    },
    mediaItem: {
      marginBottom: '1rem',
    },
    mediaTitle: {
      fontSize: '1rem',
      fontWeight: '600',
      marginBottom: '0.5rem',
    },
    mediaDescription: {
      fontSize: '0.9rem',
      color: '#4A5568',
      marginBottom: '0.5rem',
    },
    errorMessage: {
      background: 'rgba(220, 53, 69, 0.1)',
      color: '#dc3545',
      padding: '1rem',
      borderRadius: '12px',
      marginBottom: '1.5rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    loadingState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      padding: '3rem',
      color: '#4A5568',
      fontSize: '1.1rem',
    },
  };

  if (loading) {
    return (
      <div style={styles.container}>
        <div style={styles.loadingState}>
          <i className="mdi mdi-loading mdi-spin"></i>
          <span>Loading study...</span>
        </div>
      </div>
    );
  }

  if (error || !study) {
    return (
      <div style={styles.container}>
        <div style={styles.errorMessage}>
          <i className="mdi mdi-alert"></i>
          <span>{error || 'Unable to load study'}</span>
        </div>
        <button 
          style={styles.backButton}
          onClick={() => navigate('/library')}
        >
          Back to Library
        </button>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <button 
        style={styles.backButton}
        onClick={() => navigate('/library')}
      >
        <i className="mdi mdi-arrow-left"></i>
      </button>

      <div style={styles.card}>
        <div style={styles.iconBar}>
          <i className="mdi mdi-book-open-variant" style={styles.feedIcon}></i>
        </div>

        <div style={styles.header}>
          <h1 style={styles.title}>{study.title}</h1>
          <div style={styles.meta}>
            <span>Starts: {new Date(study.startDate).toLocaleDateString()}</span>
            {study.endDate && (
              <span>Ends: {new Date(study.endDate).toLocaleDateString()}</span>
            )}
            <span>{study.frequency}</span>
          </div>
        </div>

        <div style={styles.tabs}>
          {['overview', 'sessions', 'study'].map((tab) => (
            <button
              key={tab}
              style={{
                ...styles.tab,
                ...(activeTab === tab ? styles.activeTab : {})
              }}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        <div style={styles.content}>
          {activeTab === 'overview' && (
            <>
              <h2>Description</h2>
              <p>{study.description}</p>
              
              <h2>Scripture Reference</h2>
              <p>{study.scripture}</p>
              
              <h2>Objectives</h2>
              <p>{study.objectives}</p>
            </>
          )}

          {activeTab === 'sessions' && (
            <>
              {study.sessions.map((session, index) => (
                <div key={index} style={styles.sessionCard}>
                  <h3 style={styles.sessionTitle}>{session.title}</h3>
                  
                  <h4>Scripture</h4>
                  <p>{session.scripture}</p>
                  
                  <h4>Discussion Questions</h4>
                  <ul style={styles.questionList}>
                    {session.discussionQuestions?.map((question, qIndex) => (
                      <li key={qIndex} style={styles.question}>{question}</li>
                    ))}
                  </ul>
                  
                  <h4>Activities</h4>
                  <ul style={styles.activityList}>
                    {session.activities?.map((activity, aIndex) => (
                      <li key={aIndex} style={styles.activity}>{activity}</li>
                    ))}
                  </ul>

                  {session.media?.length > 0 && (
                    <div style={styles.mediaSection}>
                      <h4>Media Resources</h4>
                      {session.media.map((mediaItem, mIndex) => (
                        <div key={mIndex} style={styles.mediaItem}>
                          <h5 style={styles.mediaTitle}>{mediaItem.title}</h5>
                          <p style={styles.mediaDescription}>{mediaItem.description}</p>
                          {mediaItem.type === 'youtube' && (
                            <iframe
                              width="100%"
                              height="315"
                              src={getEmbedUrl(mediaItem.url, mediaItem.type)}
                              title={mediaItem.title}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </>
          )}

          {activeTab === 'study' && study.generatedContent && (
            <div className="generated-content">
              <ReactMarkdown>
                {study.generatedContent.markdown}
              </ReactMarkdown>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getEmbedUrl = (url, type) => {
  try {
    if (type === 'youtube') {
      const videoId = url.includes('youtu.be/') 
        ? url.split('youtu.be/')[1].split('?')[0]
        : url.split('v=')[1].split('&')[0];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (type === 'vimeo') {
      const videoId = url.split('vimeo.com/')[1].split('?')[0];
      return `https://player.vimeo.com/video/${videoId}`;
    }
  } catch (err) {
    console.error('Error getting embed URL:', err);
  }
  return '';
};

export default StudyViewer; 