import React from 'react';

const Groups = () => {
  const groups = [
    {
      id: 1,
      name: 'Sermon Preparation',
      members: 45,
      icon: 'mdi-cross',
      color: '#FF416C',
      description: 'Collaborate on sermon preparation and share resources',
      image: "https://picsum.photos/400/200?random=10"
    },
    {
      id: 2,
      name: 'Bible Study Leaders',
      members: 32,
      icon: 'mdi-book-open-variant',
      color: '#4CAF50',
      description: 'Connect with other Bible study leaders',
      image: "https://picsum.photos/400/200?random=11"
    },
    {
      id: 3,
      name: 'Prayer Warriors',
      members: 78,
      icon: 'mdi-hands-pray',
      color: '#FF4B2B',
      description: 'Join together in prayer and intercession',
      image: "https://picsum.photos/400/200?random=12"
    }
  ];

  const styles = {
    groupCard: {
      background: '#FFFFFF',
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: 'var(--shadow-sm)',
      marginBottom: '1rem',
      border: '1px solid #E2E8F0',
    },
    iconBar: {
      width: '100%',
      height: '40px',
      overflow: 'hidden',
      background: `linear-gradient(135deg, var(--primary-color) 0%, var(--primary-color)dd 100%)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    groupIcon: {
      color: 'white',
      fontSize: '1.5rem',
    },
    imageContainer: {
      width: '100%',
      height: '160px',
      overflow: 'hidden',
    },
    groupImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    groupInfo: {
      padding: '1rem',
    },
    groupName: {
      fontSize: '1.1rem',
      fontWeight: '600',
      color: '#333333',
      marginBottom: '0.5rem',
    },
    memberCount: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      color: '#666666',
      fontSize: '0.9rem',
      marginBottom: '1rem',
    },
    description: {
      color: '#333333',
      fontSize: '0.9rem',
      marginBottom: '1rem',
      lineHeight: '1.5',
    },
    actionButtons: {
      display: 'flex',
      gap: '0.5rem',
      marginTop: '1rem',
      padding: '0.5rem',
      borderTop: '1px solid #E2E8F0',
    },
    button: {
      flex: 1,
      padding: '0.75rem',
      borderRadius: '12px',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      fontSize: '0.95rem',
      fontWeight: '500',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    primaryButton: {
      background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
      color: 'white',
      backdropFilter: 'blur(4px)',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
      },
    },
  };

  return (
    <>
      {groups.map((group) => (
        <div key={group.id} style={styles.groupCard}>
          <div style={{
            ...styles.iconBar,
            background: `linear-gradient(135deg, ${group.color} 0%, ${group.color}dd 100%)`
          }}>
            <i className={`mdi ${group.icon}`} style={styles.groupIcon}></i>
          </div>
          <div style={styles.imageContainer}>
            <img src={group.image} alt="" style={styles.groupImage} />
          </div>
          <div style={styles.groupInfo}>
            <h3 style={styles.groupName}>{group.name}</h3>
            <div style={styles.memberCount}>
              <i className="mdi mdi-account-group" style={{ fontSize: '1.1rem' }}></i>
              <span>{group.members} members</span>
            </div>
            <p style={styles.description}>{group.description}</p>
            <div style={styles.actionButtons}>
              <button style={{...styles.button, ...styles.primaryButton}}>
                <i className="mdi mdi-account-plus"></i>
                Join Group
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Groups; 