import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { saveSermonToFirestore } from '../../../control/firebase';
import FinalReview from './FinalReview';

const styles = {
  container: {
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '-10px',
  },
  card: {
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
    marginBottom: '1rem',
    border: '1px solid rgba(255, 255, 255, 0.7)',
    width: '100%',
  },
  iconBar: {
    width: '100%',
    height: '48px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '0',
    lineHeight: '1',
  },
  feedIcon: {
    color: 'white',
    fontSize: '1.5rem',
    filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: '1px',
  },
  header: {
    padding: '1.5rem',
    borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
    background: 'rgba(255, 255, 255, 0.3)',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: '#2D3748',
    marginBottom: '0.5rem',
    fontFamily: "'Cardo', serif",
  },
  content: {
    padding: '1.5rem',
    background: 'rgba(255, 255, 255, 0.2)',
  },
  progressSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    marginBottom: '2rem',
  },
  step: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
  },
  stepIndicator: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.9)',
    color: '#4A5568',
    fontSize: '0.9rem',
    fontWeight: '600',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  stepIndicatorActive: {
    background: 'rgba(255, 0, 0, 0.8)',
    color: 'white',
  },
  stepIndicatorCompleted: {
    background: '#28a745',
    color: 'white',
  },
  stepLabel: {
    fontSize: '0.8rem',
    color: '#4A5568',
    fontWeight: '500',
  },
  formGroup: {
    marginBottom: '1.5rem',
  },
  label: {
    display: 'block',
    marginBottom: '0.5rem',
    fontWeight: '500',
    color: '#2D3748',
  },
  input: {
    width: '100%',
    padding: '0.75rem',
    borderRadius: '12px',
    border: '1px solid rgba(226, 232, 240, 0.8)',
    background: 'rgba(255, 255, 255, 0.9)',
    fontSize: '1rem',
    color: '#2D3748',
    transition: 'all 0.2s ease',
    '&:focus': {
      outline: 'none',
      borderColor: 'var(--primary-color)',
      boxShadow: '0 0 0 3px rgba(255, 0, 0, 0.1)',
    },
  },
  textarea: {
    width: '100%',
    padding: '0.75rem',
    borderRadius: '12px',
    border: '1px solid rgba(226, 232, 240, 0.8)',
    background: 'rgba(255, 255, 255, 0.9)',
    fontSize: '1rem',
    color: '#2D3748',
    minHeight: '120px',
    resize: 'vertical',
    transition: 'all 0.2s ease',
    '&:focus': {
      outline: 'none',
      borderColor: 'var(--primary-color)',
      boxShadow: '0 0 0 3px rgba(255, 0, 0, 0.1)',
    },
  },
  buttonGroup: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1.5rem',
  },
  button: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    padding: '0.75rem',
    border: 'none',
    borderRadius: '12px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    fontSize: '0.95rem',
    fontWeight: '500',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  buttonPrimary: {
    background: 'rgba(255, 0, 0, 0.8)',
    color: 'white',
    boxShadow: '0 2px 4px rgba(255, 0, 0, 0.2)',
    '&:hover': {
      background: 'rgba(255, 0, 0, 0.9)',
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(255, 0, 0, 0.3)',
    },
  },
  buttonSecondary: {
    background: 'rgba(241, 245, 249, 0.8)',
    color: '#666666',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      background: 'rgba(226, 232, 240, 0.9)',
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
  },
  errorMessage: {
    background: 'rgba(220, 53, 69, 0.1)',
    color: '#dc3545',
    padding: '1rem',
    borderRadius: '12px',
    marginBottom: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    backdropFilter: 'blur(4px)',
  },
  inputMethodsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '1.5rem',
    marginBottom: '2rem',
  },
  inputMethodCard: {
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(10px)',
    borderRadius: '12px',
    padding: '1.5rem',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    border: '1px solid rgba(255, 255, 255, 0.7)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 8px 12px rgba(0, 0, 0, 0.1)',
    },
  },
  iconContainer: {
    width: '48px',
    height: '48px',
    borderRadius: '12px',
    background: 'rgba(255, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 1rem',
    fontSize: '1.5rem',
    color: 'var(--primary-color)',
  },
};

const SermonCreator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const [currentStep, setCurrentStep] = useState(1);
  
  // Get today's date in YYYY-MM-DD format in local timezone
  const today = new Date().toLocaleDateString('en-CA');
  
  const [sermonData, setSermonData] = useState({
    title: '',
    description: '',
    date: today,
    scripture: '',
    notes: '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });
  
  const [processedContent, setProcessedContent] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Add effect to handle incoming processed content
  useEffect(() => {
    if (location.state?.step === 3 && location.state?.processedContent) {
      setCurrentStep(3);
      const incomingContent = location.state.processedContent;
      console.log('Incoming processed content:', incomingContent);
      
      // Update sermon data with incoming content
      setProcessedContent({
        ...incomingContent,
        title: sermonData.title,        // Use the title from our form
        description: sermonData.description,  // Use the description from our form
        scripture: sermonData.scripture,      // Use the scripture from our form
        date: sermonData.date
      });
    }
  }, [location.state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSermonData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNext = async (e) => {
    e.preventDefault();
    
    try {
      setError('');
      setLoading(true);
      
      // Validate required fields
      if (!sermonData.title.trim()) {
        throw new Error('Title is required');
      }

      // If moving from step 1 to step 2, create the sermon in Firebase
      if (currentStep === 1) {
        // Create initial sermon data
        const initialSermonData = {
          title: sermonData.title.trim(),
          description: sermonData.description.trim(),
          scripture: sermonData.scripture.trim(),
          date: sermonData.date,
          notes: sermonData.notes || '',
          status: 'draft',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          createdBy: currentUser.uid,
          lastModifiedBy: currentUser.uid,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          sourceType: 'pending',
          isPublished: false
        };

        console.log('Creating new sermon with data:', initialSermonData);
        
        // Save to Firestore and get the new ID
        const docId = await saveSermonToFirestore(currentUser.uid, initialSermonData);
        console.log('Created sermon with ID:', docId);
        
        // Update local state with the new ID
        setSermonData(prev => ({
          ...prev,
          id: docId
        }));

        setCurrentStep(2);
        setLoading(false);
        return;
      }
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleInputSelect = (inputType) => {
    // Get the sermon ID from state
    const sermonId = sermonData.id;
    
    if (!sermonId) {
      console.error('No sermon ID available');
      setError('No sermon ID available');
      return;
    }

    console.log('Navigating to input type with sermon ID:', sermonId);

    switch(inputType) {
      case 'youtube':
        navigate(`/create/sermon/youtube/${sermonId}`);
        break;
      case 'audio':
        navigate(`/create/sermon/upload/${sermonId}`);
        break;
      case 'record':
        navigate(`/create/sermon/record/${sermonId}`);
        break;
      default:
        setError('Invalid input type selected');
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.iconBar}>
          <i className="mdi mdi-cross" style={styles.feedIcon}></i>
        </div>

        <div style={styles.header}>
          <div style={styles.progressSteps}>
            {[1, 2, 3].map((step) => (
              <div key={step} style={styles.step}>
                <div style={{
                  ...styles.stepIndicator,
                  ...(currentStep === step && styles.stepIndicatorActive),
                  ...(currentStep > step && styles.stepIndicatorCompleted),
                }}>
                  {currentStep > step ? (
                    <i className="mdi mdi-check"></i>
                  ) : (
                    step
                  )}
                </div>
                <span style={styles.stepLabel}>
                  {step === 1 ? 'Details' : step === 2 ? 'Input' : 'Review'}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div style={styles.content}>
          {currentStep === 1 && (
            <form onSubmit={handleNext}>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="title">Sermon Title</label>
                <input
                  style={styles.input}
                  type="text"
                  id="title"
                  name="title"
                  value={sermonData.title}
                  onChange={handleInputChange}
                  placeholder="Enter sermon title"
                  required
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="description">Description</label>
                <textarea
                  style={styles.textarea}
                  id="description"
                  name="description"
                  value={sermonData.description}
                  onChange={handleInputChange}
                  placeholder="Enter sermon description"
                  rows="4"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="scripture">Scripture Reference</label>
                <input
                  style={styles.input}
                  type="text"
                  id="scripture"
                  name="scripture"
                  value={sermonData.scripture}
                  onChange={handleInputChange}
                  placeholder="e.g., John 3:16"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="date">Sermon Date</label>
                <input
                  style={styles.input}
                  type="date"
                  id="date"
                  name="date"
                  value={sermonData.date}
                  onChange={handleInputChange}
                  min={today}
                />
              </div>

              <div style={styles.buttonGroup}>
                <button 
                  type="button" 
                  style={{...styles.button, ...styles.buttonSecondary}}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  style={{...styles.button, ...styles.buttonPrimary}}
                  disabled={loading}
                >
                  {loading ? 'Saving...' : 'Next'}
                </button>
              </div>
            </form>
          )}

          {currentStep === 2 && (
            <div style={styles.inputMethodsGrid}>
              <div 
                style={styles.inputMethodCard}
                onClick={() => handleInputSelect('youtube')}
              >
                <div style={styles.iconContainer}>
                  <i className="mdi mdi-youtube"></i>
                </div>
                <h3>YouTube Import</h3>
                <p>Import from an existing YouTube video</p>
              </div>

              <div 
                style={styles.inputMethodCard}
                onClick={() => handleInputSelect('audio')}
              >
                <div style={styles.iconContainer}>
                  <i className="mdi mdi-file-upload"></i>
                </div>
                <h3>Upload Audio</h3>
                <p>Upload an audio file from your device</p>
              </div>

              <div 
                style={styles.inputMethodCard}
                onClick={() => handleInputSelect('record')}
              >
                <div style={styles.iconContainer}>
                  <i className="mdi mdi-microphone"></i>
                </div>
                <h3>Record Audio</h3>
                <p>Record audio directly in your browser</p>
              </div>
            </div>
          )}

          {currentStep === 3 && (
            <div style={styles.creationStep}>
              <h2 style={styles.title}>Review Generated Content</h2>
              {error && <div style={styles.errorMessage}>{error}</div>}
              <FinalReview 
                processedContent={{
                  ...processedContent,
                  title: sermonData.title,
                  description: sermonData.description,
                  scripture: sermonData.scripture,
                  date: sermonData.date
                }} 
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SermonCreator;