import React from 'react';

const PrayerRequests = () => {
  const prayerRequests = [
    {
      id: 1,
      author: {
        name: "Sarah M.",
        avatar: "https://picsum.photos/40/40?random=7",
        role: "Church Member"
      },
      request: "Please pray for my mother's recovery from surgery",
      prayerCount: 24,
      time: "2 hours ago",
      image: "https://picsum.photos/600/300?random=4",
      tags: ["health", "family", "healing"],
      color: '#FF416C'
    },
    {
      id: 2,
      author: {
        name: "John D.",
        avatar: "https://picsum.photos/40/40?random=8",
        role: "Youth Leader"
      },
      request: "Seeking prayers for guidance in a career decision",
      prayerCount: 15,
      time: "5 hours ago",
      image: "https://picsum.photos/600/300?random=5",
      tags: ["guidance", "career", "wisdom"],
      color: '#4CAF50'
    },
    {
      id: 3,
      author: {
        name: "Mary W.",
        avatar: "https://picsum.photos/40/40?random=9",
        role: "Prayer Team"
      },
      request: "Prayer needed for my son's college applications",
      prayerCount: 32,
      time: "1 day ago",
      image: "https://picsum.photos/600/300?random=6",
      tags: ["education", "youth", "future"],
      color: '#FF4B2B'
    }
  ];

  const styles = {
    feedCard: {
      background: '#FFFFFF',
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: 'var(--shadow-sm)',
      marginBottom: '1rem',
      border: '1px solid #E2E8F0',
    },
    iconBar: {
      width: '100%',
      height: '40px',
      overflow: 'hidden',
      background: `linear-gradient(135deg, var(--primary-color) 0%, var(--primary-color)dd 100%)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    prayerIcon: {
      color: 'white',
      fontSize: '1.5rem',
    },
    imageContainer: {
      width: '100%',
      height: '160px',
      overflow: 'hidden',
    },
    prayerImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    postHeader: {
      display: 'flex',
      gap: '1rem',
      padding: '1rem',
      borderBottom: '1px solid #E2E8F0',
    },
    avatar: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      objectFit: 'cover',
    },
    postMeta: {
      flex: 1,
    },
    authorInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    authorName: {
      fontSize: '1.1rem',
      fontWeight: '600',
      margin: 0,
      color: '#333333',
    },
    role: {
      color: '#666666',
      fontSize: '0.9rem',
    },
    time: {
      color: '#666666',
      fontSize: '0.875rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.25rem',
    },
    postContent: {
      padding: '1rem',
      color: '#333333',
    },
    postStats: {
      display: 'flex',
      gap: '1rem',
      color: '#666666',
      fontSize: '0.875rem',
      padding: '0.5rem 1rem',
      borderTop: '1px solid #E2E8F0',
      background: '#F8FAFC',
    },
    feedActions: {
      display: 'flex',
      padding: '0.5rem',
      borderTop: '1px solid #E2E8F0',
      gap: '0.5rem',
    },
    actionButton: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      padding: '0.5rem',
      border: 'none',
      borderRadius: '6px',
      background: 'transparent',
      color: '#666666',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.9rem',
      '&:hover': {
        background: '#F7FAFC',
      },
    },
    tag: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.25rem',
      color: '#666666',
      fontSize: '0.9rem',
    },
  };

  return (
    <>
      {prayerRequests.map(prayer => (
        <div key={prayer.id} style={styles.feedCard}>
          <div style={{
            ...styles.iconBar,
            background: `linear-gradient(135deg, ${prayer.color} 0%, ${prayer.color}dd 100%)`
          }}>
            <i className="mdi mdi-hands-pray" style={styles.prayerIcon}></i>
          </div>
          <div style={styles.imageContainer}>
            <img src={prayer.image} alt="" style={styles.prayerImage} />
          </div>
          <div style={styles.postHeader}>
            <img 
              src={prayer.author.avatar} 
              alt="" 
              style={styles.avatar}
            />
            <div style={styles.postMeta}>
              <div style={styles.authorInfo}>
                <h3 style={styles.authorName}>{prayer.author.name}</h3>
                <span style={styles.role}>{prayer.author.role}</span>
              </div>
              <span style={styles.time}>
                <i className="mdi mdi-clock-outline"></i>
                {prayer.time}
              </span>
            </div>
          </div>

          <div style={styles.postContent}>
            <p>{prayer.request}</p>
          </div>

          <div style={styles.postStats}>
            <span>{prayer.prayerCount} prayers</span>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              {prayer.tags.map((tag, index) => (
                <span key={index} style={styles.tag}>
                  <i className="mdi mdi-tag"></i>
                  {tag}
                </span>
              ))}
            </div>
          </div>

          <div style={styles.feedActions}>
            <button style={styles.actionButton}>
              <i className="mdi mdi-hands-pray"></i>
              Pray
            </button>
            <button style={styles.actionButton}>
              <i className="mdi mdi-comment-outline"></i>
              Comment
            </button>
            <button style={styles.actionButton}>
              <i className="mdi mdi-share-outline"></i>
              Share
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default PrayerRequests; 