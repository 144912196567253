import React, { useState } from 'react';

const styles = {
  feedCard: {
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
    marginBottom: '1rem',
    border: '1px solid rgba(255, 255, 255, 0.7)',
    width: '100%',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
    },
  },
  iconBar: {
    width: '100%',
    height: '48px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 100%)`,
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '0',
    lineHeight: '1',
  },
  feedIcon: {
    color: 'white',
    fontSize: '1.5rem',
    filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: '1px',
  },
  imageContainer: {
    width: '100%',
    height: '160px',
    overflow: 'hidden',
  },
  mediaImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  postHeader: {
    display: 'flex',
    gap: '1rem',
    padding: '1.5rem 1.5rem 1rem',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    background: 'rgba(255, 255, 255, 0.3)',
  },
  avatar: {
    width: '48px',
    height: '48px',
    borderRadius: '12px',
    objectFit: 'cover',
    border: '2px solid rgba(255, 255, 255, 0.8)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  postMeta: {
    flex: 1,
  },
  authorInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  authorName: {
    fontSize: '1.1rem',
    fontWeight: '600',
    margin: 0,
    color: '#2D3748',
    fontFamily: "'Cardo', serif",
  },
  role: {
    color: '#4A5568',
    fontSize: '0.9rem',
  },
  time: {
    color: '#4A5568',
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  postContent: {
    padding: '1.5rem',
    color: '#2D3748',
    fontSize: '1rem',
    lineHeight: '1.6',
    background: 'rgba(255, 255, 255, 0.2)',
  },
  postStats: {
    display: 'flex',
    gap: '1rem',
    color: '#4A5568',
    fontSize: '0.875rem',
    padding: '0.75rem 1.5rem',
    borderTop: '1px solid rgba(226, 232, 240, 0.8)',
    background: 'rgba(247, 250, 252, 0.5)',
  },
  feedActions: {
    display: 'flex',
    padding: '0.75rem',
    gap: '0.5rem',
    background: 'rgba(247, 250, 252, 0.8)',
    backdropFilter: 'blur(4px)',
  },
  actionButton: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    padding: '0.75rem',
    border: 'none',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 0.9)',
    color: '#2D3748',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    fontSize: '0.95rem',
    fontWeight: '500',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      background: 'white',
    },
  },
  activeButton: {
    color: 'var(--primary-color)',
    background: 'rgba(255, 0, 0, 0.1)',
  },
};

const NewsFeed = () => {
  const [posts, setPosts] = useState([
    {
      id: 1,
      author: {
        name: 'Pastor John',
        avatar: 'https://picsum.photos/40/40?random=1',
        role: 'Lead Pastor'
      },
      content: 'Just finished preparing this week\'s sermon on faith and perseverance. Looking forward to sharing it with everyone on Sunday!',
      time: '2 hours ago',
      likes: 12,
      comments: [
        {
          id: 1,
          author: 'Mary Smith',
          content: 'Can\'t wait to hear it!',
          time: '1 hour ago'
        }
      ],
      media: {
        type: 'image',
        url: 'https://picsum.photos/600/300?random=2'
      },
      type: 'sermon-update',
      color: '#FF416C'
    },
    {
      id: 2,
      author: {
        name: 'Ministry Team',
        avatar: 'https://picsum.photos/40/40?random=3',
        role: 'Admin'
      },
      content: 'Join us this Sunday for a special worship service! We\'ll be having guest worship leaders and a powerful message.',
      time: '5 hours ago',
      likes: 24,
      comments: [],
      media: {
        type: 'image',
        url: 'https://picsum.photos/600/300?random=4'
      },
      type: 'announcement',
      color: '#4CAF50'
    }
  ]);

  const handleLike = (postId) => {
    setPosts(posts.map(post => 
      post.id === postId 
        ? { ...post, likes: post.likes + 1 }
        : post
    ));
  };

  return (
    <>
      {posts.map((post) => (
        <div key={post.id} style={styles.feedCard}>
          <div 
            style={{
              ...styles.iconBar,
              background: `linear-gradient(135deg, ${post.color}dd 0%, ${post.color}99 100%)`
            }}
          >
            <i 
              className={`mdi ${post.type === 'sermon-update' ? 'mdi-cross' : 'mdi-newspaper'}`} 
              style={styles.feedIcon}
            ></i>
          </div>
          {post.media && (
            <div style={styles.imageContainer}>
              <img src={post.media.url} alt="" style={styles.mediaImage} />
            </div>
          )}
          <div style={styles.postHeader}>
            <img 
              src={post.author.avatar} 
              alt="" 
              style={styles.avatar}
            />
            <div style={styles.postMeta}>
              <div style={styles.authorInfo}>
                <h3 style={styles.authorName}>{post.author.name}</h3>
                <span style={styles.role}>{post.author.role}</span>
              </div>
              <span style={styles.time}>
                <i className="mdi mdi-clock-outline"></i>
                {post.time}
              </span>
            </div>
          </div>

          <div style={styles.postContent}>
            <p>{post.content}</p>
          </div>

          <div style={styles.postStats}>
            <span>{post.likes} likes</span>
            <span>{post.comments.length} comments</span>
          </div>

          <div style={styles.feedActions}>
            <button 
              style={{
                ...styles.actionButton,
                ...(post.liked ? styles.activeButton : {})
              }}
              onClick={() => handleLike(post.id)}
            >
              <i className={`mdi ${post.liked ? 'mdi-heart' : 'mdi-heart-outline'}`}></i>
              Like
            </button>
            <button style={styles.actionButton}>
              <i className="mdi mdi-comment-outline"></i>
              Comment
            </button>
            <button style={styles.actionButton}>
              <i className="mdi mdi-share-outline"></i>
              Share
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default NewsFeed;