import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
    onAuthStateChanged, 
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    sendEmailVerification,
    signInWithPopup
} from 'firebase/auth';
import { auth, db, googleProvider } from '../config/firebaseInitializer';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [isFirstSignIn, setIsFirstSignIn] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const signup = async (email, password) => {
        try {
            const result = await createUserWithEmailAndPassword(auth, email, password);
            setIsFirstSignIn(true);
            return result;
        } catch (error) {
            throw error;
        }
    };

    const login = async (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    const loginWithGoogle = async () => {
        return signInWithPopup(auth, googleProvider);
    };

    const logout = async () => {
        return signOut(auth);
    };

    const resetPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email);
            return true;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const resendVerificationEmail = async () => {
        if (currentUser && !currentUser.emailVerified) {
            try {
                await sendEmailVerification(currentUser);
                return true;
            } catch (error) {
                throw new Error(error.message);
            }
        }
    };

    const value = {
        currentUser,
        signup,
        login,
        loginWithGoogle,
        logout,
        resetPassword,
        resendVerificationEmail,
        loading,
        isFirstSignIn,
        setIsFirstSignIn
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
} 