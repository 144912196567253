import React from 'react';
import { useNavigate } from 'react-router-dom';

const Create = () => {
  const navigate = useNavigate();

  const styles = {
    createContainer: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '-10px',
    },
    contentGrid: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      padding: '1rem',
    },
    card: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      marginBottom: '0.5rem',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      width: '100%',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
      },
    },
    iconBar: {
      width: '100%',
      height: '48px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 100%)`,
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      padding: '0',
      lineHeight: '1',
    },
    feedIcon: {
      color: 'white',
      fontSize: '1.5rem',
      filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      marginTop: '1px',
    },
    cardHeader: {
      display: 'flex',
      gap: '1rem',
      padding: '1.5rem 1.5rem 1rem',
      alignItems: 'flex-start',
    },
    contentInfo: {
      flex: 1,
    },
    cardTitle: {
      fontSize: '1.4rem',
      fontWeight: '600',
      margin: 0,
      color: '#2D3748',
      marginBottom: '0.5rem',
      fontFamily: "'Cardo', serif",
    },
    cardDescription: {
      padding: '0 1.5rem 1.5rem',
      color: '#4A5568',
      fontSize: '1rem',
      lineHeight: '1.6',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
    },
    cardActions: {
      display: 'flex',
      padding: '1rem 1.5rem',
      gap: '0.5rem',
      background: 'rgba(247, 250, 252, 0.8)',
    },
    button: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.75rem',
      padding: '1rem',
      border: 'none',
      borderRadius: '12px',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '1rem',
      fontWeight: '600',
      background: 'rgba(255, 255, 255, 0.9)',
      color: '#2D3748',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        background: 'white',
      },
    },
  };

  const contentTypes = [
    {
      title: 'Sermon',
      icon: 'mdi-cross',
      description: 'Create a new sermon with AI assistance',
      color: '#FF416C',
      path: '/create/sermon'
    },
    {
      title: 'Bible Study',
      icon: 'mdi-book-open-variant',
      description: 'Design a new Bible study series',
      color: '#FF4B2B',
      path: '/create/biblestudy'
    },
    {
      title: 'Devotional',
      icon: 'mdi-creation',
      description: 'Write a daily or weekly devotional',
      color: '#82B1FF',
      path: '/create/devotional'
    },
    {
      title: 'Testimony',
      icon: 'mdi-account-voice',
      description: 'Share a powerful testimony',
      color: '#4CAF50',
      path: '/create/testimony'
    }
  ];

  return (
    <div style={styles.createContainer}>
      <div style={styles.contentGrid}>
        {contentTypes.map((type) => (
          <div key={type.title} style={styles.card}>
            <div 
              style={{
                ...styles.iconBar,
                background: `linear-gradient(135deg, ${type.color}dd 0%, ${type.color}99 100%)`
              }}
            >
              <i className={`mdi ${type.icon}`} style={styles.feedIcon}></i>
            </div>
            <div style={styles.cardHeader}>
              <div style={styles.contentInfo}>
                <h3 style={styles.cardTitle}>{type.title}</h3>
              </div>
            </div>
            <div style={styles.cardDescription}>
              {type.description}
            </div>
            <div style={styles.cardActions}>
              <button 
                style={styles.button}
                onClick={() => navigate(type.path)}
              >
                <i 
                  className="mdi mdi-plus" 
                  style={{ 
                    color: type.color,
                    fontSize: '1.2rem'
                  }}
                ></i>
                Start Creating
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Create;
