// docs: https://modularfirebase.web.app/common-use-cases/firestore/

import { firebaseConfig } from "../firebaseConfig";
import { PY_URL } from "../config";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {
  getFirestore, connectFirestoreEmulator, query,
  collection, addDoc, orderBy, updateDoc,
  limit, getDocs, writeBatch, deleteDoc, where, serverTimestamp, doc, getDoc, setDoc
} from "firebase/firestore";

import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL, listAll } from "firebase/storage";

import { getAuth, onAuthStateChanged, deleteUser } from 'firebase/auth';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const mode = process.env.NODE_ENV;

// if (mode === 'development') {
//   connectFirestoreEmulator(db, '[::1]', 8275);
// }

/**
 * Delete a collection, in batches of batchSize. Note that this does
 * not recursively delete subcollections of documents in the collection
 */

function deleteCollection(db, collectionRef, batchSize) {
  const q = query(collectionRef, orderBy('__name__'), limit(batchSize));

  return new Promise((resolve) => {
    deleteQueryBatch(db, q, batchSize, resolve);
  });
}

async function deleteQueryBatch(db, query, batchSize, resolve) {
  const snapshot = await getDocs(query);

  // When there are no documents left, we are done
  let numDeleted = 0;
  if (snapshot.size > 0) {
    // Delete documents in a batch
    const batch = writeBatch(db);
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
    numDeleted = snapshot.size;
  }

  if (numDeleted < batchSize) {
    resolve();
    return;
  }

  // Recurse on the next process tick, to avoid
  // exploding the stack.
  setTimeout(() => {
    deleteQueryBatch(db, query, batchSize, resolve);
  }, 0);
}




export const saveUserToFirestore = async (userID, email, firstName, lastName) => {
  try {
    console.log("Saving user to Firestore");
    onAuthStateChanged(auth, async(user) => {
      if (user) {
        // console.log("User is signed in");
        // check if user already exists in the database, delete all other documents with the same userID and save with this one
        const querySnapshot = await getDocs(collection(db, "users", userID, "account"));
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            deleteDoc(doc.ref);
          });
        }
        const docRef = await addDoc(collection(db, "users", userID, "account"), {
          userID: userID,
          email: email,
          firstName: firstName,
          lastName: lastName,
          timestamp: new Date(),
          timestampString: new Date().toISOString()
        });
        if (mode === 'development') {
          console.log("User written to Firestore collection with ID: ", docRef.id);
        } 
      } else {
        // User is signed out
        console.log('Unable to save user to Firestore: User is signed out');
      }
    });
  } catch (e) {
    console.error("Error adding document to Firestore users collection: ", e);
  }
}


export const removeUserFromFirestore = async (userID) => {
  try {
      await deleteCollection(db, collection(db, "users", userID, "account"), 10);
      if (mode === 'development') {
        console.log("User removed from Firestore collection with ID: ", userID);
      }
  } catch (e) {
    console.error("Error removing document from Firestore users collection: ", e);
  }
}


export const getUserObjectFromFirestore = async (userID) => {
  try {
    onAuthStateChanged(auth, async(user) => {
      console.log(`Getting user from Firestore with ID: ${userID}`);
      if (user) {
        const userDocs = await getDocs(collection(db, "users", userID, "account"));
        // return False if user doesn't exist
        if (userDocs.empty) {
          return false;
        }
        const userDoc = userDocs.docs[0];
        const userObject = userDoc.data();
        return userObject;
      } else {
        // User is signed out
        console.log('Unable to get user from Firestore: User is signed out');
      }
    });
  } catch (e) {
    console.error("Error getting document from Firestore users collection: ", e);
  }
}

export const saveSermonToFirestore = async (userId, sermonData) => {
    if (!db) {
        throw new Error('Firestore is not initialized');
    }

    try {
        console.log('Saving sermon data:', sermonData); // Debug log

        // Create a new document
        const userSermonsRef = collection(db, 'users', userId, 'sermons');
        
        const newSermon = {
            ...sermonData,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            status: 'draft'
        };
        
        // Create new document and get the ID
        const docRef = await addDoc(userSermonsRef, newSermon);
        console.log('Created new sermon with ID:', docRef.id);
        
        // Verify the save by fetching the document
        const savedDoc = await getDoc(docRef);
        console.log('Saved sermon data:', savedDoc.data());
        
        return docRef.id;
    } catch (error) {
        console.error('Error saving sermon:', error);
        throw error;
    }
};

export const deleteSermonFromFirestore = async (userId, sermonId) => {
    if (!db) {
        throw new Error('Firestore is not initialized');
    }

    try {
        console.log('Deleting sermon:', { userId, sermonId }); // Debug log
        
        // Create a reference to the specific sermon document
        const sermonRef = doc(db, 'users', userId, 'sermons', sermonId);
        
        // Get the document first to verify it exists
        const sermonDoc = await getDoc(sermonRef);
        if (!sermonDoc.exists()) {
            throw new Error('Sermon not found');
        }
        
        // Delete the sermon document
        await deleteDoc(sermonRef);
        
        console.log('Sermon deleted successfully');
        return true;
    } catch (error) {
        console.error('Error deleting sermon:', error);
        throw error;
    }
};

export const deleteUserAccount = async (userId) => {
    if (!db || !auth.currentUser) {
        throw new Error('Firebase is not initialized or user is not logged in');
    }

    try {
        // Delete user's sermons collection
        const sermonsRef = collection(db, 'users', userId, 'sermons');
        await deleteCollection(db, sermonsRef, 10);

        // Delete user's account document
        const accountRef = collection(db, 'users', userId, 'account');
        await deleteCollection(db, accountRef, 10);

        // Delete user's YouTube auth if exists
        try {
            const response = await fetch(`${PY_URL}/delete-user-yt-auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            if (!response.ok) {
                console.error('Failed to delete YouTube auth');
            }
        } catch (error) {
            console.error('Error deleting YouTube auth:', error);
        }

        // Delete the user's auth account
        await deleteUser(auth.currentUser);
        
        console.log('User account deleted successfully');
        return true;
    } catch (error) {
        console.error('Error deleting user account:', error);
        throw error;
    }
};

export const saveStudyToFirestore = async (userId, studyData) => {
  try {
    // Create a new document reference in the user's studies collection
    const studyRef = doc(collection(db, 'users', userId, 'studies'));
    
    // Add the document with the study data
    await setDoc(studyRef, {
      ...studyData,
      id: studyRef.id
    });

    return studyRef.id;
  } catch (error) {
    console.error('Error saving study to Firestore:', error);
    throw error;
  }
};

export const getStudyFromFirestore = async (userId, studyId) => {
  try {
    const studyRef = doc(db, 'users', userId, 'studies', studyId);
    const studyDoc = await getDoc(studyRef);
    
    if (studyDoc.exists()) {
      return { id: studyDoc.id, ...studyDoc.data() };
    }
    
    throw new Error('Study not found');
  } catch (error) {
    console.error('Error fetching study from Firestore:', error);
    throw error;
  }
};

export const deleteStudyFromFirestore = async (userId, studyId) => {
  if (!db) {
    throw new Error('Firestore is not initialized');
  }

  try {
    console.log('Deleting study:', { userId, studyId });
    
    const studyRef = doc(db, 'users', userId, 'studies', studyId);
    
    const studyDoc = await getDoc(studyRef);
    if (!studyDoc.exists()) {
      throw new Error('Study not found');
    }
    
    await deleteDoc(studyRef);
    
    console.log('Study deleted successfully');
    return true;
  } catch (error) {
    console.error('Error deleting study:', error);
    throw error;
  }
};

// Add these functions for Bible study management

export const saveBibleStudyDraft = async (userId, studyData) => {
  if (!db) {
    throw new Error('Firestore is not initialized');
  }

  try {
    const studyRef = studyData.id 
      ? doc(db, 'users', userId, 'studies', studyData.id)
      : doc(collection(db, 'users', userId, 'studies'));

    const dataToSave = {
      ...studyData,
      id: studyRef.id,
      updatedAt: new Date().toISOString(),
      lastModifiedBy: userId,
      status: 'draft'
    };

    // If it's a new study, add creation metadata
    if (!studyData.id) {
      dataToSave.createdAt = new Date().toISOString();
      dataToSave.createdBy = userId;
    }

    await setDoc(studyRef, dataToSave);
    return studyRef.id;
  } catch (error) {
    console.error('Error saving Bible study draft:', error);
    throw error;
  }
};

export const getBibleStudyById = async (userId, studyId) => {
  if (!db) {
    throw new Error('Firestore is not initialized');
  }

  try {
    const studyRef = doc(db, 'users', userId, 'studies', studyId);
    const studyDoc = await getDoc(studyRef);
    
    if (!studyDoc.exists()) {
      throw new Error('Bible study not found');
    }
    
    return { id: studyDoc.id, ...studyDoc.data() };
  } catch (error) {
    console.error('Error fetching Bible study:', error);
    throw error;
  }
};

export const getUserBibleStudies = async (userId) => {
  if (!db) {
    throw new Error('Firestore is not initialized');
  }

  try {
    const studiesRef = collection(db, 'users', userId, 'studies');
    const q = query(studiesRef, orderBy('updatedAt', 'desc'));
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching Bible studies:', error);
    throw error;
  }
};


