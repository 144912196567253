import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import './App.css';

import MainNav from './components/MainNav';
import Login from './screens/login';
import Paypal from "./screens/paypal";
import Create from './screens/create';
import Library from './screens/Library';
import Community from './screens/community';
import Profile from './screens/profile';
import Dashboard from './screens/Dashboard';
import ProfileSetup from './components/ProfileSetup';
import SermonViewer from './screens/SermonViewer';
import StudyViewer from './screens/StudyViewer';

// Import sermon creation components
import SermonCreator from './components/create/sermon/SermonCreator';
import YouTubeInput from './components/create/sermon/YouTubeInput';
import YouTubeLinkModal from './components/YouTubeLinkModal';
import SermonReview from './components/create/sermon/SermonReview';
import StudyCreator from './components/create/study/StudyCreator';

// Create a new component to handle the authenticated app content
const AppContent = () => {
  const { isFirstSignIn, setIsFirstSignIn } = useAuth();

  useEffect(() => {
    if (isFirstSignIn) {
      setIsFirstSignIn(false);
    }
  }, [isFirstSignIn, setIsFirstSignIn]);

  return (
    <div className="app-layout">
      <MainNav />
      <div className="main-content">
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          
          {/* Protected routes */}
          <Route path="/" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
          
          <Route path="/profile/setup" element={
            <PrivateRoute>
              <ProfileSetup />
            </PrivateRoute>
          } />
          
          <Route path="/create" element={
            <PrivateRoute>
              <Create />
            </PrivateRoute>
          } />
          
          <Route path="/create/sermon" element={
            <PrivateRoute>
              <SermonCreator />
            </PrivateRoute>
          } />
          
          <Route path="/create/sermon/process/:sermonId" element={
            <PrivateRoute>
              <SermonReview />
            </PrivateRoute>
          } />
          
          <Route path="/library" element={
            <PrivateRoute>
              <Library />
            </PrivateRoute>
          } />
          
          <Route path="/community" element={
            <PrivateRoute>
              <Community />
            </PrivateRoute>
          } />
          
          <Route path="/profile" element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />
          
          <Route path="/create/sermon/youtube/:sermonId" element={<YouTubeInput />} />
          
          <Route path="/sermon/:sermonId" element={
            <PrivateRoute>
              <SermonViewer />
            </PrivateRoute>
          } />
          
          <Route path="/create/biblestudy" element={
            <PrivateRoute>
              <StudyCreator />
            </PrivateRoute>
          } />
          
          <Route path="/create/biblestudy/edit/:studyId" element={
            <PrivateRoute>
              <StudyCreator />
            </PrivateRoute>
          } />
          
          <Route path="/study/:studyId" element={
            <PrivateRoute>
              <StudyViewer />
            </PrivateRoute>
          } />
          
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <div className="app-footer">
        <button className="footer-button">
          <i className="mdi mdi-help-circle"></i>
          <span>Help</span>
        </button>
        <button className="footer-button">
          <i className="mdi mdi-email"></i>
          <span>Contact</span>
        </button>
        <button className="footer-button">
          <i className="mdi mdi-information"></i>
          <span>About</span>
        </button>
      </div>
      {isFirstSignIn && <YouTubeLinkModal onClose={() => setIsFirstSignIn(false)} />}
    </div>
  );
};

// Main App component
function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
