import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../control/firebase';
import ReactMarkdown from 'react-markdown';

const SermonViewer = () => {
  const { sermonId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [sermon, setSermon] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('transcript');

  useEffect(() => {
    const fetchSermon = async () => {
      if (!currentUser || !sermonId) return;
      
      try {
        setLoading(true);
        const sermonRef = doc(db, 'users', currentUser.uid, 'sermons', sermonId);
        const sermonDoc = await getDoc(sermonRef);
        
        if (sermonDoc.exists()) {
          const sermonData = sermonDoc.data();
          console.log('Fetched sermon data:', sermonData);
          
          setSermon({
            id: sermonDoc.id,
            title: sermonData.title || 'Untitled Sermon',
            date: sermonData.date || new Date().toISOString(),
            scripture: sermonData.scripture || '',
            description: sermonData.description || '',
            youtubeUrl: sermonData.youtubeUrl || '',
            transcript: sermonData.transcript || '',
            summary: sermonData.summary || '',
            lessonPlan: sermonData.lessonPlan || '',
            highlights: sermonData.highlights || '',
            status: sermonData.status || 'draft',
            createdAt: sermonData.createdAt,
            updatedAt: sermonData.updatedAt,
          });
        } else {
          setError('Sermon not found');
          console.error('No sermon found with ID:', sermonId);
        }
      } catch (err) {
        console.error('Error fetching sermon:', err);
        setError('Failed to load sermon');
      } finally {
        setLoading(false);
      }
    };

    fetchSermon();
  }, [currentUser, sermonId]);

  if (loading) {
    return (
      <div className="sermon-viewer loading">
        <div className="loading-spinner">
          <i className="mdi mdi-loading mdi-spin"></i>
          <span>Loading sermon...</span>
        </div>
      </div>
    );
  }

  if (error || !sermon) {
    return (
      <div className="sermon-viewer error">
        <div className="error-message">
          <i className="mdi mdi-alert"></i>
          <span>{error || 'Unable to load sermon'}</span>
        </div>
        <button 
          className="button button-secondary"
          onClick={() => navigate('/library')}
        >
          Back to Library
        </button>
      </div>
    );
  }

  const styles = {
    container: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '-10px',
    },
    card: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.1)',
      marginBottom: '1rem',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      width: '100%',
    },
    iconBar: {
      width: '100%',
      height: '48px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(135deg, rgba(255, 0, 0, 0.85) 0%, rgba(255, 0, 0, 0.75) 100%)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      padding: '0',
      lineHeight: '1',
    },
    feedIcon: {
      color: 'white',
      fontSize: '1.5rem',
      filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2))',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      marginTop: '1px',
    },
    header: {
      padding: '1.5rem',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: '600',
      color: '#2D3748',
      marginBottom: '0.5rem',
      fontFamily: "'Cardo', serif",
    },
    meta: {
      display: 'flex',
      gap: '1rem',
      color: '#4A5568',
      fontSize: '0.9rem',
    },
    tabs: {
      display: 'flex',
      padding: '0.5rem',
      gap: '0.5rem',
      background: 'rgba(247, 250, 252, 0.8)',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
    },
    tab: {
      flex: 1,
      padding: '0.75rem',
      border: 'none',
      borderRadius: '12px',
      background: 'transparent',
      color: '#4A5568',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontSize: '0.95rem',
      fontWeight: '500',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.9)',
      },
    },
    activeTab: {
      background: 'rgba(255, 0, 0, 0.1)',
      color: 'var(--primary-color)',
    },
    content: {
      padding: '1.5rem',
      color: '#2D3748',
      fontSize: '1rem',
      lineHeight: '1.6',
      background: 'rgba(255, 255, 255, 0.2)',
    },
    backButton: {
      background: 'rgba(255, 255, 255, 0.9)',
      border: 'none',
      padding: '0.5rem',
      borderRadius: '12px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1.2rem',
      color: '#4A5568',
      marginBottom: '1rem',
      transition: 'all 0.2s ease',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      },
    },
    highlightCard: {
      background: 'rgba(255, 255, 255, 0.8)',
      borderRadius: '12px',
      padding: '1rem',
      marginBottom: '1rem',
      border: '1px solid rgba(255, 255, 255, 0.3)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    quote: {
      borderLeft: '4px solid var(--primary-color)',
      paddingLeft: '1rem',
      marginBottom: '0.5rem',
      fontStyle: 'italic',
    },
    videoContainer: {
      marginTop: '1rem',
      borderRadius: '8px',
      overflow: 'hidden',
    },
    timestampLink: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      color: '#4A5568',
      textDecoration: 'none',
      fontSize: '0.9rem',
      marginTop: '0.5rem',
      '&:hover': {
        color: 'var(--primary-color)',
      },
    },
    videoSection: {
      width: '100%',
      background: 'rgba(0, 0, 0, 0.05)',
      borderBottom: '1px solid rgba(226, 232, 240, 0.8)',
    },
    videoWrapper: {
      position: 'relative',
      paddingBottom: '56.25%', // 16:9 aspect ratio
      height: 0,
      overflow: 'hidden',
    },
    videoIframe: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 'none',
    },
  };

  return (
    <div style={styles.container}>
      <button 
        style={styles.backButton}
        onClick={() => navigate('/library')}
      >
        <i className="mdi mdi-arrow-left"></i>
      </button>

      <div style={styles.card}>
        <div style={styles.iconBar}>
          <i className="mdi mdi-cross" style={styles.feedIcon}></i>
        </div>

        <div style={styles.header}>
          <h1 style={styles.title}>{sermon?.title}</h1>
          <div style={styles.meta}>
            <span>{new Date(sermon?.date).toLocaleDateString()}</span>
            {sermon?.scripture && (
              <span>{sermon.scripture}</span>
            )}
          </div>
        </div>

        <div style={styles.videoSection}>
          <div style={styles.videoWrapper}>
            <iframe
              style={styles.videoIframe}
              src={`https://www.youtube.com/embed/${
                sermon?.youtubeUrl ? getYoutubeVideoId(sermon.youtubeUrl) : ''
              }`}
              title={sermon?.title || 'Sermon Video'}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div style={styles.tabs}>
          {['transcript', 'summary', 'lessonPlan', 'highlights'].map((tab) => (
            <button
              key={tab}
              style={{
                ...styles.tab,
                ...(activeTab === tab ? styles.activeTab : {})
              }}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        <div style={styles.content}>
          {activeTab === 'highlights' ? (
            sermon?.highlights ? (
              (() => {
                try {
                  const highlightsData = JSON.parse(sermon.highlights);
                  const parsedHighlights = JSON.parse(highlightsData.highlights);
                  const highlights = parsedHighlights.highlights;
                  const videoId = sermon.youtubeUrl ? getYoutubeVideoId(sermon.youtubeUrl) : null;

                  return highlights.map((highlight, index) => (
                    <div key={index} style={styles.highlightCard}>
                      <blockquote style={styles.quote}>{highlight.quote}</blockquote>
                      <p>{highlight.note}</p>
                      {videoId && (
                        <div style={styles.videoContainer}>
                          <iframe
                            width="100%"
                            height="315"
                            src={`https://www.youtube.com/embed/${videoId}?start=${Math.floor(parseFloat(highlight.start_time))}`}
                            title={`Highlight ${index + 1}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                          <a 
                            href={`https://youtu.be/${videoId}?t=${Math.floor(parseFloat(highlight.start_time))}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.timestampLink}
                          >
                            <i className="mdi mdi-youtube"></i>
                            Watch on YouTube at {formatTimestamp(highlight.start_time)}
                            {highlight.end_time && ` - ${formatTimestamp(highlight.end_time)}`}
                          </a>
                        </div>
                      )}
                    </div>
                  ));
                } catch (error) {
                  console.error('Error parsing highlights:', error);
                  return <p>Error displaying highlights</p>;
                }
              })()
            ) : (
              <p>No highlights available</p>
            )
          ) : (
            <ReactMarkdown>
              {sermon?.[activeTab] || `No ${activeTab} available`}
            </ReactMarkdown>
          )}
        </div>
      </div>
    </div>
  );
};

const getYoutubeVideoId = (url) => {
  if (!url) return null;
  try {
    if (url.includes('youtu.be/')) {
      return url.split('youtu.be/')[1].split('?')[0];
    } else if (url.includes('youtube.com/watch')) {
      return url.split('v=')[1].split('&')[0];
    } else if (url.includes('youtube.com/embed/')) {
      return url.split('embed/')[1].split('?')[0];
    }
  } catch (err) {
    console.error('Error extracting video ID:', err);
  }
  return null;
};

const formatTimestamp = (seconds) => {
  const time = parseFloat(seconds);
  const minutes = Math.floor(time / 60);
  const remainingSeconds = Math.floor(time % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export default SermonViewer; 