import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const ProfileSetup = () => {
    const { currentUser, updateProfile } = useAuth();
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    
    // Profile Data
    const [profileData, setProfileData] = useState({
        displayName: '',
        church: '',
        role: '',
        bio: '',
        profileImage: null,
        imageUrl: '',
        preferences: {
            notifications: true,
            emailUpdates: true,
            darkMode: false
        }
    });

    const fileInputRef = useRef();

    const handleImageUpload = async (file) => {
        if (!file) return;

        const storage = getStorage();
        const storageRef = ref(storage, `profile-images/${currentUser.uid}`);
        
        try {
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        
        if (type === 'checkbox') {
            setProfileData(prev => ({
                ...prev,
                preferences: {
                    ...prev.preferences,
                    [name]: checked
                }
            }));
        } else {
            setProfileData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfileData(prev => ({
                ...prev,
                profileImage: file,
                imageUrl: URL.createObjectURL(file)
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            let imageUrl = profileData.imageUrl;
            if (profileData.profileImage) {
                imageUrl = await handleImageUpload(profileData.profileImage);
            }

            await updateProfile({
                ...profileData,
                imageUrl,
                setupCompleted: true
            });

            navigate('/');
        } catch (error) {
            setError('Failed to update profile. Please try again.');
            console.error('Profile setup error:', error);
        } finally {
            setLoading(false);
        }
    };

    const renderStep = () => {
        switch(step) {
            case 1:
                return (
                    <div className="setup-step">
                        <h3>Basic Information</h3>
                        <div className="form-group">
                            <label>Display Name</label>
                            <input
                                type="text"
                                name="displayName"
                                value={profileData.displayName}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Church/Ministry</label>
                            <input
                                type="text"
                                name="church"
                                value={profileData.church}
                                onChange={handleInputChange}
                            />
                        </div>
                        <button onClick={() => setStep(2)} className="submit-button">
                            Next
                        </button>
                    </div>
                );
            
            case 2:
                return (
                    <div className="setup-step">
                        <h3>Profile Picture</h3>
                        <div className="profile-image-upload">
                            {profileData.imageUrl && (
                                <img
                                    src={profileData.imageUrl}
                                    alt="Profile preview"
                                    className="profile-image-preview"
                                />
                            )}
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                accept="image/*"
                                style={{ display: 'none' }}
                            />
                            <button
                                onClick={() => fileInputRef.current.click()}
                                className="submit-button"
                                style={{ marginBottom: '1rem' }}
                            >
                                Choose Image
                            </button>
                        </div>
                        <div className="button-group">
                            <button onClick={() => setStep(1)} className="submit-button">
                                Back
                            </button>
                            <button onClick={() => setStep(3)} className="submit-button">
                                Next
                            </button>
                        </div>
                    </div>
                );
            
            case 3:
                return (
                    <div className="setup-step">
                        <h3>Preferences</h3>
                        <div className="form-group">
                            <label>
                                <input
                                    type="checkbox"
                                    name="notifications"
                                    checked={profileData.preferences.notifications}
                                    onChange={handleInputChange}
                                />
                                Enable Notifications
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                <input
                                    type="checkbox"
                                    name="emailUpdates"
                                    checked={profileData.preferences.emailUpdates}
                                    onChange={handleInputChange}
                                />
                                Receive Email Updates
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                <input
                                    type="checkbox"
                                    name="darkMode"
                                    checked={profileData.preferences.darkMode}
                                    onChange={handleInputChange}
                                />
                                Dark Mode
                            </label>
                        </div>
                        <div className="button-group">
                            <button onClick={() => setStep(2)} className="submit-button">
                                Back
                            </button>
                            <button
                                onClick={handleSubmit}
                                className="submit-button"
                                disabled={loading}
                            >
                                {loading ? 'Saving...' : 'Complete Setup'}
                            </button>
                        </div>
                    </div>
                );
            
            default:
                return null;
        }
    };

    return (
        <div className="login-container">
            <div className="profile-setup">
                <h2>Complete Your Profile</h2>
                {error && <div className="error-message">{error}</div>}
                {renderStep()}
            </div>
        </div>
    );
};

export default ProfileSetup; 